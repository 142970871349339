/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Select, message, Tooltip } from "antd";
import clinicLocIcon from "./../assets/img/location-icon-list-page.svg";
import nearByIcon from "./../assets/img/near-by-icon.svg";
import phoneIcon from "./../assets/img/phone-icon.svg";
import magnifyingGlass from "./../assets/img/magnifying-glass.png";
import currentLocation from "./../assets/img/currentLocationGif.gif";
import coraLogo from "./../assets/img/cora-logo.svg";
import clinicLocationMarker from "./../assets/img/clinicLocationMarker.png";
import mapViewIcon from "./../assets/img/map-view-icon.svg";
import listViewIcon from "./../assets/img/list-view-icon.svg";
import errorImage from "./../assets/img/error-image.svg";
import noSlotImg from "./../assets/img/no-slot-icon.svg";
import ticIcon from "./../assets/img/tic-icon.png";
import mapStyles from "../styles/scss/mapStyles";
import moment from "moment";
import Slider from "react-slick";
import FloatLabel from "./floatLabel";
import { useSelector } from "react-redux";
import clinicServices from "./../assets/img/clinicServices.png";

import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import SearchClinicComponent from "./searchClinicComponent";
const { Option } = Select;

function BookAppointment(props) {
  const [selectedDate, setSelectedDate] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMapView, setIsMapView] = useState(false);
  const [appointmentSlotBookModal, setAppointmentSlotBookModal] =
    useState(false);
  const [milesValue, setMilesValue] = useState(20);
  const [miles, setMiles] = useState();
  const [sorted, setCheckSorted] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [zoomValue, setZoomValue] = useState(11);
  const [apiCallOnArrow, setApiCallOnArrow] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const customSlider = useRef();

  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

  const navigate = useNavigate();

  // let dataForAvaliableDates = [];
  const standardDataFormat = "YYYY-MM-DD";

  //listdata for clinic list
  const Data = [];

  //Fetch clinis,clinicList from store
  const clinicsSearchFields = useSelector((state) => state.clinics);
  const therapyTypes = useSelector((state) => state.therapyTypes);
  const insuranceTypes = useSelector((state) => state.insuranceTypes);

  //Fetching data from landing page
  const location = useLocation();

  let stateFromLandigPage = location.state;

  //If state is null redirect to homePage
  if (stateFromLandigPage === null) {
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
  }

  let { clinicData, appointmentDates } = JSON.parse(
    localStorage.getItem("Data")
  );

  const { formData, setDateToDefault } = stateFromLandigPage;

  //use to enable button
  useEffect(() => {
    if (appointmentDates.length !== 0 && setDateToDefault) {
      let currentDate = appointmentDates[0].date;
      let formattedCurrentDate = moment(currentDate).format(standardDataFormat);
      setSelectedDate(formattedCurrentDate);
    }
    // }, [stateFromLandigPage, appointmentDates]);
  }, [stateFromLandigPage]);

  useEffect(() => {
    let nextArrowElement = document.getElementsByClassName(
      "slick-arrow slick-next slick-disabled"
    );

    if (nextArrowElement.length === 1) {
      nextArrowElement[0].className = "slick-arrow slick-next";

      document
        .getElementsByClassName("slick-arrow slick-next")[0]
        .addEventListener("click", myFunction);
    }
  }, [slideIndex]);

  //Slider settings
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (current, next) => setSlideIndex(current),
  };

  async function myFunction() {
    let slickLength =
      document.getElementsByClassName("slick-track")[0].children.length;
    let shouldFetchNewData = document
      .getElementsByClassName("slick-track")[0]
      .children[slickLength - 3].getAttribute("class")
      .includes("slick-current");

    if (shouldFetchNewData) {
      try {
        setApiCallOnArrow(true);
        //timeout 45-seconds
        const controller = new AbortController();
        const id = setTimeout(
          () => controller.abort(),
          process.env.REACT_APP_API_TIMEOUT
        );

        //1-15

        let existingData = JSON.parse(localStorage.getItem("Data"));

        formData.appointmentDate = moment(
          existingData.appointmentDates[
            existingData.appointmentDates.length - 1
          ].date
        ).format("YYYY-MM-DD");

        const response = await fetch(
          `${REACT_APP_CORA_API_URL}Clinic/SearchClinic`,
          {
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            method: "POST",
            signal: controller.signal,
          }
        );
        clearTimeout(id);

        const clinicListData = await response.json();

        if (!Object.keys(clinicListData).includes("errors")) {
          let existingData = JSON.parse(localStorage.getItem("Data"));

          let updatedAppointmentDates = [
            ...existingData.appointmentDates,
            ...clinicListData.data.appointmentDates,
          ];

          let updatedClinics = [
            ...existingData.clinicData,
            ...clinicListData.data.clinics,
          ];

          //Merge appointment slots of duplicate clinics
          var uniqueArray = [];
          let simpleArray = [];

          // Loop through array values
          for (let index = 0; index < updatedClinics.length; index++) {
            for (let index2 = 0; index2 < updatedClinics.length; index2++) {
              if (
                updatedClinics[index].clinicId ===
                updatedClinics[index2].clinicId
              ) {
                updatedClinics[index].appointmentSlots = [
                  ...updatedClinics[index].appointmentSlots,
                  ...updatedClinics[index2].appointmentSlots,
                ];

                simpleArray = updatedClinics[index].appointmentSlots.filter(
                  // eslint-disable-next-line no-loop-func
                  (element, i) =>
                    i ===
                    updatedClinics[index].appointmentSlots.indexOf(element)
                );

                updatedClinics[index].appointmentSlots = simpleArray;

                uniqueArray.push(updatedClinics[index]);
              }
            }
          }

          //remove duplicate clinics
          const uniqueIds1 = new Set();

          const updatedClinicData = uniqueArray.filter((element) => {
            const isDuplicate = uniqueIds1.has(element.clinicId);

            uniqueIds1.add(element.clinicId);

            if (!isDuplicate) {
              return true;
            }

            return false;
          });

          updatedClinics = updatedClinicData;

          //remove duplicate appointmentDates
          const uniqueIds = new Set();

          const uniqueDates = updatedAppointmentDates.filter((element) => {
            const isDuplicate = uniqueIds.has(element.date);

            uniqueIds.add(element.date);

            if (!isDuplicate) {
              return true;
            }

            return false;
          });

          updatedAppointmentDates = uniqueDates;

          localStorage.setItem(
            "Data",
            JSON.stringify({
              appointmentDates: updatedAppointmentDates,
              clinicData: updatedClinics,
            })
          );
          setApiCallOnArrow(false);
          navigate("/clinic-list", {
            state: {
              formData: formData,
              setDateToDefault: false,
              // clinicData: clinicListData.data.clinics,
              // appointmentDates: clinicListData.data.appointmentDates,
            },
          });

          document
            .getElementsByClassName("slick-arrow slick-next")[0]
            .removeEventListener("click", myFunction);

          document.getElementsByClassName("slick-arrow slick-next")[0].click();
        } else {
          throw Error("error");
        }
      } catch (error) {
        // setLoading(false);
        setApiErrorMessage(true);
      }
    } else {
      customSlider.current.slickPrev();
    }
  }

  useEffect(() => {
    fetchMiles();
    setListData([]);
    if (sorted === "" || sorted === "nearest") {
      sortedArrayNearest();
    } else {
      sortedArrayTimeslot();
    }
    // populateData();
  }, [sorted, selectedDate, milesValue, stateFromLandigPage]);

  //Fetch miles
  const fetchMiles = async () => {
    try {
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Settings/GetMiles`
      );
      clearTimeout(id);
      const data = await response.json();
      setMiles(data.miles);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleMilesChange = (e) => {
    setMilesValue(parseInt(e));
    zoomCheck(e);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAppointmentSlotBookModal(false);
  };

  const toggleMapView = () => {
    setIsMapView(!isMapView);
  };

  function isMobile() {
    return window.innerWidth < 1024;
  }

  //Datechange function from date slider
  async function onChangeDate(date) {
    let formattedDate = moment(date).format(standardDataFormat);
    setSelectedDate(formattedDate);
  }

  //format dates according to backend
  let formattedAppointmentDates = [];
  if (appointmentDates.length !== 0) {
    appointmentDates.forEach((item) => {
      let date = item.date.slice(0, 10);
      let normalDate = moment(item.date.slice(0, 10)).format("YYYY-MM-DD_");
      let weekDay = moment(date).day();

      switch (weekDay) {
        case 0:
          weekDay = "Sun-";
          break;
        case 1:
          weekDay = "Mon-";
          break;
        case 2:
          weekDay = "Tue-";
          break;
        case 3:
          weekDay = "Wed-";
          break;
        case 4:
          weekDay = "Thu-";
          break;
        case 5:
          weekDay = "Fri-";
          break;
        case 6:
          weekDay = "Sat-";
          break;
        default:
          break;
      }

      let monthDate = moment(date).format("MMMM-DD");
      formattedAppointmentDates.push([normalDate] + weekDay + monthDate);
    });
  }

  //select appointment Time
  const selectAppointmentTime = (
    appointmentTimeObject,
    selectedDate,
    clinicData,
    distanceInMiles
  ) => {
    navigate(`/confirm-appointment`, {
      state: {
        clinicData: clinicData,
        appointmentTime: { ...appointmentTimeObject, selectedDate },
      },
    });
  };

  //Format phone number
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  const clinicDistanceFromLocation = (lat1, lng1) => {
    var R = 6371; // Radius of the earth in km

    var dLat = deg2rad(formData.latitude - lat1); // deg2rad below
    var dLon = deg2rad(formData.longitude - lng1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(formData.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // conversion factor
    const factor = 0.621371;
    // calculate miles
    const miles = d * factor;

    return miles.toFixed(1) + " Miles away from location";
  };

  const handleSortChange = (e) => {
    if (e === "Available") {
      sortedArrayTimeslot();
    } else {
      sortedArrayNearest();
    }
  };

  //Get TherapyName
  const therapyName =
    Object.values(therapyTypes).length !== 0 &&
      clinicsSearchFields["typeofTherapyId"] !== 0
      ? therapyTypes &&
      therapyTypes.find(
        (item, key) => clinicsSearchFields["typeofTherapyId"] === item.id
      )?.name
      : null;

  //Get Insurance Name
  const insuranceName =
    Object.values(insuranceTypes).length !== 0 &&
      clinicsSearchFields["insuranceProviderId"] !== 0
      ? insuranceTypes &&
      insuranceTypes.find(
        (item, key) => clinicsSearchFields["insuranceProviderId"] === item.id
      )?.name
      : null;

  //sort by nearest
  const sortedArrayNearest = () => {
    clinicData &&
      clinicData.sort(function (a, b) {
        var x = parseFloat(
          clinicDistanceFromLocation(
            a.locationLatitude,
            a.locationLongitude
          ).match(/[+-]?\d+(\.\d+)?/g)[0]
        );

        var y = parseFloat(
          clinicDistanceFromLocation(
            b.locationLatitude,
            b.locationLongitude
          ).match(/[+-]?\d+(\.\d+)?/g)[0]
        );

        setCheckSorted("nearest");
        return x < y ? -1 : x > y ? 1 : 0;
      });

    clinicData &&
      clinicData.forEach((item) =>
        milesValue >=
          clinicDistanceFromLocation(
            parseFloat(item.locationLatitude),
            parseFloat(item.locationLongitude)
          ).match(/[+-]?\d+(\.\d+)?/g)[0]
          ? Data.push({
            title: item.clinicName,
            avatar: item.clinicPicture,
            description: item.clinicDescription,
            address:
              item.clinicAddress.trim() +
              ", " +
              item.lccity +
              ", " +
              item.lcstate +
              ", " +
              item.lczip,
            number: formatPhoneNumber(item.areCode + item.clinicPhone),
            clinicId: item.clinicId,
            appointmentSlots: item.appointmentSlots,
            clinicData: item,
          })
          : null
      );

    setListData(Data);
  };

  //mapZoom value
  const zoomCheck = (e) => {
    if (e === "20") {
      setZoomValue(10);
      return 10;
    } else if (e === "10") {
      setZoomValue(11);
      return 11;
    } else if (e === "50") {
      setZoomValue(9);
    }
  };

  //sort by avaliable
  const sortedArrayTimeslot = () => {
    clinicData &&
      clinicData.sort(function (a, b) {
        var y = a.appointmentSlots.filter(
          (x) => x.date.slice(0, 10) === selectedDate && x.isAvailable === true
        );
        var x = b.appointmentSlots.filter(
          (x) => x.date.slice(0, 10) === selectedDate && x.isAvailable === true
        );

        x = x.length;
        y = y.length;

        setCheckSorted("available");
        return x < y ? -1 : x > y ? 1 : 0;
      });

    clinicData &&
      clinicData.forEach((item) =>
        milesValue >=
          clinicDistanceFromLocation(
            parseFloat(item.locationLatitude),
            parseFloat(item.locationLongitude)
          ).match(/[+-]?\d+(\.\d+)?/g)[0]
          ? Data.push({
            title: item.clinicName,
            avatar: item.clinicPicture,
            description: item.clinicDescription,
            address:
              item.clinicAddress.trim() +
              ", " +
              item.lccity +
              ", " +
              item.lcstate +
              ", " +
              item.lczip,
            number: formatPhoneNumber(item.areCode + item.clinicPhone),
            clinicId: item.clinicId,
            appointmentSlots: item.appointmentSlots,
            clinicData: item,
          })
          : null
      );

    setListData(Data);
  };

  //populate cliniclist with search clinics API

  // const populateData = () => {
  //   if (clinicData.length !== 0) {
  //     //removed props?.fromClinicList
  //     if (sorted === "") {
  //       sortedArrayNearest();
  //     }
  //   }
  // };

  //Redirect to clinic detail page using clinicID
  const getClinicDetail = (selectedClinic) => {
    let clinicId = selectedClinic.clinicId
      .toLowerCase()
      .trim()
      .replaceAll(" ", "-");

    let distance = clinicDistanceFromLocation(
      selectedClinic.locationLatitude,
      selectedClinic.locationLongitude
    );

    if (clinicsSearchFields["typeofTherapyId"] === 0) {
      const messageText = `Select Type of therapy and click Apply`;
      message.error(messageText, [1]);
    } else if (clinicsSearchFields["insuranceProviderId"] === 0) {
      const messageText = `Select Insurance Provider and click Apply`;
      message.error(messageText, [1]);
    } else {
      navigate(`/clinic-detail/${clinicId}`, {
        state: {
          selectedClinic: selectedClinic,
          appointmentDates: appointmentDates,
          formData: formData,
          distanceInMiles: distance,
          selectedDate: selectedDate,
          clinicData: clinicData,
        },
      });
    }
  };

  //timeFormatter
  const timeFormatter = (startTime) => {
    var timesplit = startTime.split(".");
    var hours = timesplit[0];
    var minutes = timesplit[1];

    if (timesplit[0].length === 1) {
      hours = "0" + timesplit[0];
    }
    if (timesplit[1].length === 2 && timesplit[1].charAt(0) === "5") {
      minutes = "30";
    }

    startTime = hours + "." + minutes;
    let formattedTime;

    if (parseInt(startTime) > 12) {
      var timesplit1 = startTime.split(".");
      var hours1 = timesplit1[0];
      var minutes1 = timesplit1[1];

      if (timesplit1[1].length === 2 && timesplit1[1].charAt(0) === "5") {
        minutes1 = "30";
      }

      hours1 = hours1 - 12;
      hours1 = "" + hours1;

      if (hours1.length === 1) {
        hours1 = "0" + hours1;
      }

      formattedTime = hours1 + "." + minutes1 + " pm";
    } else if (parseInt(startTime) === 12) {
      formattedTime = startTime + " pm";
    } else {
      formattedTime = startTime + " am";
    }
    return formattedTime.replaceAll(".", ":");
  };

  //Google map
  function Map() {
    const [selectedClinic, setSelectdClinic] = useState(null);

    useEffect(() => {
      const listener = (e) => {
        if (e.key === "Escape") {
          setSelectdClinic(null);
        }
      };
      window.addEventListener("keydown", listener);

      return () => {
        window.removeEventListener("keydown", listener);
      };
    }, []);

    return isMobile() ? (
      <GoogleMap
        defaultZoom={zoomValue}
        defaultCenter={{
          lat: stateFromLandigPage && stateFromLandigPage.formData.latitude,
          lng: stateFromLandigPage && stateFromLandigPage.formData.longitude,
        }}
        defaultOptions={{ styles: mapStyles, disableDefaultUI: true }}
      >
        <Marker
          position={{
            lat: stateFromLandigPage && stateFromLandigPage.formData.latitude,
            lng: stateFromLandigPage && stateFromLandigPage.formData.longitude,
          }}
          icon={{
            url: currentLocation,
            scaledSize: new window.google.maps.Size(30, 30),
          }}
        />

        {listData.length !== 0 &&
          listData &&
          listData.map((item, index) => (
            <Marker
              key={index}
              position={{
                lat: Number(item.clinicData?.locationLatitude),
                lng: Number(item.clinicData?.locationLongitude),
              }}
              onClick={() => {
                setSelectdClinic(item.clinicData);
              }}
              icon={{
                url: clinicLocationMarker,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          ))}

        {selectedClinic && (
          <InfoWindow
            position={{
              lat: Number(selectedClinic.locationLatitude),
              lng: Number(selectedClinic.locationLongitude),
            }}
            onCloseClick={() => {
              setSelectdClinic(null);
            }}
            className="test-class"
          >
            <div className="card">
              <div className="card-title" id="clinic-title">
                <a onClick={() => getClinicDetail(selectedClinic)}>
                  {selectedClinic.clinicName}
                </a>
              </div>

              {selectedClinic.clinicAddress && (
                <div className="clinic-loc my-2">
                  <img src={clinicLocIcon} alt="Location" />
                  <span className="ml-3 d-block">
                    {selectedClinic.clinicAddress +
                      ", " +
                      clinicsSearchFields["city"] +
                      ", " +
                      clinicsSearchFields["state"]}
                  </span>
                </div>
              )}
              <div className="clinic-nearby-plcs my-1">
                <img src={nearByIcon} alt="Near By" />
                <span className="ml-2 d-block">
                  {clinicDistanceFromLocation(
                    selectedClinic.locationLatitude,
                    selectedClinic.locationLongitude
                  )}
                </span>
              </div>

              <div className="hours-n-details mt-3 mb-2">
                <a
                  className="hours-n-details-link"
                  id="horus-n-details"
                  onClick={() => getClinicDetail(selectedClinic)}
                >
                  Clinic Hours and More Details{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    ) : (
      <GoogleMap
        defaultZoom={zoomValue}
        defaultCenter={{
          lat: stateFromLandigPage && stateFromLandigPage.formData.latitude,
          lng: stateFromLandigPage && stateFromLandigPage.formData.longitude,
        }}
        defaultOptions={{ styles: mapStyles }}
      >
        <Marker
          position={{
            lat: stateFromLandigPage && stateFromLandigPage.formData.latitude,
            lng: stateFromLandigPage && stateFromLandigPage.formData.longitude,
          }}
          icon={{
            url: currentLocation,
            scaledSize: new window.google.maps.Size(30, 30),
          }}
        />

        {listData.length !== 0 &&
          listData &&
          listData.map((item, index) => (
            <Marker
              key={index}
              position={{
                lat: Number(item.clinicData?.locationLatitude),
                lng: Number(item.clinicData?.locationLongitude),
              }}
              onClick={() => {
                setSelectdClinic(item.clinicData);
              }}
              icon={{
                url: clinicLocationMarker,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          ))}

        {selectedClinic && (
          <InfoWindow
            position={{
              lat: Number(selectedClinic.locationLatitude),
              lng: Number(selectedClinic.locationLongitude),
            }}
            onCloseClick={() => {
              setSelectdClinic(null);
            }}
          >
            <div className="card">
              <div className="card-title" id="clinic-title">
                <a onClick={() => getClinicDetail(selectedClinic)}>
                  {selectedClinic.clinicName}
                </a>
              </div>

              {selectedClinic.clinicAddress && (
                <div className="clinic-loc my-2">
                  <img src={clinicLocIcon} alt="Location" />
                  <span className="ml-3 d-block">
                    {selectedClinic.clinicAddress +
                      ", " +
                      clinicsSearchFields["city"] +
                      ", " +
                      clinicsSearchFields["state"]}
                  </span>
                </div>
              )}
              <div className="clinic-nearby-plcs my-1">
                <img src={nearByIcon} alt="Near By" />
                <span className="ml-2 d-block">
                  {clinicDistanceFromLocation(
                    selectedClinic.locationLatitude,
                    selectedClinic.locationLongitude
                  )}
                </span>
              </div>

              <div className="hours-n-details mt-3 mb-2">
                <a
                  className="hours-n-details-link"
                  id="horus-n-details"
                  onClick={() => getClinicDetail(selectedClinic)}
                >
                  Clinic Hours and More Details{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  }

  const MapWrapped = withScriptjs(withGoogleMap(Map));

  return (
    <>
      <Modal
        visible={apiCallOnArrow}
        className="exception-modal"
        footer={null}
        centered
      >
        <div className="modal-content">
          <span className="exception-modal-text">Fetching Data...</span>
        </div>
      </Modal>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            Something went wrong. Please try again later.
          </span>
        </div>
      </Modal>
      <Modal
        visible={appointmentSlotBookModal}
        className="no-slot-modal"
        onCancel={() => setAppointmentSlotBookModal(false)}
        onOk={() => setAppointmentSlotBookModal(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img className="no-slot-img" src={noSlotImg} alt="Slot Booked" />
          <span className="slot-booked-text">This slot is already booked.</span>

          <Button
            type="primary"
            className="select-different-time-btn"
            id="select-different-slot"
            onClick={() => setAppointmentSlotBookModal(false)}
          >
            <span>Please select a different time.</span>
          </Button>
        </div>
      </Modal>
      <div className="clinic-list-page">
        <Helmet>
          <title>CORA Health | Book An Appointment</title>
          <script type="text/javascript" src="//cdn.rlets.com/capture_configs/165/e1e/c5f/44b489ca502db19b9b0e144.js" async="async"></script>
        </Helmet>
        <div className="page-header">
          <div className="app-header">
            <div className="app-header-logo">
              <a href='https://coraphysicaltherapy.com'>
                <img src={coraLogo} alt="CORA Logo" id="app-logo" />
              </a>
            </div>
            <div className="schedule-appointment-btn">
              <button id="schedule-appointment-btn" onClick={showModal}>
                <div className="magnifying-glass-icon">
                  <img src={magnifyingGlass} alt="" />
                </div>
                <div className="btn-text-p">
                  <span className="btn-text-1">{therapyName}</span>{" "}
                  <span className="btn-text-2">
                    {clinicsSearchFields && clinicsSearchFields["city"]}
                  </span>
                </div>
              </button>
              <Modal
                className="search-clinic-modal"
                onCancel={handleCancel}
                visible={isModalVisible}
                footer={null}
                centered
              >
                <SearchClinicComponent
                  therapyName={therapyName}
                  insuranceName={insuranceName}
                  cancelHandler={handleCancel}
                  fromClinicList={true}
                  clinicFields={clinicsSearchFields}
                  fromclinicListForm={stateFromLandigPage.formData}
                />
              </Modal>
            </div>
            <div className="schedule-appointment-form">
              <SearchClinicComponent
                therapyName={therapyName}
                insuranceName={insuranceName}
                fromClinicList={true}
                clinicFields={clinicsSearchFields}
                fromclinicListForm={stateFromLandigPage.formData}
              />
            </div>
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="date-slider">
            <div
              className={`parent ${appointmentDates.length <= 3 ? "normalSlider" : "SlickSlider"
                }`}
            >
              <Slider
                {...settings}
                ref={(slider) => (customSlider.current = slider)}
              >
                {formattedAppointmentDates &&
                  formattedAppointmentDates.map((value, index) => {
                    return (
                      <div
                        key={index}
                        // key={value.slice(0, 10)}
                        className="child"
                        onClick={() => onChangeDate(value.slice(0, 10))}
                      >
                        {selectedDate === value.slice(0, 10) ? (
                          <span className="selected-date">
                            <span>{value.slice(11, 14)}</span>
                            <span className="d-block">
                              {value
                                .slice(15, value.length)
                                .replaceAll("-", " ")}
                            </span>
                          </span>
                        ) : (
                          <span className="other-dates">
                            <span>{value.slice(11, 14)}</span>
                            <span className="month-n-date">
                              {value
                                .slice(15, value.length)
                                .replaceAll("-", " ")}
                            </span>
                          </span>
                        )}
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>

          {isMobile() ? (
            <div className="row no-gutters main-content-row h-100">
              {!isMapView ? (
                <div className="col-lg-7 h-100 overflow-scroll">
                  <div className="clinic-list">
                    <span className="resluts-count-text">
                      <span className="result-count">
                        {listData.length !== 0
                          ? listData.length === 1
                            ? listData.length + " result found"
                            : listData.length + " results found"
                          : null}
                      </span>

                      {listData && listData.length > 1 && (
                        <div id="sortby-dropdown" className="sort-dropdown">
                          <span className="sort-label">Sort by</span>
                          <FloatLabel label="Sort By" value={"Nearest"}>
                            <Select
                              optionFilterProp="children"
                              defaultValue={"Nearest Most"}
                              id="sort-type-select"
                              name="selectSort"
                              onChange={handleSortChange}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option key={1} value={"nearest"}>
                                Nearest Most
                              </Option>
                              <Option key={2} value={"Available"}>
                                Available
                              </Option>
                            </Select>{" "}
                          </FloatLabel>
                        </div>
                      )}
                      <div id="sortby-dropdown" className="sort-dropdown">
                        <span className="sort-label">Miles</span>
                        <FloatLabel label="Miles" value={"20"}>
                          <Select
                            dropdownStyle={false}
                            optionFilterProp="children"
                            defaultValue={20}
                            size="small"
                            className="miles-type-select"
                            id="miles-type-select"
                            name="selectMiles"
                            onChange={handleMilesChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {miles &&
                              miles?.map((item, index) => (
                                <Option key={index} value={item.mile}>
                                  {item.mile}
                                </Option>
                              ))}
                          </Select>
                        </FloatLabel>
                      </div>
                    </span>
                    <div className="card-list-wrapper">
                      {listData.length !== 0 ? (
                        listData &&
                        listData.map((item, index) => (
                          <>
                            <div key={index} className="card">
                              <div
                                className="card-title mb-2 "
                                id="clinic-title"
                              >
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    getClinicDetail(item.clinicData)
                                  }
                                >
                                  {item.title}
                                </span>
                              </div>
                              <div className="card-subtitle my-1">
                                <span>{item.description}</span>
                              </div>
                              {item?.address ? (
                                <div className="clinic-loc my-1">
                                  <img src={clinicLocIcon} alt="Location" />
                                  <span className="ml-3 d-block">
                                    {item.address}
                                  </span>
                                </div>
                              ) : null}
                              <div className="clinic-nearby-plcs my-1">
                                <img src={nearByIcon} alt="Near By" />
                                <span className="ml-2 d-block">
                                  {clinicDistanceFromLocation(
                                    item.clinicData.locationLatitude,
                                    item.clinicData.locationLongitude
                                  )}
                                </span>
                              </div>
                              <div className="phone-number my-1">
                                <img src={phoneIcon} alt="Phone Number" />
                                <a
                                  href={`tel:${item.number}`}
                                  className="ml-2 d-block"
                                >
                                  {item.number}
                                </a>
                              </div>

                              <div className="clinic-loc my-1 phone-number">
                                <img
                                  className="service-icon"
                                  src={clinicServices}
                                  alt="Phone Number"
                                />

                                <a
                                  className="ml-3 d-block"
                                  id="horus-n-details"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={item.clinicData.webUrl}
                                >
                                  Clinic Services
                                </a>
                              </div>

                              <div className="appointment-hours">
                                {item.appointmentSlots.map((timeslot, index) =>
                                  selectedDate ===
                                    timeslot.date.slice(0, 10) ? (
                                    timeslot.isAvailable ? (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        key={index}
                                        className="appointment-hour-box"
                                        fromClinicList={true}
                                        onClick={() =>
                                          selectAppointmentTime(
                                            timeslot,
                                            selectedDate,
                                            item.clinicData,
                                            clinicDistanceFromLocation(
                                              item.clinicData.locationLatitude,
                                              item.clinicData.locationLongitude
                                            )
                                          )
                                        }
                                      >
                                        {timeFormatter(timeslot.startTime)}
                                      </a>
                                    ) : (
                                      <Tooltip
                                        title="Already Booked"
                                        color={"#007fa8"}
                                      >
                                        <span>
                                          <span className="disabled-slot appointment-hour-box">
                                            {timeFormatter(timeslot.startTime)}
                                          </span>
                                        </span>
                                      </Tooltip>
                                    )
                                  ) : null
                                )}
                              </div>
                              <div className="hours-n-details mt-3 mb-2">
                                <a
                                  className="hours-n-details-link"
                                  id="horus-n-details"
                                  onClick={() =>
                                    getClinicDetail(item.clinicData)
                                  }
                                >
                                  Hours and More Details{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <span className="no-result-msg">
                          No result found. Try a different search
                        </span>
                      )}
                      <div className="caught-up-text">
                        {listData.length > 0 ? (
                          <span>
                            <span className="icon-circle">
                              <img alt="" className="mr-3" src={ticIcon} />
                            </span>
                            You are all caught up with the results
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-5 map-section">
                  <div className="headline">
                    <span>Showing results in the</span>
                    <Select
                      dropdownStyle={false}
                      optionFilterProp="children"
                      defaultValue={20}
                      size="small"
                      className="miles-type-select"
                      id="miles-type-select"
                      name="selectMiles"
                      onChange={handleMilesChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {miles &&
                        miles?.map((item, index) => (
                          <Option key={index} value={item.mile}>
                            {item.mile}
                          </Option>
                        ))}
                    </Select>
                    <span>mile radius.</span>
                  </div>

                  <div className="google-map" id="google-map">
                    <MapWrapped
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${REACT_APP_GOOGLE_API_KEY}`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="row no-gutters main-content-row">
              <div className="col-lg-7 h-100 overflow-auto clinic-list-column">
                <div className="clinic-list">
                  <span className="resluts-count-text">
                    <span>
                      {listData.length !== 0
                        ? listData.length === 1
                          ? listData.length + " result found"
                          : listData.length + " results found"
                        : null}
                    </span>
                    {listData && listData.length > 1 && (
                      <div id="sortby-dropdown" className="sort-dropdown">
                        <span className="sort-label">Sort by</span>
                        <FloatLabel label="Sort By" value={"Nearest"}>
                          <Select
                            optionFilterProp="children"
                            defaultValue={"Nearest Most"}
                            id="sort-type-select"
                            name="selectSort"
                            onChange={handleSortChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option key={1} value={"nearest"}>
                              Nearest Most
                            </Option>
                            <Option key={2} value={"Available"}>
                              Available
                            </Option>
                          </Select>{" "}
                        </FloatLabel>
                      </div>
                    )}
                  </span>
                  <div className="card-list-wrapper">
                    {listData.length !== 0 ? (
                      listData &&
                      listData.map((item, index) => (
                        <>
                          <div key={index} className="card">
                            <div className="card-title mb-2" id="clinic-title">
                              <span
                                className="cursor-pointer"
                                onClick={() => getClinicDetail(item.clinicData)}
                              >
                                {item.title}
                              </span>
                            </div>

                            <div className="card-subtitle my-1">
                              <span>{item.description}</span>
                            </div>
                            {item?.address ? (
                              <div className="clinic-loc my-1">
                                <img src={clinicLocIcon} alt="Location" />
                                <span className="ml-3 d-block">
                                  {item.address}
                                </span>
                              </div>
                            ) : null}
                            <div className="clinic-nearby-plcs my-1">
                              <img src={nearByIcon} alt="Near By" />
                              <span className="ml-2 d-block">
                                {clinicDistanceFromLocation(
                                  item.clinicData.locationLatitude,
                                  item.clinicData.locationLongitude
                                )}
                              </span>
                            </div>
                            <div className="clinic-loc my-1 phone-number">
                              <img
                                className="mobile-icon"
                                src={phoneIcon}
                                alt="Phone Number"
                              />
                              <a
                                href={`tel:${item.number}`}
                                className="ml-3 d-block"
                              >
                                {item.number}
                              </a>
                            </div>

                            <div className="clinic-loc my-1 phone-number">
                              <img
                                className="service-icon"
                                src={clinicServices}
                                alt="Phone Number"
                              />

                              <a
                                className="ml-3 d-block"
                                id="horus-n-details"
                                target="_blank"
                                rel="noreferrer"
                                href={item.clinicData.webUrl}
                              >
                                Clinic Services
                              </a>
                            </div>

                            <div className="appointment-hours">
                              {item.appointmentSlots.map((timeslot, index) =>
                                selectedDate === timeslot.date.slice(0, 10) ? (
                                  timeslot.isAvailable ? (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a
                                      key={index}
                                      className="appointment-hour-box"
                                      fromClinicList={true}
                                      onClick={() =>
                                        selectAppointmentTime(
                                          timeslot,
                                          selectedDate,
                                          item.clinicData,
                                          clinicDistanceFromLocation(
                                            item.clinicData.locationLatitude,
                                            item.clinicData.locationLongitude
                                          )
                                        )
                                      }
                                    >
                                      {timeFormatter(timeslot.startTime)}
                                    </a>
                                  ) : (
                                    <Tooltip
                                      title="Already Booked"
                                      color={"#007fa8"}
                                    >
                                      <span>
                                        <span className="disabled-slot appointment-hour-box">
                                          {timeFormatter(timeslot.startTime)}
                                        </span>
                                      </span>
                                    </Tooltip>
                                  )
                                ) : null
                              )}
                            </div>
                            <div className="hours-n-details mt-3 mb-2">
                              <a
                                className="hours-n-details-link"
                                id="horus-n-details"
                                onClick={() => getClinicDetail(item.clinicData)}
                              >
                                Clinic hours and more details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <span className="no-result-msg">
                        No result found. Try a different search
                      </span>
                    )}
                    <div className="caught-up-text">
                      {listData.length > 0 ? (
                        <span>
                          <span className="icon-circle">
                            <img alt="" src={ticIcon} />
                          </span>
                          You are all caught up with the results
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 map-section">
                <div className="headline">
                  <span>Showing results in the </span>
                  <Select
                    dropdownStyle={false}
                    optionFilterProp="children"
                    defaultValue={20}
                    id="miles-type-select"
                    className="miles-type-select"
                    name="selectMiles"
                    onChange={handleMilesChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {miles &&
                      miles?.map((item, index) => (
                        <Option key={index} value={item.mile}>
                          {item.mile}
                        </Option>
                      ))}
                  </Select>
                  <span>mile radius.</span>
                </div>
                <div className="google-map">
                  <MapWrapped
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${REACT_APP_GOOGLE_API_KEY}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              </div>
            </div>
          )}

          <button className="toggle-list-map-view" onClick={toggleMapView}>
            <img
              alt="Switch View"
              src={isMapView ? listViewIcon : mapViewIcon}
            />
          </button>
        </div>
      </div>
    </>
  );
}
export default BookAppointment;
