const initialState = {
  confirmed: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "setConfirmed":
      return {
        ...state,
        confirmed: payload,
      };

    default:
      return state;
  }
};

export default reducer;
