export default function validateDate(value) {
    
    if(value == "Invalid Date"){
        return true
    } else {
        let date = new Date(value);
        let currentYear = new Date().getFullYear();
        let currentDate = new Date();
  
        if (date.getFullYear() > 1900 && date.getFullYear() <= currentYear && date.setHours(0, 0, 0, 0) <= currentDate.setHours(0, 0, 0, 0)) {
            return false
        } else {
            return true
        }
    }
    
}