/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import { Button as AntdBtn } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useSelector, useDispatch } from 'react-redux';
import { resources } from '../resource';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
}));
function MedicalHistoryComponent(props) {
  const { handleSubmit, register, errors } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const Alpha_Regex = /^[a-zA-Z0-9 ]+$/;

  const patientForm = useSelector((state) => state.patientForm);
  const _resources = resources[props.language];

  const initialFormData = Object.freeze({
    medicalIllnessesHeartProblems: null,
    medicalIllnessesPregnant: null,
    medicalIllnessesCancer: null,
    medicalIllnessesPaceMakers: null,
    medicalIllnessesOsteoporosis: null,
    medicalIllnessesDiabetes: null,
    medicalIllnessesAsthma: null,
    medicalIllnessesStroke: null,
    medicalIllnessesHighBloodPressure: null,
    medicalIllnessesSmoking: null,
    medicalIllnessesSeizures: null,
    medicalIllnessesHivAids: null,
    medications: null,
    doHaveAnAllergy: null,
    allergies: null,
    chiefPhysicalComplaint: null,
    howItHappened: null,
    surgeryInjuryLast5: null,
    havePreviousTherapyForTheProblem: null,
    wasPreviousTherapyHelpful: null,
  });

  const [formData, updateFormData] = React.useState(initialFormData);

  useEffect(() => {
    if (patientForm.medicalForm?.length !== 0) {
      updateFormData(patientForm.medicalForm);
    }
  }, [patientForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onSubmit() {
    actions?.medicalFormNextButton(true);
    actions.uploadMedicalForm(formData);
  }

  const handleChange = (e) => {
    if (e.target.value === 'true') {
      updateFormData({
        ...formData,
        [e.target.name]: true,
      });
    } else if (e.target.value === 'false') {
      if (
        e.target.name === 'havePreviousTherapyForTheProblem' &&
        formData.wasPreviousTherapyHelpful !== null
      ) {
        formData.wasPreviousTherapyHelpful = null;
      }
      updateFormData({
        ...formData,
        [e.target.name]: false,
      });
    } else {
      //medicareIllness array
      if (e.target.name === '') {
        updateFormData({
          ...formData,
          [e.target.value]: e.target.checked,
        });
      } else {
        updateFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    document.querySelector('.patient-history-data') &&
      document.querySelector('.patient-history-data').scrollTo(0, 0);
  };

  return (
    <>
      <div className="skip-text justify-content-between flex-row-reverse ml-3 ml-sm-0">
        {!window.location.href.includes('review-form') &&
          !formData?.doHaveAnAllergy && (
            <AntdBtn
              id="edit-form-btn"
              className="edit-form-btn w-auto"
              onClick={() => actions.medicalFormNextButton(true)}
            >
              {_resources.MedicalHistory.SKIP_TEXT}
            </AntdBtn>
          )}
        {props.prev ? (
          <div>
            <AntdBtn onClick={props.prev} className="edit-form-btn">
              {_resources.ReviewPage.BACK}
            </AntdBtn>
          </div>
        ) : null}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="medical-history-form">
        <div className="medical-heading">
          <p>{_resources.MedicalHistory.CHECKBOX_HEADING}</p>
        </div>
        <FormGroup name="medicalIllness" className="disease-group">
          <FormControlLabel
            value="medicalIllnessesHeartProblems"
            control={
              <Checkbox
                className="disbaled-input-field"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesHeartProblems !== null
                    ? formData.medicalIllnessesHeartProblems
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.HEART_PROBLEMS}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesPregnant"
            control={
              <Checkbox
                className="disbaled-input-field"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesPregnant !== null
                    ? formData.medicalIllnessesPregnant
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.PREGNANT}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesCancer"
            control={
              <Checkbox
                className="disbaled-input-field"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesCancer !== null
                    ? formData.medicalIllnessesCancer
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.CANCER}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesPaceMakers"
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesPaceMakers !== null
                    ? formData.medicalIllnessesPaceMakers
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.PACE_MAKERS}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesOsteoporosis"
            control={
              <Checkbox
                className="disbaled-input-field"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesOsteoporosis !== null
                    ? formData.medicalIllnessesOsteoporosis
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.OSTEO}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesDiabetes"
            control={
              <Checkbox
                className="disbaled-input-field"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesDiabetes !== null
                    ? formData.medicalIllnessesDiabetes
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.DIABETES}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesAsthma"
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesAsthma !== null
                    ? formData.medicalIllnessesAsthma
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.ASTHMA}
            onChange={handleChange}
          />
          <FormControlLabel
            value="medicalIllnessesStroke"
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesStroke !== null
                    ? formData.medicalIllnessesStroke
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.STROKE}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesHighBloodPressure !== null
                    ? formData.medicalIllnessesHighBloodPressure
                    : false
                }
              />
            }
            value="medicalIllnessesHighBloodPressure"
            label={_resources.MedicalHistory.HIGHBP}
            onChange={handleChange}
          />
          <FormControlLabel
            onChange={handleChange}
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesSmoking !== null
                    ? formData.medicalIllnessesSmoking
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.SMOKING}
            value="medicalIllnessesSmoking"
          />
          <FormControlLabel
            onChange={handleChange}
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesSeizures !== null
                    ? formData.medicalIllnessesSeizures
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.SEIZURES}
            value="medicalIllnessesSeizures"
          />
          <FormControlLabel
            onChange={handleChange}
            control={
              <Checkbox
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                checked={
                  formData.medicalIllnessesHivAids !== null
                    ? formData.medicalIllnessesHivAids
                    : false
                }
              />
            }
            label={_resources.MedicalHistory.HIV_AIDS}
            value="medicalIllnessesHivAids"
          />
        </FormGroup>

        <div className="medical-heading">
          <p>{_resources.MedicalHistory.MEDICATIONS_HEADING}</p>
        </div>
        <div className="row">
          <div className="col-lg-12 last-name-col">
            <TextField
              label={_resources.MedicalHistory.MEDICATIONS_LABEL}
              variant="outlined"
              // size="large"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              name="medications"
              fullWidth
              placeholder={_resources.MedicalHistory.MEDICATIONS_PLACEHOLDER}
              className={classes.inputField}
              onChange={handleChange}
              value={formData.medications || ''}
              onKeyDown={(event) => {
                if (!Alpha_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>

        <div className="medical-heading">
          <p>{_resources.MedicalHistory.DOYOUHAVEALLERGY}</p>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="doHaveAnAllergy"
            >
              <FormControlLabel
                onChange={handleChange}
                value={true}
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                    checked={
                      formData['doHaveAnAllergy'] === null
                        ? false
                        : formData['doHaveAnAllergy']
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.MedicalHistory.YES}
              />
              <FormControlLabel
                onChange={handleChange}
                value={false}
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                    checked={
                      formData['doHaveAnAllergy'] === null
                        ? false
                        : !formData['doHaveAnAllergy']
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.MedicalHistory.NO}
              />
            </RadioGroup>
          </div>
          {/* <div className="col-6 col-md-4">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="doHaveAnAllergy"
            >
              <FormControlLabel
                onChange={handleChange}
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </div> */}
        </div>
        {formData.doHaveAnAllergy && (
          <div className="last-name-col">
            <TextField
              label={_resources.MedicalHistory.LIST_ALLERGIES}
              variant="outlined"
              // size="large"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              inputRef={register({
                required: _resources.MedicalHistory.ALERGIES_REQUIRED,
              })}
              fullWidth
              error={Boolean(errors.allergies)}
              helperText={errors.allergies?.message}
              className={classes.inputField}
              onChange={handleChange}
              value={formData.allergies || ''}
              name="allergies"
              id="allergies"
              onKeyDown={(event) => {
                if (!Alpha_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        )}

        <div className="medical-heading">
          <p>{_resources.MedicalHistory.CP_COMPLAINT}</p>
        </div>
        <div className="row">
          <div className="col-lg-12 last-name-col">
            <TextField
              label={_resources.MedicalHistory.CP_COMPLAINT_PLACEHOLDER}
              variant="outlined"
              // size="large"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              fullWidth
              placeholder="back pain"
              className={classes.inputField}
              onChange={handleChange}
              name="chiefPhysicalComplaint"
              id="chiefPhysicalComplaint"
              value={formData.chiefPhysicalComplaint || ''}
              onKeyDown={(event) => {
                if (!Alpha_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 last-name-col">
            <TextField
              label={_resources.MedicalHistory.HOW_WHEN_HAPPENED}
              variant="outlined"
              // size="large"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              fullWidth
              placeholder="lifted a box at work, two weeks ago"
              className={classes.inputField}
              onChange={handleChange}
              name="howItHappened"
              id="howItHappened"
              value={formData.howItHappened || ''}
              onKeyDown={(event) => {
                if (!Alpha_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 last-name-col">
            <TextField
              label={_resources.MedicalHistory.LAST_5YRS}
              variant="outlined"
              // size="large"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              fullWidth
              className={classes.inputField}
              onChange={handleChange}
              name="surgeryInjuryLast5"
              id="surgeryInjuryLast5"
              value={formData.surgeryInjuryLast5 || ''}
              onKeyDown={(event) => {
                if (!Alpha_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="medical-heading">
          <p>{_resources.MedicalHistory.HAVE_PREVIOUS_INJURY}</p>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="havePreviousTherapyForTheProblem"
            >
              <FormControlLabel
                onChange={handleChange}
                value={true}
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                    checked={
                      formData['havePreviousTherapyForTheProblem'] === null
                        ? false
                        : formData['havePreviousTherapyForTheProblem']
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.MedicalHistory.YES}
              />
              <FormControlLabel
                onChange={handleChange}
                value={false}
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                    checked={
                      formData['havePreviousTherapyForTheProblem'] === null
                        ? false
                        : !formData['havePreviousTherapyForTheProblem']
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.MedicalHistory.NO}
              />
            </RadioGroup>
          </div>
          {/* <div className="col-6 col-md-4">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="havePreviousTherapyForTheProblem"
            >
              <FormControlLabel
                onChange={handleChange}
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </div> */}
        </div>

        {formData.havePreviousTherapyForTheProblem && (
          <>
            <div className="medical-heading">
              <p>{_resources.MedicalHistory.HELPFUL}</p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="wasPreviousTherapyHelpful"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['wasPreviousTherapyHelpful'] === null
                            ? false
                            : formData['wasPreviousTherapyHelpful']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicalHistory.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['wasPreviousTherapyHelpful'] === null
                            ? false
                            : !formData['wasPreviousTherapyHelpful']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicalHistory.NO}
                  />
                </RadioGroup>
              </div>
            </div>
          </>
        )}

        {window.location.href.includes('patient-form') ? (
          <Button
            className="dual-book-button upload-document-btn"
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            disabled={
              window.location.href.includes('review-form') ? true : false
            }
          >
            {_resources.MedicalHistory.CON_UPLOAD_DOCS_BUTTON}
          </Button>
        ) : null}
      </form>
    </>
  );
}
export default MedicalHistoryComponent;
