const initialState = {
  patientForm: [],
  medicalForm: [],
  medicareForm: [],
  patientFormNextButton: false,
  medicalFormNextButton: false,
  uploadDocumentsNextButton: false,
  letterOfExhaustionCopy: [],
  updateEditButtonForNewPatient: false,
  updateEditButtonForExistingPatient: false,
  signature: {},
  screeningQuestions: [],
  existingPatientWithAppointment: false,
  validationResponse: [],
  newPatientValidation: [],
  clinicName: "",
  checkInScreeningEnabled: false,
  vobNeeded: false,
  amountDue: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "patientFormNextButton":
      return {
        ...state,
        patientFormNextButton: payload,
      };

    case "clearPhase2Form":
      return {
        ...initialState,
      };

    case "uploadPatientForm":
      return {
        ...state,
        patientForm: payload,
      };

    case "newPatientValidation":
      return {
        ...state,
        newPatientValidation: payload,
      };

    case "validationResponse":
      return {
        ...state,
        validationResponse: payload,
      };

    case "updateClinicName":
      return {
        ...state,
        clinicName: payload,
      };

    case "saveSignature":
      return {
        ...state,
        signature: payload,
      };

    case "screeningQuestions":
      return {
        ...state,
        screeningQuestions: payload,
      };

    case "uploadMedicareForm":
      return {
        ...state,
        medicareForm: payload,
      };

    case "uploadLetterOfExhaustionCopy":
      return {
        ...state,
        letterOfExhaustionCopy: payload,
      };

    case "removeLetterOfExhaustionCopy":
      return {
        ...state,
        letterOfExhaustionCopy: [],
      };

    case "uploadMedicalForm":
      return {
        ...state,
        medicalForm: payload,
      };

    case "medicalFormNextButton":
      return {
        ...state,
        medicalFormNextButton: payload,
      };

    case "existingPatientWithAppointment":
      return {
        ...state,
        existingPatientWithAppointment: payload,
      };

    case "updateEditButtonForNewPatient":
      return {
        ...state,
        updateEditButtonForNewPatient: payload,
      };

    case "updateEditButtonForExistingPatient":
      return {
        ...state,
        updateEditButtonForExistingPatient: payload,
      };

    case "checkInScreeningEnabled":
      return {
        ...state,
        checkInScreeningEnabled: payload,
      };

    case "vobNeeded":
      return {
        ...state,
        vobNeeded: payload,
      };

      case "amountDue":
        return {
          ...state,
          amountDue: payload,
        };
  
      default:
      return state;
  }
};

export default reducer;
