/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Modal, Card, message } from 'antd';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import { actionCreators } from '../state';
import Webcam from 'react-webcam';
import * as imageConversion from 'image-conversion';
import pdfIcon from '../assets/img/pdf-icon.png';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import errorImage from './../assets/img/error-image.svg';
import Slider from 'react-slick';
import { resources } from '../resource';
import Dialog from '@mui/material/Dialog';

function UploadDocumentComponent(props) {
  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: 'user',
  };

  const initialFormData = Object.freeze({
    documentTypeId: null,
  });
  const _resources = resources[props.language];

  const [formData, updateFormData] = React.useState(initialFormData);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [captureSaveText, setCaptureSaveText] = useState(
    _resources.UploadDocs.TAKE_PHOTO
  );
  const [webCamModal, setWebCamModal] = useState(false);
  const [uploadBtnClicked, setUploadBtnClicked] = useState(false);
  const [webCameraImage, setWebCameraImage] = useState();
  const [documentTypes, setDocumentTypes] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [documentTypeSelectionError, setDocumentTypeSelectionError] =
    useState(false);

  const customSlider = React.useRef();

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const navigate = useNavigate();

  const documents = useSelector((state) => state.multipleUploadDocument);
  const patientForm = useSelector((state) => state.patientForm);

  useEffect(() => {
    getDocumentTypes();
  }, []);

  const getDocumentTypes = async () => {
    try {
      // timeout 45 seconds
      const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Settings/GetDocumentTypes`
      );
      clearTimeout(id);
      const documentTypesValues = await response.json();

      setDocumentTypes(documentTypesValues.documentTypes);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  async function handleCapture(input) {
    // const fileSize = input.files[0].size / 1024 / 1024;

    const fileList = Array.from(input.files);

    const sizeLimitInBytes = 25 * 1024 * 1024; // 25MB in bytes
    const isSizeExceeded = fileList.some(
      (file) => file.size > sizeLimitInBytes
    );

    if (isSizeExceeded) {
      const messageText = _resources.UploadDocs.FILE_SMALLER_THAN_25MB;
      message.error(messageText, [1.5]);
    } else {
      if (input.files) {
        if (input.files.length !== 0) {
          for (let file of input.files) {
            const newReduxStoreKey = formData.documentTypeId;

            if (file.type.split('/').pop().includes('pdf')) {
              const imageType = {
                document: await toBase64(file),
                documentTypeId: newReduxStoreKey,
                fileExtension: 'pdf',
              };
              actions.uploadMultipleDocument(imageType);
              setIsModalVisible(false);
              customSlider.current.slickNext();
            } else if (file.type.split('/')[0].includes('image')) {
              let fileCompressed = await imageConversion.compressAccurately(
                file,
                200
              );

              if (fileCompressed.type.split('/')[0].includes('image')) {
                const imageType = {
                  document: await toBase64(fileCompressed),
                  documentTypeId: newReduxStoreKey,
                  fileExtension: fileCompressed.type.split('/').pop(),
                };
                actions.uploadMultipleDocument(imageType);
                customSlider.current.slickNext();
              }
              setIsModalVisible(false);
            } else {
              const messageText = _resources.UploadDocs.PDF_OR_IMG_ALLOWED;
              message.error(messageText, [3]);
            }
          }
        }
      }
    }
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const webcamRef = React.useRef(null);

  const capture = async () => {
    const newReduxStoreKey = formData.documentTypeId;
    setCaptureSaveText(_resources.UploadDocs.UPLOAD_CAPTIAL_U);

    if (captureSaveText === _resources.UploadDocs.UPLOAD_SMALL_U) {
      setWebCamModal(false);
      // setSource(webCameraImage);
      const imageType = {
        document: webCameraImage,
        documentTypeId: newReduxStoreKey,
        fileExtension: webCameraImage.split('/')[1].split(';', 1)[0],
      };

      //add image with key
      actions.uploadMultipleDocument(imageType);
      customSlider.current.slickNext();

      setCaptureSaveText(_resources.UploadDocs.TAKE_PHOTO);
      setWebCameraImage('');
    } else {
      var images = webcamRef?.current?.getScreenshot();
      setCaptureSaveText(_resources.UploadDocs.UPLOAD_SMALL_U);
      setWebCameraImage(images);
      customSlider.current.slickNext();
    }
  };

  const reTake = () => {
    setCaptureSaveText(_resources.UploadDocs.TAKE_PHOTO);
    setWebCameraImage('');
  };

  const handleDelete = (source) => {
    // setSource(null);
    // setDisplayImage(null);
    actions.removeImageMultipleDocument(source);
    setWebCameraImage('');
    setCaptureSaveText(_resources.UploadDocs.TAKE_PHOTO);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const cardOnClick = () => {
    if (formData['documentTypeId'] !== null) {
      setIsModalVisible(true);
    } else {
      setDocumentTypeSelectionError(true);
    }
  };

  const sliders = () => {
    return documents.imageList.map((item, index) => {
      return (
        <div>
          <Card className="upload-document-card" key={index}>
            <p className="id-icon-wrapper">
              {documentTypes !== '' &&
                documentTypes.filter(
                  (item2) => item2.id === item.documentTypeId
                )[0]?.name}
            </p>

            <Box
              display="flex"
              justifyContent="center"
              border={1}
              className={props.classes.imgBox}
            >
              <img
                src={item.document.includes('/pdf') ? pdfIcon : item.document}
                alt={'snap'}
                className={props.classes.img}
              ></img>
              {window.location.href.includes('patient-form') && (
                <Button
                  type="danger"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    handleDelete(item);
                  }}
                >
                  x
                </Button>
              )}
            </Box>
          </Card>
        </div>
      );
    });
  };
  const handleInputChange = (name, value) => {
    if (value !== null && Object.keys(value).includes('id')) {
      updateFormData({
        ...formData,
        [name]: value.id,
      });
    } else {
      updateFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const nextButton = () => {
    setUploadBtnClicked(true);

    if (formData.documentTypeId == 1 && documents.imageList == 0) {
      setErrorText('Please upload state issue ID');
      handleClickOpen();
      return;
    }

    if (documentLengthValidated) {
      setErrorText('Both front and back ID are required.');
      handleClickOpen();
      return;
    }

    if (patientForm.existingPatientWithAppointment) {
      // if (patientForm.checkInScreeningEnabled && !patientForm.amountDue) {
      //   navigate(`/patient-checkin/checkInScreening`, {
      //     state: { fromFlow: true },
      //   });
      // } else
      if (patientForm.vobNeeded) {
        navigate(`/assignmentOfBenefit`);
      } else {
        navigate('/patient-checkin/signature', { state: { fromFlow: true, fromUpload: true } });
      }
    } else if (patientForm.patientForm.hasMedicalInsurance) {
      navigate('/patient-checkin/medicare-patient');
    } else {
      navigate('/patient-checkin/review-form', { state: { fromFlow: true } });
    }
  };

  const closeWebCameraModel = () => {
    setWebCamModal(false);
    setWebCameraImage('');
    setCaptureSaveText(_resources.UploadDocs.TAKE_PHOTO);
  };

  let documentLengthValidated = false;
  if (documents.imageList) {
    if (documents.imageList.length !== 0 && documents.imageList.length < 2) {
      documentLengthValidated = true;
    }
  }

  return (
    <>
      <div className="document-container">
        <Modal
          visible={apiErrorMessage}
          className="exception-modal"
          onCancel={() => setApiErrorMessage(false)}
          onOk={() => setApiErrorMessage(false)}
          footer={null}
          centered
        >
          <div className="modal-content">
            <img
              className="error-img"
              src={errorImage}
              alt="Something went wrong."
            />
            <span className="exception-modal-text">
              {_resources.Checkin.ERROR}
            </span>
          </div>
        </Modal>
        <Modal
          visible={documentTypeSelectionError}
          className="exception-modal"
          onCancel={() => setDocumentTypeSelectionError(false)}
          onOk={() => setDocumentTypeSelectionError(false)}
          footer={null}
          centered
        >
          <div className="modal-content">
            <img
              className="error-img"
              src={errorImage}
              alt="Something went wrong."
            />
            <span className="exception-modal-text">
              {_resources.UploadDocs.SELECT_DOC_FIRST}
            </span>
          </div>
        </Modal>
        {!window.location.href.includes('review-form') ? (
          <>
            {documents.imageList.length > 0 ? null : (
              <div className="skip-text justify-content-between flex-row-reverse ml-3 ml-sm-0">
                <>
                  <Button
                    id="edit-form-btn"
                    className="edit-form-btn w-auto"
                    onClick={() => nextButton()}
                  >
                    <img alt="" />
                    {_resources.UploadDocs.SKIP}
                  </Button>
                  {props.prev ? (
                    <div>
                      <Button onClick={props.prev} className="edit-form-btn">
                        {_resources.ReviewPage.BACK}
                      </Button>
                    </div>
                  ) : null}
                </>
              </div>
            )}
            {/* <div>
          <p>Please upload your documents</p>
        </div> */}
            <div className="insurance-provider-box">
              <Autocomplete
                options={documentTypes}
                required={true}
                name="documentTypeId"
                id="documentTypeId"
                onChange={(event, newValue) =>
                  handleInputChange('documentTypeId', newValue)
                }
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? true
                    : false
                }
                // value={documentTypes[formData["documentTypeId"]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={_resources.UploadDocs.SELECT_DOC_TYPE}
                    size="small"
                    variant="outlined"
                    name="documentTypeId"
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 30,
                    }}
                    id="documentTypeId"
                  />
                )}
                getOptionLabel={(documentTypes) => documentTypes.name}
              />
              {formData.documentTypeId == 1 && (
                <p class="small mb-0 mt-1">
                  For state issued ID, please upload front and back of ID. 
                </p>
              )}
            </div>
            <Card
              onClick={cardOnClick}
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              className="upload-document-card"
            >
              <p className="id-icon-wrapper">
                <i className="id-icon"></i> {props.title}
              </p>
            </Card>
          </>
        ) : null}
        <Slider {...settings} ref={customSlider}>
          {sliders()}
        </Slider>

        <Dialog
          open={open}
          onClose={handleClose}
          className="exception-modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="modal-content px-5 py-4">
            <img
              className="error-img"
              src={errorImage}
              alt="Something went wrong."
            />
            <span className="exception-modal-text">{errorText}</span>
          </div>
        </Dialog>
        <Modal
          centered
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          className="upload-document-modal capture-upload-modal"
        >
          {props.screen === 'desktop' ? (
            <button
              className="cam-upload-btn"
              onClick={() => {
                setIsModalVisible(false);
                setWebCamModal(true);
              }}
            >
              <i className="camera-icon"></i>{' '}
              {_resources.UploadDocs.CAPTURE_WEBCAMERA}
            </button>
          ) : (
            <>
              <div className={props.classes.root}>
                <Grid container>
                  <Grid item xs={12}>
                    <label className="file-upload-btn">
                      <i className="camera-icon"></i>{' '}
                      {_resources.UploadDocs.CAPTURE_CAMERA}
                      <input
                        accept="image/*"
                        multiple
                        className={props.classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    </label>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          <div>
            <label className="file-upload-btn">
              <i className="upload-img-icon "></i>
              {_resources.UploadDocs.UPLOAD_FROM_PC}
              <input
                // className={props.classes.input}
                accept="image/*,application/pdf"
                type="file"
                name="myImage"
                multiple
                id="upload-from-computer-drive"
                onChange={(e) => handleCapture(e.target)}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </label>
          </div>
        </Modal>
        <Modal
          visible={webCamModal}
          onOk={() => closeWebCameraModel()}
          onCancel={() => closeWebCameraModel()}
          className="upload-document-modal capture-modal"
          width={400}
        >
          {webCameraImage ? (
            <Box
              display="flex"
              justifyContent="center"
              border={1}
              className={props.classes.imgBox}
            >
              <img
                src={webCameraImage}
                alt={'snap'}
                className={props.classes.img}
              ></img>
            </Box>
          ) : (
            <Webcam
              style={{
                width: '300px',
                height: '300px',
              }}
              ref={webcamRef}
              screenshotQuality={1}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          )}
          <div className="cam-btn-wrapper">
            {webCameraImage && (
              <button className="retake-btn" onClick={reTake}>
                {_resources.UploadDocs.RETAKE}
              </button>
            )}
            <button className="take-btn" onClick={capture}>
              {captureSaveText}
            </button>
          </div>
        </Modal>
        {window.location.href.includes('patient-form') ? (
          <button
            onClick={nextButton}
            className="dual-book-button continue-btn"
            variant="contained"
            // color="primary"
            type="submit"
            size="large"
          >
            {_resources.UploadDocs.NEXT}{' '}
          </button>
        ) : null}
      </div>
    </>
  );
}
export default UploadDocumentComponent;
