const initialState = {
  formData: [],
  shouldPreFillForm: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "fillForm":
      return {
        ...state,
        formData: [payload],
      };

    case "clearForm":
      return {
        ...initialState,
      };

    case "updateFlag":
      return {
        ...state,
        shouldPreFillForm: payload,
      };
    case "updateForm":
      state.formData[0][payload["name"]] = payload["value"];
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
