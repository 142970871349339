import { TextField} from '@material-ui/core';

const NameField = ({
  label,
  name,
  id,
  validationRules,
  defaultValue,
  errors,
  handleChange,
  ...otherProps
}) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      size="small"
      className={otherProps.classes.inputField}
      onChange={handleChange}
      name={name}
      id={id}
      key={id}
      inputRef={validationRules}
      defaultValue={defaultValue || ''}
      error={Boolean(errors)}
      helperText={errors?.message}
      inputProps={{ maxLength: 30 }}
      {...otherProps}
    />
  );
};

export default NameField;
