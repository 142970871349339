const initialState = {
  imageList: [],
  prescription: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "uploadMultipleDocument":
      return {
        ...state,
        imageList: [...state.imageList, payload],
      };

    case "uploadDocumentsFromResponse":
      return {
        ...state,
        imageList: payload,
      };

    case "removeImageMultipleDocument":
      const filteredImages = state.imageList.filter((item) => item !== payload);
      return {
        ...state,
        imageList: [...filteredImages],
      };

    case "clearAllMultipleDocument":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
