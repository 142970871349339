/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Modal, Card, message, Spin } from 'antd';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import infoIcon from './../assets/img/info-icon.svg';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { Box } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import * as imageConversion from 'image-conversion';
import Webcam from 'react-webcam';
import clinicIcon from './../assets/img/location-pin-icon.svg';
import errorImage from './../assets/img/error-image.svg';
import pdfIcon from '../assets/img/pdf-icon.png';
import { LoadingOutlined } from '@ant-design/icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';

import { resources } from '../resource';

import validateDate from '../utils/validateDate';
import NameField from './molecules/nameField';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
}));

function PatientInformationComponent(props) {
  const auth = useSelector((state) => state.auth.authenticated);
  const location = useLocation();

  useEffect(() => {
    if (!auth) {
      if (props?.verifiedData?.prevPath) {
        if (props.verifiedData.prevPath) {
          window.location.replace(props.verifiedData.prevPath);
        } else {
          document.location.href = '/';
        }
      }
    }
  }, []);

  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const [gender, setGender] = useState(null);

  const typeOfGender = useSelector(
    (state) => state.patientForm.patientForm.gender
  );

  useEffect(() => {
    if (props?.verifiedData?.gender) {
      setGender(props.verifiedData.gender);
    } else if (location.state?.gender) {
      setGender(location.state.gender);
    } else {
      setGender(typeOfGender);
    }
  }, []);

  useEffect(() => {
    updateFormData({
      ...formData,
      gender: props?.verifiedData?.gender
        ? props.verifiedData.gender
        : typeOfGender,
    });
  }, []);

  useEffect(() => {
    updateFormData({
      ...formData,
      city: '',
      state: '',
    });
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayImage, setDisplayImage] = useState('');
  const [source, setSource] = useState('');
  const [captureSaveText, setCaptureSaveText] = useState('Take Photo');
  const [webCamModal, setWebCamModal] = useState(false);
  const [webCameraImage, setWebCameraImage] = useState();
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [socialMediaTypes, setSocialMediaTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [relationshipTypes, setRelationshipTypes] = useState([]);
  const [employerZipCode, setEmployerZip] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [onSubmitFlag, setOnSubmitFlag] = useState(false);
  const [dobError, setDobError] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const patientForm = useSelector((state) => state.patientForm);
  const patientFormNextButton = patientForm.patientFormNextButton;
  const _resources = resources[props.language];

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

  useEffect(() => {
    getSocialMediaTypes();
    getEmploymentStatusTypes();
    getRelationshipTyes();
  }, []);

  useEffect(() => {
    if (errors.zipCode) {
      setZipCode('');
    }

    if (errors.employerZipCode) {
      setEmployerZip('');
    }
  }, [errors.zipCode, errors.employerZipCode]);

  const [formData, updateFormData] = React.useState(patientForm.patientForm);

  const Alpha_Regex = /^[a-zA-Z0-9 ]+$/;

  // this code is hideous, but needed in order to update the formData object if the screen was accessed via
  // the online scheduling flow b/c some data is passed in as props vs. in state
  if (
    props?.verifiedData !== null &&
    props?.verifiedData.dob &&
    (formData.dob !== props?.verifiedData.dob ||
      formData.mobilePhone !==
        formatPhoneNumber(
          props?.verifiedData.cellAreaCode + props?.verifiedData.cell
        ) ||
      (formData.email !== props?.verifiedData.email?.trim() &&
        props?.verifiedData.email) ||
      (formData.firstName !== props?.verifiedData.firstName?.trim() &&
        props?.verifiedData.firstName) ||
      (formData.lastName !== props?.verifiedData.lastName?.trim() &&
        props?.verifiedData.lastName))
  ) {
    let tmpCell = formatPhoneNumber(
      props?.verifiedData.cellAreaCode + props?.verifiedData.cell?.trim()
    );
    updateFormData({
      ...formData,
      dob: props?.verifiedData.dob || formData.dob,
      mobilePhone: tmpCell || formData.mobilePhone,
      gender: props?.verifiedData.gender || location.state,
      //        mobilePhone: props?.verifiedData.mobile || formData.mobilePhone,
      email: props?.verifiedData.email?.trim() || formData.email,
      firstName: props?.verifiedData.firstName?.trim() || formData.firstName,
      lastName: props?.verifiedData.lastName?.trim() || formData.lastName,
    });
  }

  const Alphabets_Regex = /^[a-zA-Z ]+$/;
  const Alphabets_Hyphen_Spaces_Regex = /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i;
  const Numbers_Regex = /\d+/;

  const patientPhone = useSelector(
    (state) => state.patientForm.patientForm.mobilePhone
  );

  const cell = useSelector(
    (state) => state.patientForm.newPatientValidation.cell
  );
  const cellAreaCode = useSelector(
    (state) => state.patientForm.newPatientValidation.cellAreaCode
  );

  const newPatientValidation = cellAreaCode + cell;

  useEffect(() => {
    if (Object.keys(patientForm).includes('letterOfExhaustionCopy')) {
      if (Object.values(patientForm.letterOfExhaustionCopy).length !== 0) {
        if (
          patientForm.letterOfExhaustionCopy.imageUrl &&
          patientForm.letterOfExhaustionCopy.imageUrl.includes('/pdf')
        ) {
          setSource(patientForm.letterOfExhaustionCopy.imageUrl);
          setDisplayImage(pdfIcon);
        } else {
          setSource(patientForm.letterOfExhaustionCopy.imageUrl);
          setDisplayImage(patientForm.letterOfExhaustionCopy.imageUrl);
        }
      }
    }
    if (patientForm.patientForm.length !== 0) {
      if (patientForm.patientForm.zip !== '') {
        setZipCode(patientForm.patientForm.zip);
      }
      if (patientForm.patientForm.employerZip !== '') {
        setEmployerZip(patientForm.patientForm.employerZip);
      }
    }
  }, [patientForm]);

  //Fetch social Media types
  const getSocialMediaTypes = async () => {
    try {
      // timeout 45 seconds
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Settings/GetSocialMedia`
      );
      clearTimeout(id);
      let socialMediaValues = [];
      const socialMedia = await response.json();
      socialMedia.socialMedia.map((item) => {
        socialMediaValues.push(item.hearAboutName);
      });
      setSocialMediaTypes(socialMediaValues);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  const getEmploymentStatusTypes = async () => {
    try {
      // timeout 45 seconds
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Settings/GetEmploymentStatus`
      );
      clearTimeout(id);
      const employmentTypes = await response.json();

      let employeeStatus = [];
      employmentTypes.employmentStatuses.map((item) => {
        employeeStatus.push(item.name);
      });
      setEmploymentTypes(employeeStatus);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  const getRelationshipTyes = async () => {
    try {
      // timeout 45 seconds
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Settings/GetEmergencyContacts`
      );
      clearTimeout(id);
      const relationShipTypes = await response.json();

      let relationshipValues = [];
      relationShipTypes.emergencyContacts.map((item) => {
        relationshipValues.push(item.ecname.trim());
      });
      setRelationshipTypes(relationshipValues);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  function onSubmit() {
    if (dobError) {
      return;
    }

    let newForm = {
      ...formData,
    };

    if (newForm.weight == '') {
      newForm.weight = 0;
    }
    if (newForm.heightFt == '') {
      newForm.heightFt = 0;
    }
    if (newForm.heightIn == '') {
      newForm.heightIn = 0;
    }

    if (newForm.mobilePhone != document.getElementById('phone').value) {
      newForm.mobilePhone = document.getElementById('phone').value;
    }

    if (newForm.state == '') {
      newForm.state = patientForm.patientForm.state;
    }

    if (newForm.city == '') {
      newForm.city = patientForm.patientForm.city;
    }

    if (newForm.zip === null || newForm.city == '' || newForm.state == '') {
      setOnSubmitFlag(true);
    } else {
      if (!patientFormNextButton) {
        actions.patientFormNextButton(true);
      }
      actions.uploadPatientForm(newForm);
    }
  }

  const handleInputChange = (name, value) => {
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  const onGenderChange = (e) => {
    updateFormData({
      ...formData,
      gender: e.target.value,
    });
    setGender(e.target.value);
  };

  const handleChange = async (e) => {
    if (e.target.name.includes('Phone') || e.target.name.includes('phone')) {
      const inputField = document.getElementById(e.target.name);
      const formattedInputValue = formatPhoneNumber(inputField?.value);
      inputField.value = formattedInputValue;
      updateFormData({
        ...formData,
        [e.target.name]: formattedInputValue,
      });
    } else if (e.target.name == 'weight' && e.target.value === '') {
      updateFormData({
        ...formData,
        [e.target.name]: 0,
      });
    } else if (e.target.value === 'true' || e.target.value === 'false') {
      if (e.target.name === 'isWorkInjury' && e.target.checked) {
        formData.employerName = null;
        formData.employerPhone = null;
        formData.employerAddress1 = null;
        formData.employerAddress2 = null;
        formData.employerCity = null;
        formData.employerState = null;
        formData.employerZip = null;
        formData.job = null;
        formData.jobStatus = null;
        formData.isAutoInjury = false;
        formData.Neither = false;
        setEmployerZip('');
        updateFormData({
          ...formData,
          [e.target.name]: e.target.checked,
        });
      } else if (e.target.name === 'isAutoInjury' && e.target.checked) {
        formData.autoInsuranceCompany = null;
        formData.AutoInsuranceCompanyPhoneNumber = null;
        formData.autoAttorneyFirstName = null;
        formData.autoAttorneyLastName = null;
        formData.autoHealthInsurancePhone = null;
        formData.autoHealthInsuranceCompany = null;
        formData.autoAttorneyPhone = null;
        formData.autoAccidentPrimaryInsuredName = null;
        formData.autoIDNumber = null;
        formData.letterOfExhaustionFromYourAutoCarrier = null;
        formData.isWorkInjury = false;
        formData.Neither = false;
        updateFormData({
          ...formData,
          [e.target.name]: e.target.checked,
        });
      }

      if (
        e.target.name === 'hasMedicalInsurance' && e.target.value === 'true'
          ? true
          : false
      ) {
        formData.hasMedicalInsurance = true;
      }
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value === 'true' ? true : false,
      });
    } else if (e.target.name === 'didYouHaveTheIncident') {
      formData.isAutoInjury = false;
      formData.isWorkInjury = false;

      updateFormData({
        ...formData,
        [e.target.value]: e.target.checked,
      });
    } else if (e.target.name === 'email') {
      updateFormData({
        ...formData,
        email2: e.target.value,
      });
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  //Format phone number
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: 'user',
  };

  const cardOnClick = () => {
    if (
      !window.location.href.includes('review-form') &&
      patientForm['updateEditButtonForNewPatient']
    ) {
      setIsModalVisible(true);
    }
  };

  setTimeout(() => {
    const inputElement = document.getElementById('phone');
    if (inputElement !== null) {
      inputElement.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement)
      );
    }

    const inputElement1 = document.getElementById('referredByPhone');
    if (inputElement1 !== null) {
      inputElement1.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement1)
      );
    }

    const inputElement10 = document.getElementById('primaryCarePhysicianPhone');
    if (inputElement10 !== null) {
      inputElement10.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement10)
      );
    }

    const inputElement2 = document.getElementById('emergencyContactPhone');
    if (inputElement2 !== null) {
      inputElement2.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement2)
      );
    }

    const inputElement3 = document.getElementById('employerPhone');
    if (inputElement3 !== null) {
      inputElement3.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement3)
      );
    }

    const inputElement4 = document.getElementById('autoHealthInsurancePhone');
    if (inputElement4 !== null) {
      inputElement4.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement4)
      );
    }

    const inputElement5 = document.getElementById('autoAttorneyPhone');
    if (inputElement5 !== null) {
      inputElement5.addEventListener(
        'onkeydown',
        phoneNumberFormatter(inputElement5)
      );
    }
  }, 350);

  function phoneNumberFormatter(input) {
    if (input !== null) {
      const formattedInputValue = formatPhoneNumber(input?.value);
      input.value = formattedInputValue;
    }
  }

  const webcamRef = React.useRef(null);

  const capture = async () => {
    const newReduxStoreKey = props.title.replaceAll(' ', '-');
    setCaptureSaveText('Upload');

    if (captureSaveText === 'upload') {
      setWebCamModal(false);
      setSource(webCameraImage);
      const imageType = {
        imageUrl: webCameraImage,
        imageKey: newReduxStoreKey,
        extension: webCameraImage.split('/')[1].split(';', 1)[0],
      };

      //add image with key
      actions.uploadLetterOfExhaustionCopy(imageType);
      setCaptureSaveText('Take Photo');
    } else {
      var images = webcamRef?.current?.getScreenshot();
      setCaptureSaveText('upload');
      setWebCameraImage(images);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSelect = async (value) => {
    let tempZip = '';
    let tempCity = '';
    let tempState = '';

    let parts = value.split(',');
    if (parts.length === 3) {
      tempCity = parts[0];
      let moreparts = parts[1].trim().split(' ');
      if (moreparts.length === 2) {
        tempState = moreparts[0];
        tempZip = moreparts[1];
      }
    }
    // const results = await geocodeByAddress(value);
    // const latLng = await getLatLng(results[0]);

    // try {
    //   const controller = new AbortController();
    //   const id = setTimeout(
    //     () => controller.abort(),
    //     process.env.REACT_APP_API_TIMEOUT
    //   );
    //   const response = await fetch(
    //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&sensor=true&key=AIzaSyChTcMUCY9Zw3j00st0uKkqTz0RGlOpea8`
    //   );

    //   clearTimeout(id);

    //   const location = await response.json();

    //   //populating formData with selected City
    //   let addressComponents = [];
    //   location &&
    //     location.results.map((item) => {
    //       addressComponents.push(item.address_components.length);
    //     });

    //   const index = addressComponents.indexOf(Math.max(...addressComponents));
    //   const maxValueInArray = Math.max(...addressComponents);
    //   let tempZip = '';
    //   let tempCity = '';
    //   let tempState = '';

    //   location &&
    //     location.results.map((item) => {
    //       if (item.address_components.length === maxValueInArray) {
    //         item.address_components.map((item2) => {
    //           if (
    //             item2.types.length === 1 &&
    //             item2.types[0] === "postal_code"
    //           ) {
    //             tempZip = item2.long_name;
    //             // setZipCode(item2.long_name);

    //             // updateFormData({
    //             //   ...formData,
    //             //   zip: item2.long_name,
    //             // });
    //             // formData.zip = item2.long_name;
    //           }
    //         });
    //       }
    //     });

    //   location &&
    //     location.results[index].address_components.map((item) => {
    //       if (
    //         item.types.length === 2 &&
    //         item.types[0] === "locality" &&
    //         item.types[1] === "political"
    //       ) {
    //         tempCity = item.long_name;
    //         // formData.city = item.long_name;
    //         // updateFormData({
    //         //   ...formData,
    //         //   city: item.long_name,
    //         // });
    //       }

    //       if (
    //         item.types.length === 2 &&
    //         item.types[0] === "administrative_area_level_1" &&
    //         item.types[1] === "political"
    //       ) {
    //         tempState = item.short_name;
    //         // formData.state = item.short_name;
    //         // updateFormData({
    //         //   ...formData,
    //         //   state: item.short_name,
    //         // });
    //       }
    //     });

    if (tempZip && tempCity && tempState) {
      setZipCode(tempZip);
      updateFormData({
        ...formData,
        state: tempState,
        zip: tempZip,
        city: tempCity,
      });
    } else {
      setZipCode('');
      updateFormData({
        ...formData,
        state: '',
        zip: '',
        city: '',
      });
    }
    // } catch (error) {
    //   setApiErrorMessage(true);
    // }
  };

  const handleSelectEmployeeZip = async (value) => {
    let tempZip = '';
    let tempCity = '';
    let tempState = '';

    let parts = value.split(',');
    if (parts.length === 3) {
      tempCity = parts[0];
      let moreparts = parts[1].trim().split(' ');
      if (moreparts.length === 2) {
        tempState = moreparts[0];
        tempZip = moreparts[1];
      }
    }

    if (tempZip && tempCity && tempState) {
      setEmployerZip(tempZip);
      updateFormData({
        ...formData,
        employerState: tempState,
        employerZip: tempZip,
        employerCity: tempCity,
      });
    } else {
      setEmployerZip('');
      updateFormData({
        ...formData,
        employerState: '',
        employerZip: '',
        employerCity: '',
      });
    }
  };

  async function handleCapture(input) {
    const fileSize = input.files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 25) {
      const messageText = `File should not be greater than 25MB`;
      message.error(messageText, [1.5]);
    } else {
      if (input.files) {
        if (input.files.length !== 0) {
          const file = input.files[0];
          const newReduxStoreKey = props.title.replaceAll(' ', '-');

          if (file.type.split('/').pop().includes('pdf')) {
            const imageType = {
              imageUrl: await toBase64(file),
              imageKey: newReduxStoreKey,
              extension: 'pdf',
            };
            actions.uploadLetterOfExhaustionCopy(imageType);
            setIsModalVisible(false);
          } else if (file.type.split('/')[0].includes('image')) {
            let fileCompressed = await imageConversion.compressAccurately(
              file,
              200
            );

            if (
              fileCompressed.type.split('/').pop().includes('pdf') ||
              fileCompressed.type.split('/')[0].includes('image')
            ) {
              const imageType = {
                imageUrl: await toBase64(fileCompressed),
                imageKey: newReduxStoreKey,
                extension: fileCompressed.type.split('/').pop(),
              };
              actions.uploadLetterOfExhaustionCopy(imageType);
            }
            setIsModalVisible(false);
          } else {
            const messageText = `You can only upload a PDF file or an image.`;
            message.error(messageText, [3]);
          }
        }
      }
    }
  }

  const handleDelete = () => {
    setSource(null);
    setDisplayImage(null);
    actions.removeLetterOfExhaustionCopy([]);
    setWebCameraImage('');
    setCaptureSaveText('Take Photo');
  };

  const reTake = () => {
    setCaptureSaveText('Take Photo');
    setWebCameraImage('');
  };

  const setCustomZipCode = (e) => {
    setZipCode(e);
  };

  const handleZipOnBlur = (suggestions, value) => {
    if (suggestions.length > 0) {
      handleSelect(suggestions[0].description);
    } else if (!value) {
      // clear ZIP/City/State
      updateFormData({
        ...formData,
        zip: '',
        city: '',
        state: '',
      });
      setZipCode('');
    }
  };

  const handleEmployerZipOnBlur = (suggestions, value) => {
    if (suggestions.length > 0) {
      handleSelectEmployeeZip(suggestions[0].description);
    } else if (!value) {
      // clear ZIP/City/State
      updateFormData({
        ...formData,
        employerZip: '',
        employerCity: '',
        employerState: '',
      });
      setEmployerZip('');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="patient-info-form">
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            Something went wrong. Please try again later.
          </span>
        </div>
      </Modal>
      {!window.location.href.includes('review-form') && (
        <div className="patient-heading">
          <p>{_resources.PatientInformation.PATINET_INFO}</p>
        </div>
      )}

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
        <NameField
          label={_resources.PatientInformation.FIRST_NAME}
          name="firstName"
          id="firstName"
          validationRules={register({
            required: _resources.PatientInformation.FIRST_NAME_REQ,
            pattern: {
              value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
              message: _resources.PatientInformation.VALID_FIRST_NAME,
            },
          })}
          handleChange={handleChange}
          defaultValue={formData.firstName}
          errors={errors.firstName}
          {...{
            classes,
            disabled: window.location.href.includes('review-form')
              ? true
              : patientForm['updateEditButtonForNewPatient'] ||
                patientForm['updateEditButtonExistingPatient']
              ? window.location.href.includes('appointmentGuid')
                ? true
                : false
              : true,
          }}
        />
        </div>

        <div className="col-lg-6 pateint-column-field">
        <NameField
          label={_resources.PatientInformation.LAST_NAME}
          name="lastName"
          id="lastName"
          validationRules={register({
            required: _resources.PatientInformation.LAST_NAME_REQ,
            pattern: {
              value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
              message: _resources.PatientInformation.VALID_LAST_NAME,
            },
          })}
          handleChange={handleChange}
          defaultValue={formData.lastName}
          errors={errors.lastName}
          {...{
            classes,
            disabled: window.location.href.includes('review-form')
              ? true
              : patientForm['updateEditButtonForNewPatient'] ||
                patientForm['updateEditButtonExistingPatient']
              ? window.location.href.includes('appointmentGuid')
                ? true
                : false
              : true,
          }}
        />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.PREFERED_NAME}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            className={classes.inputField}
            onChange={handleChange}
            name="preferredName"
            id="preferredName"
            key="preferredName"
            inputRef={register({
              pattern: {
                value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                message: _resources.PatientInformation.VALID_PREFERRED_NAME,
              },
            })}
            onKeyDown={(event) => {
              if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={formData.preferredName || ''}
            error={Boolean(errors.preferredName)}
            helperText={errors.preferredName?.message}
            inputProps={{ maxLength: 30 }}
          />
        </div>

        <div className="col-lg-3 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.HEIGHT_FEET}
            variant="outlined"
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            fullWidth
            className={classes.inputField}
            onChange={handleChange}
            name="heightFt"
            id="heightFt"
            key="heightFt"
            onKeyPress={(event) => {
              if (!Numbers_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={formData.heightFt || ''}
            error={Boolean(errors.heightFt)}
            helperText={errors.heightFt?.message}
            inputProps={{ maxLength: 1 }}
          />
        </div>
        <div className="col-lg-3 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.HEIGHT_INCH}
            variant="outlined"
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            fullWidth
            className={classes.inputField}
            onChange={handleChange}
            name="heightIn"
            id="heightIn"
            key="heightIn"
            inputRef={register({
              pattern: {
                value: /\b([0-9]|1[0-1])\b/,
                message: _resources.PatientInformation.HEIGHT_INCH_VALID,
              },
            })}
            onKeyPress={(event) => {
              if (!Numbers_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={formData.heightIn || ''}
            error={Boolean(errors.heightIn)}
            helperText={errors.heightIn?.message}
            inputProps={{ maxLength: 2 }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.WEIGHT}
            variant="outlined"
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            fullWidth
            className={classes.inputField}
            onChange={handleChange}
            name="weight"
            id="weight"
            key="weight"
            inputRef={register({
              pattern: {
                value: /[0-9]{1,3}/,
                message: _resources.PatientInformation.WEIGHT_VALID,
              },
            })}
            onKeyPress={(event) => {
              if (!Numbers_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={formData.weight || ''}
            error={Boolean(errors.weight)}
            helperText={errors.weight?.message}
            inputProps={{ maxLength: 3 }}
          />
        </div>
        <div className="col-lg-6 date-of-birth-col">
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                label={_resources.Checkin.DOB_PLACEHOLDER}
                inputVariant="outlined"
                disabled={true}
                format="MM/dd/yyyy"
                name="dob"
                value={formData.dob || ''}
                onChange={(value) => {
                  props.setFieldValue('date', value);
                  const dateValidated = validateDate(value);
                  setDobError(dateValidated);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                error={dobError}
                helperText={dobError && 'Invalid Date'}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.CITY}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            className={classes.inputField}
            onChange={handleChange}
            disabled={true}
            name="city"
            id="city"
            key="city"
            inputRef={register({
              required: _resources.PatientInformation.CITY_REQ,
            })}
            value={formData.city || patientForm.patientForm.city || ''}
            style={{ marginBottom: '0' }}
            error={
              Boolean(errors.city) &&
              errors?.city?.ref?.value == '' &&
              formData?.city == ''
            }
            helperText={
              errors?.city?.ref.value == '' && formData?.city == ''
                ? _resources.PatientInformation.CITY_REQ
                : ''
            }
          />
        </div>

        <div className="col-lg-3 zip-column-field">
          <divfloat-label>
            <PlacesAutocomplete
              searchOptions={{
                types: ['(regions)'],
                componentRestrictions: { country: 'us' },
              }}
              value={zipCode || ''}
              onChange={(e) => setCustomZipCode(e)}
              onSelect={handleSelect}
              highlightFirstSuggestion={true}
              id="placer"
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => {
                return (
                  <div>
                    <TextField
                      {...getInputProps({
                        onBlur: (e) =>
                          handleZipOnBlur(suggestions, e.target.value),
                        onKeyPress: (event) => {
                          if (!Numbers_Regex.test(event.key)) {
                            event.preventDefault();
                          }
                        },
                      })}
                      disabled={
                        window.location.href.includes('review-form')
                          ? true
                          : patientForm['updateEditButtonForNewPatient']
                          ? false
                          : patientForm['updateEditButtonExistingPatient']
                          ? true
                          : false
                      }
                      label={_resources.PatientInformation.ZIP}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      className={'loc-input zip-input'}
                      inputProps={{ maxLength: 5 }}
                      name="zipCode"
                      id="location-input"
                      key="location-input"
                    />

                    <div className="autocomplete-dropdown-container">
                      {loading ? (
                        <Spin
                          className="m-auto plcs-auto-complete-spinner"
                          indicator={antIcon}
                        />
                      ) : null}

                      {suggestions.map((suggestion, index) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? '#41b6e6'
                            : '#fff',
                          borderTop: '1px solid #00504633',
                          paddingTop: '1px',
                          paddingBottom: '1px',
                        };

                        return (
                          <div
                            key={index}
                            className="suggestions"
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            <img
                              src={clinicIcon}
                              className="suggestions-location-icon icon-location"
                              alt="clinic-icon"
                            />
                            {suggestion.description.slice(0, 60)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </divfloat-label>
          {errors.zipCode?.type === 'required' && zipCode == '' && (
            <p
              role="alert"
              style={{
                color: '#f44336',
                fontSize: '0.75rem',
                backgroundColor: '#fff',
                paddingLeft: '4px',
              }}
            >
              {_resources.PatientInformation.ZIP_REQUIRED}
            </p>
          )}

          <TextField
            style={{ opacity: '0', height: '0' }}
            label={_resources.PatientInformation.ZIP}
            variant="outlined"
            size="small"
            fullWidth
            value={zipCode}
            name="zipCode"
            id="location-inputs"
            key="location-inputs"
            inputRef={register({
              required: _resources.PatientInformation.ZIP_REQUIRED,
            })}
            defaultValue={zipCode || ''}
            inputProps={{ maxLength: 5 }}
          />
        </div>
        <div className="col-lg-3 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.STATE}
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={true}
            className={classes.inputField}
            name="state"
            id="state"
            key="state"
            inputProps={{ maxLength: 30 }}
            value={formData.state || patientForm.patientForm.state || ''}
            inputRef={register({
              required: _resources.PatientInformation.STATE_REQ,
            })}
            style={{ marginBottom: '0' }}
            error={
              Boolean(errors.state) &&
              errors?.state?.ref?.value == '' &&
              formData?.state == ''
            }
            helperText={
              errors?.state?.ref.value == '' && formData?.state == ''
                ? _resources.PatientInformation.STATE_REQ
                : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.ADDRESS1}
            variant="outlined"
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? true
                : false
            }
            fullWidth
            className={classes.inputField}
            onChange={handleChange}
            name="address1"
            id="address1"
            key="address1"
            inputRef={register({
              required: _resources.PatientInformation.ADD1_REQ,
            })}
            defaultValue={formData.address1 || ''}
            error={Boolean(errors.address1)}
            helperText={Boolean(errors.address1) ? _resources.PatientInformation.ADD1_REQ : ''}
            inputProps={{ maxLength: 30 }}
            onKeyDown={(event) => {
              if (!Alpha_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.ADDRESS2}
            variant="outlined"
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? true
                : false
            }
            fullWidth
            className={classes.inputField}
            onChange={handleChange}
            name="address2"
            id="address2"
            key="address2"
            defaultValue={formData.address2 || ''}
            error={Boolean(errors.address2)}
            helperText={errors.address2?.message}
            inputProps={{ maxLength: 30 }}
            onKeyDown={(event) => {
              if (!Alpha_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <label style={{ fontWeight: '600' }}>
            {_resources.patientForm.GENDER}
          </label>{' '}
          <br></br>
          <FormControl
            component="fieldset"
            className="gender-rad inside-form"
            style={{ backgroundColor: 'transparent !important' }}
          >
            <RadioGroup
              onChange={onGenderChange}
              name="radio-buttons-group"
              defaultValue={gender}
              value={gender}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="M"
                control={<Radio />}
                label={_resources.patientForm.MALE}
              />
              <FormControlLabel
                value="F"
                control={<Radio />}
                label={_resources.patientForm.FEMALE}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="patient-heading mb-3">
        <p>{_resources.PatientInformation.CONTACT_HEADER}</p>
        <img src={infoIcon} alt="Info Icon" />
        <span className="consent-para">
          {_resources.PatientInformation.CONTACT_INFO}
        </span>
      </div>

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.PHONENUMBER}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? window.location.href.includes('appointmentGuid')
                  ? true
                  : false
                : patientForm['updateEditButtonExistingPatient']
                ? true
                : window.location.href.includes('appointmentGuid')
                ? true
                : false
            }
            onChange={handleChange}
            className={classes.inputField}
            name="phone"
            id="phone"
            key="phone"
            inputRef={register({
              required: _resources.PatientInformation.PHONE_REQ,
              pattern: {
                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: _resources.PatientInformation.PHONE_VALID,
              },
            })}
            defaultValue={
              formatPhoneNumber(patientPhone) ||
              formatPhoneNumber(newPatientValidation)
            }
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            inputProps={{ maxLength: 14 }}
          />
        </div>
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.EMAIL}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className={classes.inputField}
            name="email"
            id="email"
            key="email"
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? window.location.href.includes('appointmentGuid')
                  ? false
                  : false
                : patientForm['updateEditButtonExistingPatient']
                ? true
                : window.location.href.includes('appointmentGuid')
                ? false
                : false
            }
            inputRef={register({
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: _resources.PatientInformation.EMAIL_VALID,
              },
            })}
            defaultValue={formData.email || ''}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
      <div className="patient-heading">
        <p>{_resources.PatientInformation.HOW_DID_YOU_HEAR_ABOUT_CORA}</p>
      </div>

      <div className="row">
        <div className="col-lg-6 hear-info">
          <Autocomplete
            options={socialMediaTypes}
            required={true}
            name="hearAbout"
            id="hearAbout"
            onChange={(event, newValue) =>
              handleInputChange('hearAbout', newValue)
            }
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            defaultValue={formData.hearAbout}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  _resources.PatientInformation
                    .FROM_WHERE_DID_YOU_HEAR_ABOUT_CORA
                }
                size="small"
                variant="outlined"
                name="hearAbout"
                inputProps={{
                  ...params.inputProps,
                  maxLength: 30,
                }}
                id="hearAbout"
                key="hearAbout"
              />
            )}
          />
        </div>
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.NAME_WHO_REFERRED_YOU}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            className={classes.inputField}
            onChange={handleChange}
            name="referredByName"
            id="referredByName"
            key="referredByName"
            inputRef={register({
              pattern: {
                value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                message: _resources.PatientInformation.VALID_NAME,
              },
            })}
            onKeyDown={(event) => {
              if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={formData.referredByName || ''}
            error={Boolean(errors.referredByName)}
            helperText={errors.referredByName?.message}
            inputProps={{ maxLength: 30 }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.PRIMARY_CARE_PHONENUMBER}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            onChange={handleChange}
            className={classes.inputField}
            name="referredByPhone"
            id="referredByPhone"
            key="referredByPhone"
            inputRef={register({
              pattern: {
                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: _resources.PatientInformation.PHONE_VALID,
              },
            })}
            defaultValue={formatPhoneNumber(formData.referredByPhone || '')}
            error={Boolean(errors.referredByPhone)}
            helperText={errors.referredByPhone?.message}
            inputProps={{ maxLength: 14 }}
          />
        </div>
      </div>
      <div className="patient-heading">
        <p>{_resources.PatientInformation.PRIMARY_CARE_HEADING}</p>
      </div>
      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.PRIMARY_CARE_FIRST_NAME}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            onKeyDown={(event) => {
              if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            className={classes.inputField}
            onChange={handleChange}
            name="primaryCarePhysicianFirstName"
            id="primaryCarePhysicianFirstName"
            key="primaryCarePhysicianFirstName"
            inputRef={register({
              pattern: {
                value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                message: _resources.PatientInformation.VALID_NAME,
              },
            })}
            defaultValue={formData.primaryCarePhysicianFirstName || ''}
            error={Boolean(errors.primaryCarePhysicianFirstName)}
            helperText={errors.primaryCarePhysicianFirstName?.message}
            inputProps={{ maxLength: 30 }}
          />
        </div>
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.PRIMARY_CARE_LAST_NAME}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            onKeyDown={(event) => {
              if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            className={classes.inputField}
            onChange={handleChange}
            name="primaryCarePhysicianLastName"
            id="primaryCarePhysicianLastName"
            key="primaryCarePhysicianLastName"
            inputRef={register({
              pattern: {
                value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                message: _resources.PatientInformation.VALID_NAME,
              },
            })}
            defaultValue={formData.primaryCarePhysicianLastName || ''}
            error={Boolean(errors.primaryCarePhysicianLastName)}
            helperText={errors.primaryCarePhysicianLastName?.message}
            inputProps={{ maxLength: 30 }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          <TextField
            label={_resources.PatientInformation.PRIMARY_CARE_PHONENUMBER}
            variant="outlined"
            fullWidth
            size="small"
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            onChange={handleChange}
            className={classes.inputField}
            name="primaryCarePhysicianPhone"
            id="primaryCarePhysicianPhone"
            key="primaryCarePhysicianPhone"
            inputRef={register({
              pattern: {
                value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: _resources.PatientInformation.PHONE_VALID,
              },
            })}
            defaultValue={formatPhoneNumber(
              formData.primaryCarePhysicianPhone || ''
            )}
            error={Boolean(errors.primaryCarePhysicianPhone)}
            helperText={errors.primaryCarePhysicianPhone?.message}
            inputProps={{ maxLength: 14 }}
          />
        </div>
      </div>
      <div className="patient-heading">
        <p>{_resources.PatientInformation.EMERGENCY_CONTACT_HEADING}</p>
      </div>
      <div className="row">
        <div className="col-lg-6 pateint-column-field">
          {patientForm['updateEditButtonForNewPatient'] ? (
            <TextField
              label={_resources.PatientInformation.EMERGENCY_CONTACT_FIRST_NAME}
              variant="outlined"
              fullWidth
              size="small"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              className={classes.inputField}
              onChange={handleChange}
              name="emergencyContactFirstName"
              id="emergencyContactFirstName"
              key="emergencyContactFirstName"
              inputRef={register({
                required: _resources.PatientInformation.FIRST_NAME_REQ,
                pattern: {
                  value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                  message: _resources.PatientInformation.VALID_NAME,
                },
              })}
              onKeyDown={(event) => {
                if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
              defaultValue={formData.emergencyContactFirstName || ''}
              error={Boolean(errors.emergencyContactFirstName)}
              helperText={errors.emergencyContactFirstName?.message}
              inputProps={{ maxLength: 14 }}
            />
          ) : (
            <TextField
              label={_resources.PatientInformation.EMERGENCY_CONTACT_FIRST_NAME}
              variant="outlined"
              fullWidth
              size="small"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              onKeyDown={(event) => {
                if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className={classes.inputField}
              onChange={handleChange}
              name="emergencyContactFirstName"
              id="emergencyContactFirstName"
              key="emergencyContactFirstName"
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                  message: _resources.PatientInformation.VALID_NAME,
                },
              })}
              defaultValue={formData.emergencyContactFirstName || ''}
              error={Boolean(errors.emergencyContactFirstName)}
              helperText={_resources.PatientInformation.FIRST_NAME_REQ}
              inputProps={{ maxLength: 14 }}
            />
          )}
        </div>
        <div className="col-lg-6 pateint-column-field">
          {patientForm['updateEditButtonForNewPatient'] ? (
            <TextField
              label={_resources.PatientInformation.EMERGENCY_CONTACT_LAST_NAME}
              variant="outlined"
              fullWidth
              size="small"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              className={classes.inputField}
              onChange={handleChange}
              name="emergencyContactLastName"
              id="emergencyContactLastName"
              key="emergencyContactLastName"
              inputRef={register({
                required: _resources.PatientInformation.LAST_NAME_REQ,
                pattern: {
                  value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                  message: _resources.PatientInformation.VALID_NAME,
                },
              })}
              onKeyDown={(event) => {
                if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
              defaultValue={formData.emergencyContactLastName || ''}
              error={Boolean(errors.emergencyContactLastName)}
              helperText={Boolean(errors.emergencyContactLastName) ? _resources.PatientInformation.LAST_NAME_REQ : ''}
              inputProps={{ maxLength: 30 }}
            />
          ) : (
            <TextField
              label={_resources.PatientInformation.EMERGENCY_CONTACT_LAST_NAME}
              variant="outlined"
              fullWidth
              size="small"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              className={classes.inputField}
              onChange={handleChange}
              name="emergencyContactLastName"
              id="emergencyContactLastName"
              key="emergencyContactLastName"
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                  message: _resources.PatientInformation.VALID_NAME,
                },
              })}
              onKeyDown={(event) => {
                if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
              defaultValue={formData.emergencyContactLastName || ''}
              error={Boolean(errors.emergencyContactLastName)}
              helperText={Boolean(errors.emergencyContactLastName) ? _resources.PatientInformation.LAST_NAME_REQ : ''}
              inputProps={{ maxLength: 30 }}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 hear-info">
          <Autocomplete
            options={relationshipTypes}
            required={true}
            name="emergencyContactRelationship"
            id="emergencyContactRelationship"
            onChange={(event, newValue) =>
              handleInputChange('emergencyContactRelationship', newValue)
            }
            disabled={
              window.location.href.includes('review-form')
                ? true
                : patientForm['updateEditButtonForNewPatient']
                ? false
                : patientForm['updateEditButtonExistingPatient']
                ? false
                : true
            }
            defaultValue={formData.emergencyContactRelationship}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  _resources.PatientInformation.EMERGENCY_CONTACT_RELATIONSHIP
                }
                size="small"
                variant="outlined"
                name="emergencyContactRelationship"
                inputProps={{
                  ...params.inputProps,
                  maxLength: 30,
                }}
                id="emergencyContactRelationship"
                key="emergencyContactRelationship"
              />
            )}
          />
        </div>
        <div className="col-lg-6 pateint-column-field">
          {patientForm['updateEditButtonForNewPatient'] ? (
            <TextField
              label={
                _resources.PatientInformation.EMERGENCY_CONTACT_PHONENUMBER
              }
              variant="outlined"
              fullWidth
              size="small"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              onChange={handleChange}
              className={classes.inputField}
              name="emergencyContactPhone"
              id="emergencyContactPhone"
              key="emergencyContactPhone"
              inputRef={register({
                required: _resources.PatientInformation.PHONE_REQ,
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                  message: _resources.PatientInformation.PHONE_VALID,
                },
              })}
              defaultValue={formatPhoneNumber(
                formData.emergencyContactPhone || ''
              )}
              error={Boolean(errors.emergencyContactPhone)}
              helperText={errors.emergencyContactPhone?.message}
              inputProps={{ maxLength: 14 }}
            />
          ) : (
            <TextField
              label={
                _resources.PatientInformation.EMERGENCY_CONTACT_PHONENUMBER
              }
              variant="outlined"
              fullWidth
              size="small"
              disabled={
                window.location.href.includes('review-form')
                  ? true
                  : patientForm['updateEditButtonForNewPatient']
                  ? false
                  : patientForm['updateEditButtonExistingPatient']
                  ? false
                  : true
              }
              onChange={handleChange}
              className={classes.inputField}
              name="emergencyContactPhone"
              id="emergencyContactPhone"
              key="emergencyContactPhone"
              inputRef={register({
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                  message: _resources.PatientInformation.PHONE_VALID,
                },
              })}
              defaultValue={formatPhoneNumber(
                formData.emergencyContactPhone || ''
              )}
              error={Boolean(errors.emergencyContactPhone)}
              helperText={errors.emergencyContactPhone?.message}
              inputProps={{ maxLength: 14 }}
            />
          )}
        </div>
      </div>

      <div className="patient-heading">
        <p>{_resources.PatientInformation.ARE_YOU_MEDICARE_PATIENT}</p>
      </div>
      <div className="row radio-group">
        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label">
          <FormControlLabel
            name="hasMedicalInsurance"
            onChange={(event) => handleChange(event)}
            value={true}
            control={
              <Radio
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
              />
            }
            label={_resources.PatientInformation.YES}
            checked={
              formData.hasMedicalInsurance !== null
                ? formData.hasMedicalInsurance
                : false
            }
          />
          <FormControlLabel
            name="hasMedicalInsurance"
            onChange={(event) => handleChange(event)}
            control={
              <Radio
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
              />
            }
            value={false}
            label={_resources.PatientInformation.NO}
            checked={
              formData.hasMedicalInsurance !== null
                ? !formData.hasMedicalInsurance
                : false
            }
          />
        </RadioGroup>
      </div>
      <div className="patient-heading">
        <p>{_resources.PatientInformation.WHERE_DID_YOU_HAVE_THE_INCIDENT}</p>
      </div>
      <div className="row radio-group">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="workOrAuto"
        >
          <FormControlLabel
            name="isWorkInjury"
            onChange={(event) => handleChange(event)}
            control={
              <Radio
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
              />
            }
            value={true}
            label={_resources.PatientInformation.WORK}
            checked={formData.isWorkInjury ? true : false}
          />
          <FormControlLabel
            name="isAutoInjury"
            onChange={(event) => handleChange(event)}
            control={
              <Radio
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
              />
            }
            value={true}
            label={_resources.PatientInformation.AUTO_ACCIDENT}
            checked={formData.isAutoInjury ? true : false}
          />
          <FormControlLabel
            name="didYouHaveTheIncident"
            onChange={(event) => handleChange(event)}
            value="Neither"
            control={
              <Radio
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
              />
            }
            label={_resources.PatientInformation.NEITHER}
            checked={
              formData.isWorkInjury !== null &&
              !formData.isWorkInjury &&
              formData.isAutoInjury !== null &&
              !formData.isAutoInjury
                ? true
                : false
            }
          />
        </RadioGroup>
      </div>

      {formData.isWorkInjury && (
        <>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.WORK_EMPLOYER_NAME}
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onKeyDown={(event) => {
                  if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={classes.inputField}
                onChange={handleChange}
                name="employerName"
                id="employerName"
                key="employerName"
                inputRef={register({
                  required: _resources.PatientInformation.NAME_REQ,
                  pattern: {
                    value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                    message: _resources.PatientInformation.VALID_NAME,
                  },
                })}
                defaultValue={formData.employerName || ''}
                error={Boolean(errors.employerName)}
                helperText={errors.employerName?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.WORK_PHONE_NUMBER}
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onChange={handleChange}
                className={classes.inputField}
                name="employerPhone"
                id="employerPhone"
                key="employerPhone"
                inputRef={register({
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message: _resources.PatientInformation.PHONE_VALID,
                  },
                })}
                defaultValue={formatPhoneNumber(formData.employerPhone || '')}
                error={Boolean(errors.employerPhone)}
                helperText={errors.employerPhone?.message}
                inputProps={{ maxLength: 14 }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.WORK_CITY}
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                className={classes.inputField}
                name="employerCity"
                id="employerCity"
                key="employerCity"
                inputProps={{ maxLength: 30 }}
                disabled={true}
                inputRef={register({})}
                value={formData.employerCity || ''}
                error={Boolean(errors.employerCity)}
                helperText={errors.employerCity?.message}
              />
            </div>

            <div className="col-lg-3 zip-column-field">
              <divfloat-label>
                <PlacesAutocomplete
                  searchOptions={{
                    types: ['(regions)'],
                    componentRestrictions: { country: ['us'] },
                  }}
                  value={employerZipCode || ''}
                  onChange={setEmployerZip}
                  onSelect={handleSelectEmployeeZip}
                  highlightFirstSuggestion={true}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        {...getInputProps({
                          onBlur: (e) =>
                            handleEmployerZipOnBlur(
                              suggestions,
                              e.target.value
                            ),
                          onKeyPress: (event) => {
                            if (!Numbers_Regex.test(event.key)) {
                              event.preventDefault();
                            }
                          },
                        })}
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : patientForm['updateEditButtonForNewPatient']
                            ? false
                            : patientForm['updateEditButtonExistingPatient']
                            ? true
                            : false
                        }
                        label={_resources.PatientInformation.ZIP}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 5 }}
                        InputLabelProps={{ shrink: true }}
                        className={'loc-input zip-input'}
                        name="employerZipCode"
                        id="location-input-employerZipCode"
                        key="location-input-employerZipCode"
                        // inputRef={register({
                        //   required: _resources.PatientInformation.ZIP_REQUIRED,
                        // })}
                        // error={Boolean(errors.employerZipCode)}
                        // helperText={errors.employerZipCode?.message}
                      />

                      {/* {formData.employerZip === null && onSubmitFlag && (
                        <span className="error">
                          {_resources.PatientInformation.ZIP_REQUIRED}
                        </span>
                      )} */}

                      {/* {employerZipCode !== "" &&
                        onSubmitFlag &&
                        employerZipCode.length === 0 && (
                          <span className="error" style={{ color: "red" }}>
                            {_resources.PatientInformation.ZIP_REQUIRED}
                          </span>
                        )} */}

                      <div className="autocomplete-dropdown-container">
                        {loading ? (
                          <Spin
                            className="m-auto plcs-auto-complete-spinner"
                            indicator={antIcon}
                          />
                        ) : null}

                        {suggestions.map((suggestion, index) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? '#41b6e6'
                              : '#fff',
                            borderTop: '1px solid #00504633',
                            paddingTop: '1px',
                            paddingBottom: '1px',
                          };

                          return (
                            <div
                              key={index}
                              className="suggestions"
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              <img
                                src={clinicIcon}
                                className="suggestions-location-icon icon-location"
                                alt="clinic-icon"
                              />
                              {suggestion.description.slice(0, 60)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </divfloat-label>

              {errors.employerZipCode?.type === 'required' &&
                employerZipCode == '' && (
                  <p
                    role="alert"
                    style={{
                      color: '#f44336',
                      fontSize: '0.75rem',
                      marginTop: '4px',
                      backgroundColor: '#fff',
                      paddingLeft: '4px',
                    }}
                  >
                    {_resources.PatientInformation.ZIP_REQUIRED}
                  </p>
                )}

              <TextField
                style={{ opacity: '0', height: '0' }}
                label={_resources.PatientInformation.ZIP}
                variant="outlined"
                size="small"
                fullWidth
                value={employerZipCode}
                name="employerZipCode"
                id="location-input-emp"
                key="location-input-emp"
                inputRef={register({
                  required: _resources.PatientInformation.ZIP_REQUIRED,
                })}
                defaultValue={employerZipCode || ''}
                inputProps={{ maxLength: 30 }}
              />
            </div>

            <div className="col-lg-3 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.STATE}
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                className={classes.inputField}
                name="employerState"
                id="employerState"
                key="employerState"
                inputProps={{ maxLength: 30 }}
                disabled={true}
                inputRef={register({})}
                value={formData.employerState || ''}
                error={Boolean(errors.employerState)}
                helperText={errors.employerState?.message}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.WORK_ADDRESS1}
                variant="outlined"
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                name="employerAddress1"
                id="employerAddress1"
                key="employerAddress1"
                defaultValue={formData.employerAddress1 || ''}
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.WORK_ADDRESS2}
                variant="outlined"
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                name="employerAddress2"
                id="employerAddress2"
                key="employerAddress2"
                defaultValue={formData.employerAddress2 || ''}
                inputProps={{ maxLength: 30 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation.WORK_WHAT_IS_YOUR_REGULAR_JOB
                }
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                className={classes.inputField}
                onChange={handleChange}
                name="job"
                id="job"
                key="job"
                inputRef={register({
                  pattern: {
                    value: /^[A-Za-z& ]{0,30}$/,
                    message: _resources.PatientInformation.VALID_JOB,
                  },
                })}
                defaultValue={formData.job || ''}
                error={Boolean(errors.job)}
                helperText={errors.job?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <div className="col-lg-6 hear-info">
              <Autocomplete
                id="ip-select"
                options={employmentTypes}
                name="jobStatus"
                onChange={(event, newValue) =>
                  handleInputChange('jobStatus', newValue)
                }
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                defaultValue={formData.jobStatus}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={_resources.PatientInformation.WORK_STATUS_OF_JOB}
                    size="small"
                    variant="outlined"
                    name="jobStatus"
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 30,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </>
      )}
      {formData.isAutoInjury && (
        <>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation.AUTO_ACCIDENT_INSURANCE_COMPANY
                }
                variant="outlined"
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onKeyDown={(event) => {
                  if (!Alphabets_Regex.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                name="autoInsuranceCompany"
                id="autoInsuranceCompany"
                key="autoInsuranceCompany"
                inputRef={register({
                  required: _resources.PatientInformation.AUTO_INSURANCE_REQ,
                  pattern: {
                    value: /^[A-Za-z ]{0,30}$/,
                    message: _resources.PatientInformation.VALID_NAME,
                  },
                })}
                defaultValue={formData.autoInsuranceCompany || ''}
                error={Boolean(errors.autoInsuranceCompany)}
                helperText={errors.autoInsuranceCompany?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.AUTO_ACCIDENT_PHONE_NUMBER}
                variant="outlined"
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                name="AutoInsuranceCompanyPhoneNumber"
                id="AutoInsuranceCompanyPhoneNumber"
                key="AutoInsuranceCompanyPhoneNumber"
                inputRef={register({
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message: _resources.PatientInformation.PHONE_VALID,
                  },
                })}
                defaultValue={formatPhoneNumber(
                  formData.AutoInsuranceCompanyPhoneNumber || ''
                )}
                error={Boolean(errors.AutoInsuranceCompanyPhoneNumber)}
                helperText={errors.AutoInsuranceCompanyPhoneNumber?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation
                    .AUTO_ACCIDENT_ATTORNEY_FIRST_NAME
                }
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onKeyDown={(event) => {
                  if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={classes.inputField}
                onChange={handleChange}
                name="autoAttorneyFirstName"
                id="autoAttorneyFirstName"
                key="autoAttorneyFirstName"
                inputRef={register({
                  pattern: {
                    value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                    message: _resources.PatientInformation.VALID_NAME,
                  },
                })}
                defaultValue={formData.autoAttorneyFirstName || ''}
                error={Boolean(errors.autoAttorneyFirstName)}
                helperText={errors.autoAttorneyFirstName?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation.AUTO_ACCIDENT_ATTORNEY_LAST_NAME
                }
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onKeyDown={(event) => {
                  if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={classes.inputField}
                onChange={handleChange}
                name="autoAttorneyLastName"
                id="autoAttorneyLastName"
                key="autoAttorneyLastName"
                inputRef={register({
                  pattern: {
                    value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                    message: _resources.PatientInformation.VALID_NAME,
                  },
                })}
                defaultValue={formData.autoAttorneyLastName || ''}
                error={Boolean(errors.autoAttorneyLastName)}
                helperText={errors.autoAttorneyLastName?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation
                    .AUTO_ACCIDENT_ATTORNEY_PHONE_NUMBER
                }
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onChange={handleChange}
                className={classes.inputField}
                name="autoAttorneyPhone"
                id="autoAttorneyPhone"
                key="autoAttorneyPhone"
                inputRef={register({
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message: _resources.PatientInformation.PHONE_VALID,
                  },
                })}
                defaultValue={formatPhoneNumber(
                  formData.autoAttorneyPhone || ''
                )}
                error={Boolean(errors.autoAttorneyPhone)}
                helperText={errors.autoAttorneyPhone?.message}
                inputProps={{ maxLength: 14 }}
              />
            </div>
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation
                    .AUTO_ACCIDENT_HEALTH_INSURANCE_COMPANY
                }
                variant="outlined"
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                name="autoHealthInsuranceCompany"
                id="autoHealthInsuranceCompany"
                key="autoHealthInsuranceCompany"
                inputRef={register({
                  required: _resources.PatientInformation.HEALTH_INSURANCE_REQ,
                  pattern: {
                    value: /^[A-Za-z& ]{0,30}$/,
                    message: _resources.PatientInformation.VALID_NAME,
                  },
                })}
                defaultValue={formData.autoHealthInsuranceCompany || ''}
                error={Boolean(errors.autoHealthInsuranceCompany)}
                helperText={errors.autoHealthInsuranceCompany?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation
                    .AUTO_ACCIDENT_HEALTH_INSURANCE_PHONE_NUMBER
                }
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onChange={handleChange}
                className={classes.inputField}
                name="autoHealthInsurancePhone"
                id="autoHealthInsurancePhone"
                key="autoHealthInsurancePhone"
                inputRef={register({
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message: _resources.PatientInformation.PHONE_VALID,
                  },
                })}
                defaultValue={formatPhoneNumber(
                  formData.autoHealthInsurancePhone || ''
                )}
                error={Boolean(errors.autoHealthInsurancePhone)}
                helperText={errors.autoHealthInsurancePhone?.message}
                inputProps={{ maxLength: 14 }}
              />
            </div>
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={
                  _resources.PatientInformation
                    .AUTO_ACCIDENT_NAME_OF_PRIMARY_INSURED
                }
                variant="outlined"
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onKeyDown={(event) => {
                  if (!Alphabets_Hyphen_Spaces_Regex.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                fullWidth
                className={classes.inputField}
                onChange={handleChange}
                name="autoAccidentPrimaryInsuredName"
                id="autoAccidentPrimaryInsuredName"
                key="autoAccidentPrimaryInsuredName"
                inputRef={register({
                  pattern: {
                    value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                    message: _resources.PatientInformation.VALID_NAME,
                  },
                })}
                defaultValue={formData.autoAccidentPrimaryInsuredName || ''}
                error={Boolean(errors.autoAccidentPrimaryInsuredName)}
                helperText={errors.autoAccidentPrimaryInsuredName?.message}
                inputProps={{ maxLength: 30 }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pateint-column-field">
              <TextField
                label={_resources.PatientInformation.AUTO_ACCIDENT_ID_NUMBER}
                variant="outlined"
                fullWidth
                size="small"
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onChange={handleChange}
                className={classes.inputField}
                name="autoIDNumber"
                id="autoIDNumber"
                key="autoIDNumber"
                defaultValue={formData.autoIDNumber || ''}
                inputProps={{ maxLength: 14 }}
              />
            </div>
          </div>
          <div className="patient-heading">
            <p>
              {
                _resources.PatientInformation
                  .AUTO_ACCIDENT_DO_YOU_HAVE_LETTER_OF_EXHUASTION
              }
            </p>
          </div>
          <div className="row radio-group ">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                name="letterOfExhaustionFromYourAutoCarrier"
                onChange={(event) => handleChange(event)}
                value={true}
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                  />
                }
                label={_resources.PatientInformation.YES}
                checked={
                  formData.letterOfExhaustionFromYourAutoCarrier === true
                    ? true
                    : false
                }
              />
              <FormControlLabel
                name="letterOfExhaustionFromYourAutoCarrier"
                onChange={(event) => handleChange(event)}
                value={false}
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                  />
                }
                label={_resources.PatientInformation.NO}
                checked={
                  formData.letterOfExhaustionFromYourAutoCarrier === false
                    ? true
                    : false
                }
              />
              <FormControlLabel
                name="letterOfExhaustionFromYourAutoCarrier"
                onChange={(event) => handleChange(event)}
                value="NotSure"
                control={
                  <Radio
                    disabled={
                      window.location.href.includes('review-form')
                        ? true
                        : patientForm['updateEditButtonForNewPatient']
                        ? false
                        : patientForm['updateEditButtonExistingPatient']
                        ? false
                        : true
                    }
                  />
                }
                label={_resources.PatientInformation.AUTO_ACCIDENT_NOT_SURE}
                checked={
                  formData.letterOfExhaustionFromYourAutoCarrier === 'NotSure'
                    ? true
                    : false
                }
              />
            </RadioGroup>
          </div>

          {formData.letterOfExhaustionFromYourAutoCarrier === true && (
            <>
              <Card
                disabled={
                  window.location.href.includes('review-form')
                    ? true
                    : patientForm['updateEditButtonForNewPatient']
                    ? false
                    : patientForm['updateEditButtonExistingPatient']
                    ? false
                    : true
                }
                onClick={cardOnClick}
              >
                {!displayImage ? (
                  <p className="id-icon-wrapper">
                    <i className="id-icon"></i> {props.title}
                  </p>
                ) : (
                  <p>{_resources.PatientInformation.UPLOADED}</p>
                )}

                {source && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    border={1}
                    className={props.classes.imgBox}
                  >
                    <img
                      src={displayImage}
                      alt={'snap'}
                      className={props.classes.img}
                    ></img>
                    {!window.location.href.includes('review-form') &&
                      patientForm['updateEditButtonForNewPatient'] && (
                        <Button
                          type="danger"
                          className="cross-btn"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            handleDelete();
                          }}
                        >
                          x
                        </Button>
                      )}
                  </Box>
                )}
              </Card>
              <Modal
                centered
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                className="upload-document-modal capture-upload-modal"
              >
                {props.screen === 'desktop' ? (
                  <button
                    className="cam-upload-btn"
                    onClick={() => {
                      setIsModalVisible(false);
                      setWebCamModal(true);
                    }}
                  >
                    <i className="camera-icon"></i>{' '}
                    {_resources.PatientInformation.CAPTURE_WEBCAMERA}
                  </button>
                ) : (
                  <>
                    <div className={props.classes.root}>
                      <Grid container>
                        <Grid item xs={12}>
                          <label className="file-upload-btn">
                            <i className="camera-icon"></i>
                            {_resources.PatientInformation.CAPTURE_CAMERA}
                            <input
                              accept="image/*"
                              className={props.classes.input}
                              id="icon-button-file"
                              type="file"
                              capture="environment"
                              onChange={(e) => handleCapture(e.target)}
                            />
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
                <div>
                  <label className="file-upload-btn">
                    <i className="upload-img-icon "></i>
                    {_resources.PatientInformation.UPLOAD_FROM_PC}
                    <input
                      accept="image/*,application/pdf"
                      type="file"
                      name="myImage"
                      id="upload-from-computer-drive"
                      onChange={(e) => handleCapture(e.target)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </label>
                </div>
              </Modal>

              <Modal
                visible={webCamModal}
                onOk={() => setWebCamModal(false)}
                onCancel={() => setWebCamModal(false)}
                className="upload-document-modal capture-modal"
                width={400}
              >
                {webCameraImage ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    border={1}
                    className={props.classes.imgBox}
                  >
                    <img
                      src={webCameraImage}
                      alt={'snap'}
                      className={props.classes.img}
                    ></img>
                  </Box>
                ) : (
                  <Webcam
                    style={{
                      width: '300px',
                      height: '300px',
                    }}
                    ref={webcamRef}
                    screenshotQuality={1}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                )}
                <div className="cam-btn-wrapper">
                  {webCameraImage && (
                    <button className="retake-btn" onClick={reTake}>
                      {_resources.PatientInformation.RETAKE}
                    </button>
                  )}
                  <button className="take-btn" onClick={capture}>
                    {captureSaveText}
                  </button>
                </div>
              </Modal>
            </>
          )}
        </>
      )}

      {window.location.href.includes('patient-form') ? (
        <>
          {patientForm.existingPatientWithAppointment ? (
            <Button
              className="dual-book-button continue-btn"
              variant="contained"
              type="submit"
              size="large"
            >
              {_resources.PatientInformation.CONT_UPLOAD_DOCS_BUTTON}
            </Button>
          ) : (
            <Button
              className="dual-book-button continue-btn"
              variant="contained"
              type="submit"
              size="large"
            >
              {_resources.PatientInformation.CONTINUE_MEDICAL_HISTORY_BUTTON}
            </Button>
          )}
        </>
      ) : null}
    </form>
  );
}
export default PatientInformationComponent;
