const reducer = (searchClinicsState = {}, { type, payload }) => {
  switch (type) {
    case "search":
      return { ...payload };
    default:
      return searchClinicsState;
  }
};

export default reducer;
