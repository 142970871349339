import React from "react";
import coraLogo from "./../assets/img/cora-logo.svg";

export default function Header() {
  return (
    <div className="app-header">
      <div className="app-header-logo">
        <a href='https://coraphysicaltherapy.com'>
          <img src={coraLogo} alt="CORA Logo" id="app-logo" />
        </a>
      </div>
    </div>
  );
}
