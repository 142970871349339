/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import FloatLabel from "./floatLabel";
import { Helmet } from "react-helmet";
import { Select, Spin, Switch, Modal } from "antd";
import { Button } from "@material-ui/core";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import calculatorIcon from "./../assets/img/calculator-icon.svg";
import searchIcon from "./../assets/img/search-icon.svg";
import magnifyingGlassIcon from "./../assets/img/magnifying-glass.png";
import clinicIcon from "./../assets/img/location-pin-icon.svg";
import errorImage from "./../assets/img/error-image.svg";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useForm } from "react-hook-form";

function SearchClinicComponent(props) {
  const clinicsSearchFields = useSelector((state) => state.clinics);

  //OnPage refresh onclinic list replace initialForm with existing Data to Prevent data loss
  let initialFormData = {};
  if (props.fromClinicList) {
    initialFormData = {
      typeofTherapyId: props.fromclinicListForm.typeofTherapyId,
      city: props.fromclinicListForm.city,
      zip: props.fromclinicListForm.zip,
      appointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      insuranceProviderId: props.fromclinicListForm.insuranceProviderId,
      isSeenDoctor: props.fromclinicListForm.isSeenDoctor,
      longitude: props.fromclinicListForm.longitude,
      latitude: props.fromclinicListForm.latitude,
      currentTime: moment(new Date()).format("HH"),
    };
  } else {
    //initial Form State
    initialFormData = {
      typeofTherapyId: 0,
      city: "",
      zip: "",
      appointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      insuranceProviderId: 0,
      isSeenDoctor: true,
      longitude: 0,
      latitude: 0,
      currentTime: moment(new Date()).format("HH"),
    };
  }

  const { handleSubmit } = useForm();
  const [formData, updateFormData] = React.useState(initialFormData);
  const [therapyTypes, setTherapyTypes] = useState();
  const [insuranceTypes, setInsuranceTypes] = useState();
  // const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = React.useState("");
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [detectLocationLoader, setDetectLoctionLoader] = useState(false);
  const [url, setUrl] = useState();
  const [onSubmitFlag, setOnSubmitFlag] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [handleSelectTriggered, setHandleSelectTriggered] = useState(false);
  const { Option } = Select;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;
  const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = bindActionCreators(actionCreators, dispatch);

  setTimeout(() => {
    var insurance = document.getElementById("insurance-provider-select");
    insurance.maxLength = "30";
    insurance.setAttribute("onkeydown", "return /[a-zA-Z ]/i.test(event.key)");

    var therapy = document.getElementById("therapy-type-select");
    therapy.maxLength = "30";
    therapy.setAttribute("onkeydown", "return /[a-zA-Z ]/i.test(event.key)");
  }, 350);

  useEffect(() => {
    if (address === "") {
      setHandleSelectTriggered(false);
    }
  }, [address]);

  useEffect(() => {
    if (props.fromClinicList) {
      updateFormData(initialFormData);
    }
  }, []);

  const handleSelect = async (value) => {
    setHandleSelectTriggered(true);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setAddress(value);

    try {
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&sensor=true&key=${REACT_APP_GOOGLE_API_KEY}`
      );

      clearTimeout(id);

      const location = await response.json();

      //populating formData with selected City
      let addressComponents = [];
      location &&
        location.results.map((item) => {
          addressComponents.push(item.address_components.length);
        });

      const index = addressComponents.indexOf(Math.max(...addressComponents));
      const maxValueInArray = Math.max(...addressComponents);

      location &&
        location.results.map((item) => {
          if (item.address_components.length === maxValueInArray) {
            item.address_components.map((item2) => {
              if (
                item2.types.length === 1 &&
                item2.types[0] === "postal_code"
              ) {
                formData.zip = item2.long_name;
              }
            });
          }
        });

      location &&
        location.results[index].address_components.map((item) => {
          if (
            item.types.length === 2 &&
            item.types[0] === "locality" &&
            item.types[1] === "political"
          ) {
            formData.city = item.long_name;
          }

          if (
            item.types.length === 2 &&
            item.types[0] === "administrative_area_level_1" &&
            item.types[1] === "political"
          ) {
            formData.state = item.long_name;
          }
        });

      formData.latitude = latLng.lat;
      formData.longitude = latLng.lng;
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  //Check for city Value if exist then display to clinic-list
  useEffect(() => {
    if (props.fromClinicList) {
      setAddress(props.fromclinicListForm.city);
    }
  }, [formData]);

  //Fetch Therapy and Insurance
  useEffect(() => {
    getTherapy();
    getInsurance();
  }, []);

  //get location based on zip code
  const getLocationBasedOnZipCode = async (zipCode) => {
    setHandleSelectTriggered(true);

    try {
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${REACT_APP_GOOGLE_API_KEY}`
      );
      clearTimeout(id);

      const location = await response.json();

      formData.city = location.results[0].formatted_address;
      formData.address = location.results[0].formatted_address;
      formData.zip = zipCode;
      formData.latitude = location.results[0].geometry.location.lat;
      formData.longitude = location.results[0].geometry.location.lng;
      setAddress(location.results[0].formatted_address);

      setDetectLoctionLoader(false);
    } catch (error) {
      setApiErrorMessage(true);
      setDetectLoctionLoader(false);
    }
  };

  //get current location if location enabled
  const getCurrentLocation = async () => {
    setHandleSelectTriggered(true);
    var isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (isChrome) {
      const permissionStatus = await navigator?.permissions.query({
        name: "geolocation",
      });

      if (permissionStatus.state === "denied") {
        alert("You need to allow location  to use this feature!");
      } else if (permissionStatus.state === "granted") {
        setDetectLoctionLoader(true);

        try {
          const controller = new AbortController();
          const id = setTimeout(
            () => controller.abort(),
            process.env.REACT_APP_API_TIMEOUT
          );
          const response = await fetch(url);
          clearTimeout(id);

          const location = await response.json();

          let addressComponents = [];
          location &&
            location.results.map((item) => {
              addressComponents.push(item.address_components.length);
            });

          //gett max length index because it contains all information
          const index = addressComponents.indexOf(
            Math.max(...addressComponents)
          );
          const maxValueInArray = Math.max(...addressComponents);

          location &&
            location.results.map((item) => {
              if (item.address_components.length === maxValueInArray) {
                item.address_components.map((item2) => {
                  if (
                    item2.types.length === 1 &&
                    item2.types[0] === "postal_code"
                  ) {
                    formData.zip = item2.long_name;
                  }
                });
              }
            });

          location &&
            location.results[index].address_components.map((item) => {
              if (
                item.types.length === 2 &&
                item.types[0] === "administrative_area_level_1" &&
                item.types[1] === "political"
              ) {
                formData.state = item.long_name;
              }
            });

          //populating formData with latlng, city,zip
          formData.city = "My current location";
          formData.latitude = latitude;
          formData.longitude = longitude;

          setAddress("My Current Location");
          setDetectLoctionLoader(false);
        } catch (error) {
          setApiErrorMessage(true);
          setDetectLoctionLoader(false);
        }
      }
    } else {
      try {
        const controller = new AbortController();
        const id = setTimeout(
          () => controller.abort(),
          process.env.REACT_APP_API_TIMEOUT
        );
        const response = await fetch(url);
        clearTimeout(id);
        const location = await response.json();

        let addressComponents = [];
        location &&
          location.results.map((item) => {
            addressComponents.push(item.address_components.length);
          });

        const index = addressComponents.indexOf(Math.max(...addressComponents));
        const maxValueInArray = Math.max(...addressComponents);

        location &&
          location.results.map((item) => {
            if (item.address_components.length === maxValueInArray) {
              item.address_components.map((item2) => {
                if (
                  item2.types.length === 1 &&
                  item2.types[0] === "postal_code"
                ) {
                  formData.zip = item2.long_name;
                }
              });
            }
          });

        location &&
          location.results[index].address_components.map((item) => {
            if (
              item.types.length === 2 &&
              item.types[0] === "administrative_area_level_1" &&
              item.types[1] === "political"
            ) {
              formData.state = item.long_name;
            }
          });

        //populating formData with latlng, city,zip
        formData.city = "My current location";
        formData.latitude = latitude;
        formData.longitude = longitude;

        setAddress("My Current Location");
        setDetectLoctionLoader(false);
      } catch (error) {
        setApiErrorMessage(true);
        setDetectLoctionLoader(false);
      }
    }
  };

  //Detect Location on pageLoad
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
      setUrl(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=true&key=${REACT_APP_GOOGLE_API_KEY}`
      );
    });
  }, [url]);

  //reverse geocoding to get location name
  useEffect(() => {
    let zipCode = "";
    var url = new URL(window.location.href);
    zipCode = url.searchParams.get("id");

    if (zipCode !== "" && window.location.href.includes("id")) {
      getLocationBasedOnZipCode(zipCode);
    } else if (latitude && longitude) {
      if (!props.fromClinicList) {
        getCurrentLocation();
      }
    }
  }, [url]);

  //Fetch Therapy
  const getTherapy = async () => {
    try {
      // timeout 45 seconds
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Therapy/GetTypeOfTherapy`
      );
      clearTimeout(id);
      const therapyList = await response.json();
      setTherapyTypes(therapyList.data);
      actions.therapyTypes(therapyList.data);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  //Fetch Insurance
  const getInsurance = async () => {
    try {
      // timeout 45 seconds
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}InsuranceProvider/GetInsuranceProviders`
      );
      clearTimeout(id);

      const insuranceTypes = await response.json();
      setInsuranceTypes(insuranceTypes.data);
      actions.insuranceTypes(insuranceTypes.data);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  //navigate to appointment-submitted page
  const navigateToForm = async () => {
    navigate("/book-appointment");
  };

  //clear location function
  const clearLocation = () => {
    setAddress("");
    formData.latitude = 0;
    formData.longitude = 0;
    formData.city = "";
    formData.state = "";
    formData.zip = "";
  };

  //Select fields handle change
  const handleChange = (name, event) => {
    updateFormData({
      ...formData,
      [name]: event,
    });
  };

  // Country Restrictions
  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: "us" },
  };

  //Search Clinic submit button
  const onSubmit = async (data) => {
    if (
      address === "" ||
      formData.city === "" ||
      formData.typeofTherapyId === 0 ||
      formData.insuranceProviderId === 0
    ) {
      setOnSubmitFlag(true);
    } else {
      try {
        setLoading(true);

        //timeout 45-seconds
        const controller = new AbortController();
        const id = setTimeout(
          () => controller.abort(),
          process.env.REACT_APP_API_TIMEOUT
        );

        // if (props.fromClinicList) {
        //   const appointmentDatesFromLS = new Date(
        //     JSON.parse(localStorage.getItem("appointmentDates"))[0].date.slice(
        //       1,
        //       10
        //     )
        //   );

        //   let newDate = appointmentDatesFromLS.setDate(
        //     appointmentDatesFromLS.getDate() - 1
        //   );
        //   formData.appointmentDate = new Date(newDate).toLocaleDateString(
        //     "fr-CA"
        //   );

        //   formData.currentTime = moment(
        //     Date(
        //       JSON.parse(
        //         localStorage.getItem("appointmentDates")
        //       )[0].date.slice(1, 10)
        //     )
        //   ).format("HH");
        // }

        if (props.fromClinicList) {
          formData["appointmentDate"] = moment(new Date()).format("YYYY-MM-DD");
          formData["currentTime"] = moment(new Date()).format("HH");
        }

        const response = await fetch(
          `${REACT_APP_CORA_API_URL}Clinic/SearchClinic`,
          {
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            method: "POST",
            signal: controller.signal,
          }
        );
        clearTimeout(id);

        const clinicListData = await response.json();

        if (!Object.keys(clinicListData).includes("errors")) {
          setOnSubmitFlag(false);
          actions.searchClinics(formData);

          // actions.setAppointmentDates(clinicListData.data.appointmentDates);
          localStorage.clear();

          localStorage.setItem(
            "Data",
            JSON.stringify({
              appointmentDates: clinicListData.data.appointmentDates,
              clinicData: clinicListData.data.clinics,
            })
          );
          setLoading(false);

          //Close search modal when search complete
          if (props?.cancelHandler) {
            props.cancelHandler();
          }

          navigate("/clinic-list", {
            state: {
              formData: formData,
              setDateToDefault: true,
              // clinicData: clinicListData.data.clinics,
              // appointmentDates: clinicListData.data.appointmentDates,
            },
          });

          if (
            props.fromClinicList &&
            clinicListData.data.appointmentDates.length > 0
          ) {
            let slickData =
              document.getElementsByClassName("slick-track")[0].children;

            var width = document
              .getElementsByClassName("slick-track")[0]
              .getAttribute("style")
              .split("width:")[1]
              .slice(1, 7);

            document
              .getElementsByClassName("slick-track")[0]
              .setAttribute(
                "style",
                `width: ${width}; opacity: 1; transform: translate3d(0px, 0px, 0px);`
              );

            for (let index = 0; index < slickData.length; index++) {
              if (index === 0) {
                slickData[index].setAttribute(
                  "class",
                  "slick-slide slick-active slick-current"
                );
                slickData[index].setAttribute("aria-hidden", "false");
              } else if (index === 1 || index === 2) {
                slickData[index].setAttribute(
                  "class",
                  "slick-slide slick-active"
                );
                slickData[index].setAttribute("aria-hidden", "false");
              } else {
                slickData[index].setAttribute("class", "slick-slide");
                slickData[index].setAttribute("aria-hidden", "true");
              }
            }

            // document
            //   .getElementsByClassName("slick-track")[0]
            //   .children.map((item, index) => {});

            document.getElementsByClassName(
              "slick-arrow slick-prev"
            )[0].className = "slick-arrow slick-prev slick-disabled";
          }
        } else {
          throw Error("error");
        }
      } catch (error) {
        setLoading(false);
        setApiErrorMessage(true);
      }
    }
  };

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//cdn.rlets.com/capture_configs/165/e1e/c5f/44b489ca502db19b9b0e144.js"
          async="async"
        ></script>
      </Helmet>

      <div className="filters" data-testid="step-1">
        <script
          type="text/javascript"
          src="//cdn.rlets.com/capture_configs/165/e1e/c5f/44b489ca502db19b9b0e144.js"
          async="async"
        ></script>
        <Modal
          visible={apiErrorMessage}
          className="exception-modal"
          onCancel={() => setApiErrorMessage(false)}
          onOk={() => setApiErrorMessage(false)}
          footer={null}
          centered
        >
          <div className="modal-content">
            <img
              className="error-img"
              src={errorImage}
              alt="Something went wrong."
            />
            <span className="exception-modal-text">
              Something went wrong. Please try again later.
            </span>
          </div>
        </Modal>
        <div>
          <span className="info-text">
            New patient? SCHEDULE AN APPOINTMENT NOW.
          </span>
          {!props.fromClinicList && (
            <span>
              If you are a current CORA patient, please call your clinic to
              schedule your complete plan of care.
            </span>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-card">
            <div className="my-3 tyerapy-type-select">
              <FloatLabel
                label="Type of Therapy"
                value={
                  formData.typeofTherapyId !== 0
                    ? formData.typeofTherapyId
                    : null
                }
              >
                {
                  <div className="prefix-icon-wrapper">
                    <img alt="" src={searchIcon} />
                  </div>
                }
                <Select
                  size={"large"}
                  showSearch
                  optionFilterProp="children"
                  defaultValue={
                    props.fromClinicList
                      ? props.therapyName !== null
                        ? props.therapyName
                        : formData.typeofTherapyId !== 0
                        ? formData.typeofTherapyId
                        : null
                      : null
                  }
                  id="therapy-type-select"
                  name="typeofTherapyId"
                  onChange={(e) => handleChange("typeofTherapyId", e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  maxLength={6}
                >
                  {therapyTypes &&
                    therapyTypes?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </FloatLabel>
              {formData.typeofTherapyId === 0 && onSubmitFlag && (
                <span className="error" style={{ color: "red" }}>
                  Type of therapy is required
                </span>
              )}
            </div>
            <div className="my-3 position-relative location-select">
              <FloatLabel label="City, State or Zip Code" value={address}>
                <PlacesAutocomplete
                  searchOptions={searchOptions}
                  value={address}
                  defaultValue={
                    props.fromClinicList && clinicsSearchFields["city"]
                      ? clinicsSearchFields["city"]
                      : address
                  }
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        className="loc-input"
                        id="location-input"
                        {...getInputProps()}
                        maxLength={35}
                      ></input>

                      {detectLocationLoader ? (
                        <Spin
                          size="medium"
                          className="detect-location-loader"
                        />
                      ) : (
                        <>
                          {address !== "" ? (
                            <span className="floating-label">
                              City, State or Zip Code
                            </span>
                          ) : null}
                        </>
                      )}
                      <span
                        title="Detect Current Location"
                        className="loc-icon-span"
                        onClick={getCurrentLocation}
                      ></span>
                      {address === "" && onSubmitFlag && (
                        <span className="error" style={{ color: "red" }}>
                          City, State or Zip Code is required
                        </span>
                      )}

                      {!handleSelectTriggered &&
                        onSubmitFlag &&
                        address.length !== 0 && (
                          <span className="error" style={{ color: "red" }}>
                            Select City, State or Zip from drop down
                          </span>
                        )}

                      <div className="autocomplete-dropdown-container">
                        {loading ? (
                          <Spin
                            className="m-auto plcs-auto-complete-spinner"
                            indicator={antIcon}
                          />
                        ) : null}

                        {suggestions.map((suggestion, index) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#41b6e6"
                              : "#fff",
                            borderTop: "1px solid #00504633",
                            paddingTop: "1px",
                            paddingBottom: "1px",
                          };

                          return (
                            <div
                              key={index}
                              className="suggestions"
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              <img
                                src={clinicIcon}
                                className="suggestions-location-icon"
                                alt="clinic-icon"
                              />
                              {suggestion.description.slice(0, 60)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </FloatLabel>

              {address ? (
                <CloseCircleOutlined
                  className="close-circle"
                  onClick={() => clearLocation()}
                />
              ) : null}
            </div>
            <div className="my-3 insurance-provider-select">
              <FloatLabel
                label="Insurance Provider"
                value={
                  formData.insuranceProviderId !== 0
                    ? formData.insuranceProviderId
                    : null
                }
              >
                {
                  <div className="prefix-icon-wrapper">
                    <img alt="" src={calculatorIcon} />
                  </div>
                }
                <Select
                  size={"large"}
                  showSearch
                  showArrow
                  optionFilterProp="children"
                  defaultValue={
                    props.fromClinicList
                      ? props.insuranceName !== null
                        ? props.insuranceName
                        : formData.insuranceProviderId !== 0
                        ? formData.insuranceProviderId
                        : null
                      : null
                  }
                  name="insuranceProviderId"
                  id="insurance-provider-select"
                  onChange={(e) => handleChange("insuranceProviderId", e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {insuranceTypes &&
                    insuranceTypes.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </FloatLabel>
              {formData.insuranceProviderId === 0 && onSubmitFlag && (
                <span className="error" style={{ color: "red" }}>
                  Insurance provider is required
                </span>
              )}
            </div>
            {props.fromClinicList ? null : (
              <div className="my-3">
                <Switch
                  defaultChecked
                  name="isSeenDoctor"
                  onChange={(e) => handleChange("isSeenDoctor", e)}
                  className="mr-1"
                  id="seen-doc-checkbox"
                />{" "}
                <span className="checkbox-text">Have you seen a doctor?</span>
              </div>
            )}

            <div className="apply-btn">
              <Button
                type="submit"
                id="search-clinic-button"
                className="my-3"
                disabled={loading}
              >
                {loading ? (
                  <Spin
                    className="mr-3 search-clinic-btn-loader"
                    indicator={antIcon}
                  />
                ) : (
                  <img
                    alt=""
                    className="magnifyling-glass-icon mr-3"
                    src={magnifyingGlassIcon}
                  />
                )}{" "}
                {props.fromClinicList ? (
                  <span>Search</span>
                ) : (
                  <span>Search Clinic Now</span>
                )}
              </Button>
            </div>
            {props.fromClinicList ? null : (
              <>
                <div className="divider mt-3 mb-4">
                  <div className="divider-line"></div>
                  <span>OR</span>
                </div>
                <span className="comp-claim-text my-3">
                  In case injury is due to an auto accident or a workers'
                  compensation claim,
                </span>
                <Button
                  id="connect-with-cora-team-btn"
                  className="connect-cora-team"
                  onClick={navigateToForm}
                >
                  Connect With CORA Team
                </Button>
              </>
            )}

            {props.fromClinicList ? null : (
              <>
                <div className="divider mt-3 mb-4">
                  <div className="divider-line"></div>
                  <span>OR</span>
                </div>

                <Button
                  id="connect-with-cora-team-btn"
                  rel="noreferrer"
                  className="connect-cora-team"
                  onClick={() => {
                    window.open(
                      "https://www.coraphysicaltherapy.com/book-telehealth-appointment/"
                    );
                  }}
                >
                  Book Telehealth Appointment
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default SearchClinicComponent;
