import React, { useState } from "react";

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, formData, onSubmitFlag } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";
  const zipErrorData =
    formData?.zip === null && onSubmitFlag ? "zip-error-data" : "";

  return (
    <div
      className="float-label "
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass + " " + zipErrorData}>{label}</label>
    </div>
  );
};

export default FloatLabel;
