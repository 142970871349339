import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from './headerPhase2';
import { Carousel } from 'antd';
import { Button, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import submitIcon from './../assets/img/submit-icon.png';
import { resources } from '../resource';
import { useLocation, useNavigate } from 'react-router-dom';

function CheckInScreening(props) {
  const patientForm = useSelector((state) => state.patientForm);
  const [question1Answer, setQuestion1Answer] = useState(0);
  const [question2Answer, setQuestion2Answer] = useState(0);
  const clinicUrl = useSelector((state) => state.auth.clinicUrl);

  const _resources = resources[props.language];
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state == null) {
      if (clinicUrl && clinicUrl != '') {
        window.location.replace(clinicUrl);
      }
    }
    window.history.replaceState(null, '');
  }, []);

  const handleQ1Change = (e) => {
    setQuestion1Answer(parseInt(e.target.value));
  };

  const handleQ2Change = (e) => {
    setQuestion2Answer(parseInt(e.target.value));
  };

  const continueCheckIn = () => {
    if (patientForm.vobNeeded) {
      navigate(`/assignmentOfBenefit`);
    } else {
      navigate(`/patient-checkin/signature`, { state: { fromFlow: true } });
    }
  };

  return (
    <>
      <Header />
      <Helmet>
        <title>CORA Health | Screening</title>
      </Helmet>
      <div className="checkin-page-carousel d-flex justify-content-between ">
        <div className="col-lg-7 check-in-screening-submit-container">
          <img src={submitIcon} alt="Submit Icon" />
          <p className="screen_question">
            {_resources.CheckInScreening.QUESTION_1}
          </p>
          <p className="screen_detail">
            {_resources.CheckInScreening.QUESTION_1_DETAILS}
          </p>

          <div className="row radio-group">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="question1group"
            >
              <FormControlLabel
                onChange={handleQ1Change}
                value={1}
                control={
                  <Radio
                    className="radiobutton"
                    checked={
                      question1Answer === null
                        ? false
                        : question1Answer === 1
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.CheckInScreening.YES}
              />
              <FormControlLabel
                onChange={handleQ1Change}
                value={2}
                control={
                  <Radio
                    className="radiobutton"
                    checked={
                      question1Answer === null
                        ? false
                        : question1Answer === 2
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.CheckInScreening.NO}
              />
            </RadioGroup>
          </div>
          <p className="screen_question">
            {_resources.CheckInScreening.QUESTION_2}
          </p>
          <div className="row radio-group">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="question2group"
            >
              <FormControlLabel
                onChange={handleQ2Change}
                value={1}
                control={
                  <Radio
                    className="radiobutton"
                    checked={
                      question2Answer === null
                        ? false
                        : question2Answer === 1
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.CheckInScreening.YES}
              />
              <FormControlLabel
                onChange={handleQ2Change}
                value={2}
                control={
                  <Radio
                    className="radiobutton"
                    checked={
                      question2Answer === null
                        ? false
                        : question2Answer === 2
                        ? true
                        : false
                    }
                  />
                }
                label={_resources.CheckInScreening.NO}
              />
            </RadioGroup>
          </div>
          <p className="screen_question">
            {_resources.CheckInScreening.CLOSE_CONTACT_HEADER}
          </p>
          <ol className="screen_detail">
            <li>{_resources.CheckInScreening.CLOSE_CONTACT_1}</li>
            <li>{_resources.CheckInScreening.CLOSE_CONTACT_2}</li>
            <li>{_resources.CheckInScreening.CLOSE_CONTACT_3}</li>
            <li>{_resources.CheckInScreening.CLOSE_CONTACT_4}</li>
            <li>{_resources.CheckInScreening.CLOSE_CONTACT_5}</li>
          </ol>

          <div className="check-in-screening-proceed-container">
            {question1Answer === 1 || question2Answer === 1 ? (
              <p className="check-in-screening-proceed">
                {_resources.CheckInScreening.PROCEED_TO_FRONT_DESK}
              </p>
            ) : question1Answer === 2 && question2Answer === 2 ? (
              <Button
                type="submit"
                id="search-clinic-button"
                onClick={continueCheckIn}
              >
                {_resources.CheckInScreening.CONTINUE_CHECK_IN}
              </Button>
            ) : (
              <p className="proceed">
                <br />
              </p>
            )}
          </div>
        </div>
        <div className="col-lg-5 p-0 carousel-column">
          <Carousel>
            <div>
              <div className="clinic-pic-one"></div>
            </div>
            <div>
              <div className="clinic-pic-two"></div>
            </div>
            <div>
              <div className="clinic-pic-three"></div>
            </div>
            <div>
              <div className="clinic-pic-four"></div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}
export default CheckInScreening;
