/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from './headerPhase2';
import { Carousel } from 'antd';
import noSlotImg from './../assets/img/no-slot-icon.svg';
import submitIcon from './../assets/img/submit-icon.png';
import homeIcon from './../assets/img/home-icon.png';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import errorImage from './../assets/img/error-image.svg';
import phoneIcon from './../assets/img/phone-icon.svg';
import { resources } from '../resource';
import { useNavigate } from 'react-router-dom';

function Submission(props) {
  const location = useLocation();
  const navigate = useNavigate();

  let stateFromCheckin = location.state;
  const patientForm = useSelector((state) => state.patientForm);
  const clinicUrl = useSelector((state) => state.auth.clinicUrl);
  const multipleUploads = useSelector((state) => state.multipleUploadDocument);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [waystarLink, setWaystarLink] = useState(location.state.waystarLink);
  const _resources = resources[props.language];

  useEffect(() => {
    if (multipleUploads.imageList.length > 0) {
      uploadDocuments();
    }
  }, [patientForm, multipleUploads]);

  const uploadDocuments = async () => {
    try {
      const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

      multipleUploads.imageList &&
        // eslint-disable-next-line array-callback-return
        multipleUploads.imageList.map((item) => {
          item['companyId'] =
            location && location.state.submitPatientRespone.companyId;
          item['patientId'] =
            location && location.state.submitPatientRespone.patientId;
        });

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/UploadDocument`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(multipleUploads.imageList),
        }
      );
      clearTimeout(id);

      await response.json();
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  return (
    <>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            {_resources.Checkin.ERROR}
          </span>
        </div>
      </Modal>
      <Header />
      <Helmet>
        <title>CORA Health | Appointment Submitted</title>
      </Helmet>
      <div className="checkin-page-carousel d-flex justify-content-between ">
        {stateFromCheckin === null ? (
          <div className="col-lg-7 submit-container">
            <img src={noSlotImg} alt="Submit Icon" />
            <p className="information text-center">
              {_resources.Submission.ISSUE_IN_CLINIC}
              <br />
              {_resources.Submission.FOR_MORE_INFO}
              <br />
              <br />
              <img src={phoneIcon} alt="Near By" className="mx-2" />{' '}
              <strong>(866) 443-2672</strong>
            </p>
            <div className="proceed-container">
              {/* {
               waystarLink && ( <p className="pt-3">{_resources.Submission.OR}</p> ) 
            }
              {
                waystarLink 
                ?
                <Button
                  type="submit"
                  className="mt-4 way-link"
                  href={waystarLink}
                >
                  {_resources.Submission.PAYMENT}
                </Button>
                :
                ""
              } */}
              <p className="proceed">
                {' '}
                {_resources.Submission.PROCEED_TO_FRONT_DESK}
              </p>
            </div>
          </div>
        ) : (
          <div className="col-lg-7 submit-container">
            <img src={submitIcon} alt="Submit Icon" />
            <p className="information">
              {' '}
              {_resources.Submission.INFO_HAS_BEEN_SUBMITTED}
            </p>

            <div className="proceed-container">
              {patientForm &&
              patientForm.validationResponse.visitType === 'Eval' &&
              !patientForm.validationResponse.preRegistrationComplete ? (
                <p className="proceed">
                  {' '}
                  {/* {
                    waystarLink 
                    ?
                    <>
                      {_resources.Submission.YOU_ARE_ALL_SET_WAYSTAR}
                    </>
                    :
                    } */}
                  <>{_resources.Submission.YOU_ARE_ALL_SET}</>
                </p>
              ) : location.state.newPatient &&
                location.state.newPatient == true ? (
                <p className="proceed">
                  {_resources.Submission.WE_WILL_BE_RIGHT_TO_YOU}
                </p>
              ) : (
                <p className="proceed">
                  {/* {
                    waystarLink 
                    ?
                    <>
                      {_resources.Submission.YOU_ARE_ALL_SET_WAYSTAR}
                    </>
                    :
                    } */}
                  <>{_resources.Submission.YOU_ARE_ALL_SET}</>
                </p>
              )}
            </div>
            {/* {
               waystarLink && ( <p className="pt-3">{_resources.Submission.OR}</p> ) 
            }
            {
              waystarLink 
              ?
              <Button
                type="submit"
                className="mt-4 way-link"
                href={waystarLink}
              >
                {_resources.Submission.PAYMENT}
              </Button>
              :
              ""
            } */}
            {clinicUrl && clinicUrl != '' ? (
              <span
                className="back-home cursor-pointer mt-3 d-flex align-items-center"
                onClick={() => {
                  window.history.replaceState({}, '');
                  window.location.replace(clinicUrl);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-log-out mr-2"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1={21} x2={9} y1={12} y2={12} />
                </svg>
                {_resources.Submission.LOGOUT}
              </span>
            ) : null}
            {clinicUrl && clinicUrl != '' ? null : (
              <a className="back-home" href={'https://coraphysicaltherapy.com'}>
                <img
                  src={homeIcon}
                  alt="Home Icon"
                  onClick={() =>
                    window.location.replace('https://coraphysicaltherapy.com')
                  }
                />
                <p>{_resources.Submission.BACK_TO_HOME} </p>
              </a>
            )}
          </div>
        )}
        <div className="col-lg-5 p-0 carousel-column">
          <Carousel>
            <div>
              <div className="clinic-pic-one"></div>
            </div>
            <div>
              <div className="clinic-pic-two"></div>
            </div>
            <div>
              <div className="clinic-pic-three"></div>
            </div>
            <div>
              <div className="clinic-pic-four"></div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}
export default Submission;
