/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Header from './headerPhase2';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import backArrow from './../assets/img/back-arrow.svg';
import redoBtn from './../assets/img/redo-btn.svg';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spin } from 'antd';
import errorImage from './../assets/img/error-image.svg';
import { LoadingOutlined } from '@ant-design/icons';
import Checkbox from '@mui/material/Checkbox';
import { resources } from '../resource';

import moment from 'moment';

function Signature(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  let signatureRef = React.useRef(null);
  const actions = bindActionCreators(actionCreators, dispatch);
  const [updatePatientSpinner, setUpdatePatientSpinner] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [canvasEmpty, setCanvasEmpty] = useState(true);
  const [hasSignature, setHasSignature] = useState(false);
  const [receiptAcknowledgement, setReceiptAcknowledgement] = useState(false);
  const [treatmentAcknowledgement, setTreatmentAcknowledgement] =
    useState(false);
  const [actionAcknowledgement, setActionAcknowledgement] = useState(false);
  const [financialAcknowledgement, setFinancialAcknowledgement] =
    useState(false);
  const _resources = resources[props.language];

  const navigate = useNavigate();
  let canvasRef;

  const initialFormData = Object.freeze({
    AnythingChangeInHealthYourLastAppointment: null,
    InsuranceChangeSinceLastAppointment: null,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const patientForm = useSelector((state) => state.patientForm);
  const clinicUrl = useSelector((state) => state.auth.clinicUrl);

  const { companyId, patientId } = patientForm.patientForm;
  const [validationErrors, setValidationErrors] = useState([]);

  const [formData, updateFormData] = React.useState(initialFormData);


  const {
    firstName,
    lastName,
    preferredName,
    heightFt,
    heightIn,
    weight,
    address1,
    address2,
    city,
    state,
    zip,
    phone,
    dob,
    email,
    hearAbout,
    referredByName,
    referredByPhone,
    primaryCarePhysicianFirstName,
    primaryCarePhysicianLastName,
    primaryCarePhysicianPhone,
    emergencyContactFirstName,
    emergencyContactLastName,
    emergencyContactRelationship,
    emergencyContactPhone,
    hasMedicalInsurance,
    didYouHaveTheIncident,
    employerName,
    employerPhone,
    employerAddress1,
    employerAddress2,
    employerCity,
    employerState,
    employerZip,
    job,
    jobStatus,
    autoInsuranceCompany,
    AutoInsuranceCompanyPhoneNumber,
    autoAttorneyFirstName,
    autoAttorneyLastName,
    autoHealthInsurancePhone,
    autoHealthInsuranceCompany,
    autoAttorneyPhone,
    autoAccidentPhone,
    autoAccidentPrimaryInsuredName,
    autoIDNumber,
    isAutoInjury,
    isWorkInjury,
    letterOfExhaustionFromYourAutoCarrier,
  } = patientForm.patientForm;

  const { extension, imageUrl } = patientForm.letterOfExhaustionCopy;
  const [ip, setIP] = useState('');

  const {
    medicalIllnessesHeartProblems,
    medicalIllnessesPregnant,
    medicalIllnessesCancer,
    medicalIllnessesPaceMakers,
    medicalIllnessesOsteoporosis,
    medicalIllnessesDiabetes,
    medicalIllnessesAsthma,
    medicalIllnessesStroke,
    medicalIllnessesHighBloodPressure,
    medicalIllnessesSmoking,
    medicalIllnessesSeizures,
    medicalIllnessesHivAids,
    medications,
    doHaveAnyAllergy,
    allergies,
    chiefPhysicalComplaint,
    howItHappened,
    surgeryInjuryLast5,
    // previousTherapy: havePreviousTherapyForTheProblem,
    havePreviousTherapyForTheProblem,
    wasPreviousTherapyHelpful,
  } = patientForm.medicalForm;

  const {
    physicalOccupationalSpeechThreapy,
    woundCare,
    injectionsMedications,
    bathingPersonalCare,
    ivCare,
    anyServicesNotListedAbove,
    homeHealthRepresentativeNursAide,
  } = patientForm.medicareForm;

  var url, clinicId;
  if (patientForm.clinicName.includes('/patient-checkin/checkin?clinicId=')) {
    if (patientForm.clinicName !== '') {
      url = new URL(patientForm.clinicName);
      clinicId = url.searchParams.get('clinicId').replaceAll('%20', ' ');
    }
  } else {
    clinicId = patientForm.clinicName.replaceAll('%20', ' ');
  }

  // const getData = async () => {

  // }

  const location = useLocation();

  useEffect(() => {
    //passing getData method to the lifecycle method
    // getData()
    if (location.state == null) {
      window.location.replace(clinicUrl);
    }
    window.history.replaceState(null, '');
  }, []);

  function customSerialize(obj) {
    const seen = new WeakSet();
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    });
  }

  const handleClick = async () => {
    // let encodedSignature = await toBase64(canvasRef._sigPad);
    let customizedObj = customSerialize(canvasRef);

    actions.saveSignature(customizedObj);
    try {
      if (patientForm.updateEditButtonForNewPatient) {
        submitForNewPatient();
      } else if (patientForm.updateEditButtonForExistingPatient) {
        submitForExistingPatient();
      }
    } catch (error) {
      setApiErrorMessage(true);
      setUpdatePatientSpinner(false);
    }
  };

  const redoButtonClear = () => {
    canvasRef.clear();
    setHasSignature(false);
    setCanvasEmpty(true);
  };

  const submitForNewPatient = async () => {
    try {
      const responseIP = await fetch('https://api.ipify.org/?format=json');
      const ipAddress = await responseIP.json();
      const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );

      setUpdatePatientSpinner(true);
      let bodyArry = {
        // patientId: patientForm.patientForm.patientId,

        patientId: Object.keys(patientForm.patientForm).includes('patientId')
          ? patientForm.patientForm.patientId
          : patientForm.newPatientValidation.patientId,
        companyId: Object.keys(patientForm.patientForm).includes('companyId')
          ? patientForm.patientForm.companyId
          : patientForm.newPatientValidation.companyId,
        appointmentId:
          Object.keys(patientForm.validationResponse).length !== 0
            ? patientForm.validationResponse.appointmentId
            : null,
        firstName: firstName,
        lastName: lastName,
        preferredName: preferredName,
        heightFt: heightFt === null ? 0 : heightFt,
        heightIn: heightIn === null ? 0 : heightIn,
        weight: weight === null ? 0 : weight,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        mobileAreaCode: Object.keys(patientForm.patientForm).includes('phone')
          ? patientForm.patientForm.phone.slice(1, 4)
          : patientForm.patientForm.mobilePhone.slice(1, 4),
        //              : patientForm.patientForm.mobilePhone.slice(0, 3),
        mobilePhone: Object.keys(patientForm.patientForm).includes('phone')
          ? patientForm.patientForm.phone.slice(6, 15).replaceAll('-', '')
          : patientForm.patientForm.mobilePhone
              .slice(6, 15)
              .replaceAll('-', ''),
        //              : patientForm.patientForm.mobilePhone.slice(3, 10),
        dob: moment(dob).format('YYYY-MM-DD'),
        gender: patientForm.patientForm.gender,
        email: patientForm.patientForm.email2
          ? patientForm.patientForm.email2
          : email,
        hearAbout: hearAbout,
        referredByName: referredByName,
        referrredByAreaCode: referredByPhone?.slice(1, 4),
        referredByPhone: referredByPhone?.slice(6, 16).replaceAll('-', ''),
        primaryCarePhysicianFirstName: primaryCarePhysicianFirstName,
        primaryCarePhysicianLastName: primaryCarePhysicianLastName,
        primaryCarePhysicianAreaCode:
          primaryCarePhysicianPhone && primaryCarePhysicianPhone?.slice(1, 4),
        primaryCarePhysicianPhone:
          primaryCarePhysicianPhone &&
          primaryCarePhysicianPhone?.slice(6, 16).replaceAll('-', ''),
        emergencyContactFirstName: emergencyContactFirstName,
        emergencyContactLastName: emergencyContactLastName,
        emergencyContactRelationship: emergencyContactRelationship,
        emergencyContactAreaCode:
          emergencyContactPhone && emergencyContactPhone?.slice(1, 4),
        emergencyContactPhone:
          emergencyContactPhone &&
          emergencyContactPhone?.slice(6, 16).replaceAll('-', ''),
        hasMedicalInsurance: hasMedicalInsurance ? true : false,
        isWorkInjury: isWorkInjury === null ? false : isWorkInjury,
        isAutoInjury: isAutoInjury === null ? false : isAutoInjury,
        employerName: employerName,
        employerAreaCode: employerPhone && employerPhone?.slice(1, 4),
        employerPhone:
          employerPhone && employerPhone?.slice(6, 16).replaceAll('-', ''),
        employerAddress1: employerAddress1,
        employerAddress2: employerAddress2,
        employerCity: employerCity,
        employerState: employerState,
        employerZip: employerZip,
        job: job,
        jobStatus: jobStatus,
        autoInsuranceCompany: autoInsuranceCompany,
        autoAttorneyFirstName: autoAttorneyFirstName,
        autoAttorneyLastName: autoAttorneyLastName,
        attorneyAreaCode: autoAttorneyPhone?.slice(1, 4),
        attorneyPhone: autoAttorneyPhone?.slice(6, 16).replaceAll('-', ''),
        autoHealthInsuranceCompany: autoHealthInsuranceCompany,
        AutoInsuranceAreaCode: AutoInsuranceCompanyPhoneNumber
          ? AutoInsuranceCompanyPhoneNumber?.slice(1, 4)
          : '',
        AutoInsurancePhone: AutoInsuranceCompanyPhoneNumber
          ? AutoInsuranceCompanyPhoneNumber?.slice(6, 16).replaceAll('-', '')
          : '',
        autoAttorneyPhone: autoAttorneyPhone,
        autoHealthInsuranceAreaCode: autoHealthInsurancePhone?.slice(1, 4),
        autoHealthInsurancePhone: autoHealthInsurancePhone
          ?.slice(6, 16)
          .replaceAll('-', ''),
        autoAccidentPrimaryInsuredName: autoAccidentPrimaryInsuredName,
        autoIDNumber: autoIDNumber,
        letterOfExhaustionFromYourAutoCarrier:
          letterOfExhaustionFromYourAutoCarrier !== null &&
          letterOfExhaustionFromYourAutoCarrier !== 'NotSure'
            ? letterOfExhaustionFromYourAutoCarrier
            : false,
        letterOfExhaustionFromCopyExtension:
          extension !== null ? extension : null,
        letterOfExhaustionFromCopy: imageUrl !== null ? imageUrl : null,
        medicalIllnessesHeartProblems:
          medicalIllnessesHeartProblems !== null
            ? medicalIllnessesHeartProblems
            : false,
        medicalIllnessesPregnant:
          medicalIllnessesPregnant !== null ? medicalIllnessesPregnant : false,
        medicalIllnessesCancer:
          medicalIllnessesCancer !== null ? medicalIllnessesCancer : false,
        medicalIllnessesPaceMakers:
          medicalIllnessesPaceMakers !== null
            ? medicalIllnessesPaceMakers
            : false,
        medicalIllnessesOsteoporosis:
          medicalIllnessesOsteoporosis !== null
            ? medicalIllnessesOsteoporosis
            : false,
        medicalIllnessesDiabetes:
          medicalIllnessesDiabetes !== null ? medicalIllnessesDiabetes : false,
        medicalIllnessesAsthma:
          medicalIllnessesAsthma !== null ? medicalIllnessesAsthma : false,
        medicalIllnessesStroke:
          medicalIllnessesStroke !== null ? medicalIllnessesStroke : false,
        medicalIllnessesHighBloodPressure:
          medicalIllnessesHighBloodPressure !== null
            ? medicalIllnessesHighBloodPressure
            : false,
        medicalIllnessesSmoking:
          medicalIllnessesSmoking !== null ? medicalIllnessesSmoking : false,
        medicalIllnessesSeizures:
          medicalIllnessesSeizures !== null ? medicalIllnessesSeizures : false,
        medicalIllnessesHivAids:
          medicalIllnessesHivAids !== null ? medicalIllnessesHivAids : false,
        medications: medications,
        doHaveAnAllergy: doHaveAnyAllergy,
        allergies: allergies,
        chiefPhysicalComplaint: chiefPhysicalComplaint,
        howItHappened: howItHappened,
        surgeryInjuryLast5: surgeryInjuryLast5,
        // previousTherapy: havePreviousTherapyForTheProblem,
        havePreviousTherapyForTheProblem:
          havePreviousTherapyForTheProblem === null
            ? false
            : havePreviousTherapyForTheProblem,
        wasPreviousTherapyHelpful:
          wasPreviousTherapyHelpful === null
            ? false
            : wasPreviousTherapyHelpful,

        physicalOccupationalSpeechThreapy:
          physicalOccupationalSpeechThreapy === null
            ? false
            : physicalOccupationalSpeechThreapy,
        woundCare: woundCare === null ? false : woundCare,
        injectionsMedications:
          injectionsMedications === null ? false : injectionsMedications,
        bathingPersonalCare:
          bathingPersonalCare === null ? false : bathingPersonalCare,
        ivCare: ivCare === null ? false : ivCare,
        anyServicesNotListedAbove: false, // correct it (should be string)
        iPAdress: ipAddress.ip,
        homeHealthRepresentativeNursAide:
          homeHealthRepresentativeNursAide === null
            ? false
            : homeHealthRepresentativeNursAide,
        signature: canvasRef?.toDataURL(),
        signatureExtension: 'jpeg',
      };

      for (let i in bodyArry) {
        if (typeof bodyArry[i] === 'string') {
          bodyArry[i] = bodyArry[i].replace(/&/g, 'and');
        }
      }

      const responseAPI = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/SubmitPatient`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(bodyArry),
        }
      );
      clearTimeout(id);

      if (responseAPI.ok) {
        const response = await responseAPI.json();
        setUpdatePatientSpinner(false);

        if (response.responseCode === 200) {
          if (response.submitPatient.status) {
            navigate('/patient-checkin/form-submitted', {
              state: {
                submitPatientRespone: response.submitPatient,
                newPatient: true,
                waystarLink: response.submitPatient.wayStarAPIURl,
              },
            });
          }
        } else {
          setApiErrorMessage(true);
          setUpdatePatientSpinner(false);
        }
      } else {
        setUpdatePatientSpinner(false);
        setApiErrorMessage(true);
        // Handle 400 or 500 response codes
        // Here, you can perform specific actions based on the status code

        if (responseAPI.status === 400) {
          // Handle 400 Bad Request
          const response = await responseAPI.json();
          setValidationErrors(response);
        } else if (responseAPI.status === 500) {
          // Handle 500 Internal Server Error
          const response = await responseAPI.json();
          setValidationErrors(response);
        } else {
          // Handle other response codes if needed
        }
      }
    } catch (error) {
      setApiErrorMessage(true);
      setUpdatePatientSpinner(false);
    }
  };

  const submitForExistingPatient = async () => {
    try {
      const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );

      setUpdatePatientSpinner(true);

      let cellAreaCode, cell;
      // determine cell area code and phone number here
      if (Object.keys(patientForm.patientForm).includes('phone')) {
        if (patientForm.patientForm.phone.length === 10) {
          cellAreaCode = patientForm.patientForm.phone.slice(0, 3);
          cell = patientForm.patientForm.phone.slice(3);
        } else {
          cellAreaCode = patientForm.patientForm.phone.slice(1, 4);
          cell = patientForm.patientForm.phone.slice(6, 15).replaceAll('-', '');
        }
      } else {
        if (patientForm.patientForm.mobilePhone.length === 10) {
          cellAreaCode = patientForm.patientForm.mobilePhone.slice(0, 3);
          cell = patientForm.patientForm.mobilePhone.slice(3);
        } else {
          cellAreaCode = patientForm.patientForm.mobilePhone.slice(1, 4);
          cell = patientForm.patientForm.mobilePhone
            .slice(6, 15)
            .replaceAll('-', '');
        }
      }

      const responseAPI = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/UpdatePatient`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            appointmentId:
              Object.keys(patientForm.validationResponse).length !== 0
                ? patientForm.validationResponse.appointmentId
                : 0,
            companyId: patientForm.patientForm.companyId,
            patientId: patientForm.patientForm.patientId,
            address1: patientForm.patientForm.address1,
            address2:
              patientForm.patientForm.address2 !== null
                ? patientForm.patientForm.address2
                : '',
            city: patientForm.patientForm.city,
            state: patientForm.patientForm.state,
            zip: patientForm.patientForm.zip,
            mobileAreaCode: cellAreaCode,
            mobilePhone: cell,
            email:
              patientForm.patientForm.email !== null
                ? patientForm.patientForm.email
                : '',
            signature: canvasRef.toDataURL(),
            signatureExtension: 'jpeg',
          }),
        }
      );
      clearTimeout(id);

      if (responseAPI.ok) {
      }

      const response = await responseAPI.json();

      setUpdatePatientSpinner(false);
      if (response.responseCode === 200) {
        if (response.updateExistingPatient.status === 1) {
          navigate('/patient-checkin/form-submitted', {
            state: {
              submitPatientRespone: {
                companyId: patientForm && patientForm.patientForm.companyId,
                patientId: patientForm && patientForm.patientForm.patientId,
              },
              newPatient: false,
              waystarLink: response.updateExistingPatient.wayStarAPIURl,
            },
          });
        }
      } else {
        setValidationErrors(response);
        setApiErrorMessage(true);
      }
    } catch (error) {
      setApiErrorMessage(true);
      setUpdatePatientSpinner(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.value === 'true' || e.target.value === 'false') {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value === 'true' ? true : false,
      });
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCheckbox = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  canvasRef = patientForm.signature;

  const isContactPhoneRequired =
    Object.values(validationErrors).length > 0 &&
    validationErrors?.EmergencyContactPhone?.some(
      (errorMessage) =>
        errorMessage === 'The EmergencyContactPhone field is required.'
    );

  const isContactAreaCodeRequired =
    Object.values(validationErrors).length > 0 &&
    validationErrors?.EmergencyContactAreaCode?.some(
      (errorMessage) =>
        errorMessage === 'The EmergencyContactAreaCode field is required.'
    );

  const isContactLastNameRequired =
    Object.values(validationErrors).length > 0 &&
    validationErrors?.EmergencyContactLastName.some(
      (errorMessage) =>
        errorMessage === 'The EmergencyContactLastName field is required.'
    );

  const isContactFirstNameRequired =
    Object.values(validationErrors).length > 0 &&
    validationErrors?.EmergencyContactFirstName?.some(
      (errorMessage) =>
        errorMessage === 'The EmergencyContactFirstName field is required.'
    );

    const goBack = () => {
      if(location.state?.fromReview){
        navigate('/patient-checkin/review-form', { state: { fromFlow: true } });
      } else if(location.state?.fromUpload){
        navigate('/patient-checkin/patient-form', { state: { fromReview: true } });
      } else {
        window.history.back()
      }
    }

  return (
    <>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />

          {Object.keys(validationErrors).length > 0 ? (
            <p className="exception-modal-text">
              {_resources.Signature.FIELD_REQ}
            </p>
          ) : (
            <span className="exception-modal-text">
              {_resources.Checkin.ERROR}
            </span>
          )}
          {/* {Object.keys(validationErrors).map((fieldName) => (
            <div key={fieldName}>
              <h3>{fieldName}</h3>
              <ul>
                {validationErrors[fieldName].map((errorMessage) => (
                  <li key={errorMessage}>{errorMessage}</li>
                ))}
              </ul>
            </div>
          ))} */}
          {isContactPhoneRequired && (
            <p className="mb-0">Contact phone is required.</p>
          )}
          {isContactAreaCodeRequired && (
            <p className="mb-0">Contact area code is required.</p>
          )}
          {isContactLastNameRequired && (
            <p className="mb-0">Contact last name is required.</p>
          )}
          {isContactFirstNameRequired && (
            <p className="mb-0">Contact first name is required.</p>
          )}
        </div>
      </Modal>
      <Header />
      <Helmet>
        <title>Cora Health | Check-In Signature</title>
      </Helmet>

      <div className="confirm-and-book-appointment checkin-page-carousel d-flex justify-content-between">
        <div className="col-lg-7 signature-data">
          <div className="signature-heading">
            <img
              onClick={goBack}
              className="back-arrow"
              src={backArrow}
              alt="date"
            />
            <p className="signature">{_resources.Signature.SIGNATURE}</p>
          </div>
          {/* <div>
              <p className="required-signature">
                Signature is required to submit the form
              </p>
            </div> */}
          <div className="signature-canvas">
            <SignatureCanvas
              ref={(ref) => (canvasRef = ref)}
              onEnd={(e) => {
                if (e['composed']) {
                  setCanvasEmpty(false);
                }
              }}
              onBegin={() => {
                setHasSignature(true);
              }}
              va
              penColor="green"
              // canvasProps={{ width: 300, height: 350, className: "sigCanvas" }}
              canvasProps={{
                // width: 700,
                // height: 350,
                className: 'sigCanvas',
              }}
            />
            {!hasSignature && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {_resources.VerificationOfBenefits.SIGN_HERE}
              </div>
            )}

            <Button className="redo" onClick={redoButtonClear}>
              <img src={redoBtn} alt="date" />
              <span className="ml-2">
                {_resources.VerificationOfBenefits.REDO}
              </span>
            </Button>
          </div>

          <div className="screen">
            {patientForm.updateEditButtonForExistingPatient && (
              <div className="screening-appointment">
                {/* <div className="medical-heading">
                  <p>
                    Has anything changed in your health since your last
                    appointment?
                  </p>
                </div>
                <div className="row">
                   <div className="radio-group ">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="AnythingChangeInHealthYourLastAppointment"
                      id="AnythingChangeInHealthYourLastAppointment"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            checked={
                              formData[
                                "AnythingChangeInHealthYourLastAppointment"
                              ] === null
                                ? false
                                : formData[
                                    "AnythingChangeInHealthYourLastAppointment"
                                  ]
                                ? true
                                : false
                            }
                          />
                        }
                        label="Yes"
                        className="mr-4"
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            checked={
                              formData[
                                "AnythingChangeInHealthYourLastAppointment"
                              ] === null
                                ? false
                                : !formData[
                                    "AnythingChangeInHealthYourLastAppointment"
                                  ]
                                ? true
                                : false
                            }
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </div> 
                </div>

                <div className="medical-heading">
                  <p>Has your insurance changed since the last appointment?</p>
                </div>
                <div className="row">
                  {/* <div className="radio-group">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="InsuranceChangeSinceLastAppointment"
                      id="InsuranceChangeSinceLastAppointment"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            checked={
                              formData[
                                "InsuranceChangeSinceLastAppointment"
                              ] === null
                                ? false
                                : formData[
                                    "InsuranceChangeSinceLastAppointment"
                                  ]
                                ? true
                                : false
                            }
                          />
                        }
                        label="Yes"
                        className="mr-4"
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            checked={
                              formData[
                                "InsuranceChangeSinceLastAppointment"
                              ] === null
                                ? false
                                : !formData[
                                    "InsuranceChangeSinceLastAppointment"
                                  ]
                                ? true
                                : false
                            }
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                </div> */}
              </div>
            )}

            {(Object.keys(patientForm.validationResponse).length !== 0 &&
              patientForm.validationResponse.visitType === 'Eval' &&
              !patientForm.validationResponse.preRegistrationComplete) ||
            Object.keys(patientForm.newPatientValidation).length !== 0 ? (
              <>
                <div className="screening-container-alignment d-flex align-items-start mb-3">
                  <Checkbox
                    value={true}
                    name="checkbox1"
                    onChange={handleCheckbox}
                    className="tnc-checkbox mr-1"
                    id="tnc-checkbox"
                    checked={
                      formData['checkbox1'] === null
                        ? false
                        : formData['checkbox1']
                        ? true
                        : false
                    }
                  ></Checkbox>
                  <span className="screening-checkbox">
                    {_resources.Signature.I_AGREE_TO_THE}{' '}
                    <a
                      rel="noreferrer"
                      id="terms-conditions"
                      onClick={() => setReceiptAcknowledgement(true)}
                      className="color-blue"
                    >
                      {_resources.Signature.ACK_OF_RECEIPT}
                    </a>{' '}
                    {_resources.Signature.AND_THE}{' '}
                    <a
                      rel="noreferrer"
                      id="privacy-policy"
                      className="color-blue"
                      onClick={() => setTreatmentAcknowledgement(true)}
                    >
                      {_resources.Signature.AUTH_CONSENT}
                    </a>
                    <Modal
                      visible={receiptAcknowledgement}
                      className="exception-modal"
                      onCancel={() => setReceiptAcknowledgement(false)}
                      // onOk={() => setApiCallOnArrow(false)}
                      footer={null}
                      // size="large"
                      centered
                    >
                      <div className="modal-content">
                        <span className="pop-up-modal-text">
                          {_resources.Signature.RECEIPT_ACK_MODAL_TEXT_PART_1}
                          <br></br>
                          <b>
                            {_resources.Signature.RECEIPT_ACK_MODAL_TEXT_PART_2}{' '}
                          </b>{' '}
                          <br></br>
                          {_resources.Signature.RECEIPT_ACK_MODAL_TEXT_PART_3}
                          <br></br>I
                          {_resources.Signature.RECEIPT_ACK_MODAL_TEXT_PART_4}{' '}
                          <br></br>
                          {_resources.Signature.RECEIPT_ACK_MODAL_TEXT_PART_5}
                        </span>
                      </div>
                    </Modal>
                    <Modal
                      visible={treatmentAcknowledgement}
                      className="exception-modal"
                      onCancel={() => setTreatmentAcknowledgement(false)}
                      // onOk={() => setApiCallOnArrow(false)}
                      footer={null}
                      // size="large"
                      centered
                    >
                      <div className="modal-content">
                        <span className="pop-up-modal-text">
                          {_resources.Signature.TREATMENT_ACK_MODAL_TEXT}
                        </span>
                      </div>
                    </Modal>
                  </span>
                </div>

                <div className="d-flex align-items-start mb-3">
                  <Checkbox
                    className="tnc-checkbox mr-1"
                    id="tnc-checkbox"
                    value="checkbox2"
                    name="checkbox2"
                    onChange={handleCheckbox}
                    checked={
                      formData['checkbox2'] === null
                        ? false
                        : formData['checkbox2']
                        ? true
                        : false
                    }
                    // onClick={() => setBookButtonState(!bookButtonState)}
                  ></Checkbox>
                  <span className="screening-checkbox">
                    {_resources.Signature.I_AGREE_TO_THE}{' '}
                    <a
                      rel="noreferrer"
                      id="terms-conditions"
                      onClick={() => setActionAcknowledgement(true)}
                      className="color-blue"
                    >
                      {_resources.Signature.CAUSE_OF_ACTION}{' '}
                    </a>
                    {_resources.Signature.AND}{' '}
                    <a
                      rel="noreferrer"
                      id="terms-conditions"
                      onClick={() => setFinancialAcknowledgement(true)}
                      className="color-blue"
                    >
                      {_resources.Signature.FINANCIAL_RESP}{' '}
                    </a>
                    <Modal
                      visible={actionAcknowledgement}
                      className="exception-modal"
                      onCancel={() => setActionAcknowledgement(false)}
                      // onOk={() => setApiCallOnArrow(false)}
                      footer={null}
                      // size="large"
                      centered
                    >
                      <div className="modal-content">
                        <span className="pop-up-modal-text-small">
                          {_resources.Signature.ACTION_ACK_MODAL_TEXT_1}{' '}
                          {moment(new Date()).format('YYYY-MM-DD')}.{' '}
                          {_resources.Signature.ACTION_ACK_MODAL_TEXT_2}
                        </span>
                      </div>
                    </Modal>
                    <Modal
                      visible={financialAcknowledgement}
                      className="exception-modal"
                      onCancel={() => setFinancialAcknowledgement(false)}
                      // onOk={() => setApiCallOnArrow(false)}
                      footer={null}
                      // size="large"
                      centered
                    >
                      <div className="modal-content">
                        <span className="pop-up-modal-text">
                          {_resources.Signature.FINANCIAL_ACK_MODAL_TEXT}
                        </span>
                      </div>
                    </Modal>
                  </span>
                </div>

                <div className="d-flex align-items-start mb-3">
                  <Checkbox
                    value="checkbox3"
                    name="checkbox3"
                    onChange={handleCheckbox}
                    className="tnc-checkbox mr-1"
                    id="tnc-checkbox"
                    checked={
                      formData['checkbox3'] === null
                        ? false
                        : formData['checkbox3']
                        ? true
                        : false
                    }
                    // onClick={() => setBookButtonState(!bookButtonState)}
                  ></Checkbox>
                  <span className="screening-checkbox">
                    {_resources.Signature.I_AGREE_TO_THE}{' '}
                    <a
                      href="https://www.coraphysicaltherapy.com/terms-of-use/"
                      className="clickable-link color-blue"
                      target="_blank"
                      rel="noreferrer"
                      id="terms-conditions"
                    >
                      {_resources.Signature.T_AND_C}{' '}
                    </a>
                    {_resources.Signature.AND_THE}{' '}
                    <a
                      href="https://www.coraphysicaltherapy.com/privacy-policy/"
                      className="clickable-link color-blue"
                      target="_blank"
                      rel="noreferrer"
                      id="privacy-policy"
                    >
                      {_resources.Signature.PRIVACY_POLICY}
                    </a>
                  </span>
                </div>
              </>
            ) : null}

            {(Object.keys(patientForm.validationResponse).length !== 0 &&
              patientForm.validationResponse.visitType === 'Eval' &&
              !patientForm.validationResponse.preRegistrationComplete) ||
            Object.keys(patientForm.newPatientValidation).length !== 0 ? (
              <Button
                className={`dual-book-button continue-btn ${
                  !formData['checkbox1'] ||
                  !formData['checkbox2'] ||
                  !formData['checkbox3'] ||
                  canvasEmpty
                    ? 'Mui-disabled'
                    : 'test'
                }`}
                onClick={() => handleClick()}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                disabled={
                  !formData['checkbox1'] ||
                  !formData['checkbox2'] ||
                  !formData['checkbox3'] ||
                  canvasEmpty
                    ? true
                    : false
                }
              >
                {updatePatientSpinner && (
                  <Spin
                    indicator={antIcon}
                    className="mr-3 submit-btn-loader"
                  />
                )}{' '}
                {_resources.Signature.SUBMIT}
              </Button>
            ) : (
              <Button
                className={`dual-book-button continue-btn ${
                  canvasEmpty ? 'Mui-disabled' : 'test'
                }`}
                onClick={() => handleClick()}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                disabled={canvasEmpty ? true : false}
              >
                {updatePatientSpinner && (
                  <Spin
                    indicator={antIcon}
                    className="mr-3 submit-btn-loader"
                  />
                )}{' '}
                {_resources.Signature.SUBMIT}
              </Button>
            )}
            <Button
              size="large"
              id="back-btn"
              onClick={goBack}
              className="dual-book-button back-button"
              variant="contained"
            >
              {_resources.Signature.BACK}
            </Button>
          </div>
        </div>
        <div className="col-lg-5 p-0 carousel-column">
          <Carousel>
            <div>
              <div className="clinic-pic-one"></div>
            </div>
            <div>
              <div className="clinic-pic-two"></div>
            </div>
            <div>
              <div className="clinic-pic-three"></div>
            </div>
            <div>
              <div className="clinic-pic-four"></div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Signature;
