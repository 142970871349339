/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Header from './headerPhase2';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import backArrow from './../assets/img/back-arrow.svg';
import redoBtn from './../assets/img/redo-btn.svg';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spin } from 'antd';
import errorImage from './../assets/img/error-image.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { resources } from '../resource';

import Checkbox from '@mui/material/Checkbox';

import moment from 'moment';

function AssignmentOfBenefit(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  let signatureRef = React.useRef(null);
  const actions = bindActionCreators(actionCreators, dispatch);
  const [updatePatientSpinner, setUpdatePatientSpinner] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [canvasEmpty, setCanvasEmpty] = useState(true);
  const [benefitTypes, setBenefitTypes] = useState('');
  const [hasSignature, setHasSignature] = useState(false);

  const navigate = useNavigate();
  let canvasRef;

  const _resourcesENG = resources['en'];
  const _resourcesSPA = resources['spa'];
  const _resourcesCr = resources['cr'];
  const _resources = resources[props.language];

  const initialFormData = Object.freeze({
    AnythingChangeInHealthYourLastAppointment: null,
    InsuranceChangeSinceLastAppointment: null,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });

  const patientForm = useSelector((state) => state.patientForm);
  const { companyId, patientId } = patientForm.patientForm;

  useEffect(() => {
    if (patientForm.patientForm.companyId !== '') {
      getPatientBenefits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientForm.patientForm]);

  const [formData, updateFormData] = React.useState(initialFormData);

  var url, clinicId;
  if (patientForm.clinicName.includes('/patient-checkin/checkin?clinicId=')) {
    if (patientForm.clinicName !== '') {
      url = new URL(patientForm.clinicName);
      clinicId = url.searchParams.get('clinicId');
    }
  } else {
    clinicId = patientForm.clinicName;
  }

  const getPatientBenefits = async () => {
    try {
      const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

      url = new URL(window.location.href);
      let epno = url.searchParams.get('Epno');
      let companyId = url.searchParams.get('CompanyId');

      if (epno === null || companyId === null) {
        epno = '' + patientForm.validationResponse.epno;
        companyId = patientForm.patientForm.companyId;
      }

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );

      const response = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/GetBenefitInformation`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            companyId: companyId,
            epno: epno,
          }),
        }
      );
      clearTimeout(id);
      const benefitTypes = await response.json();

      setBenefitTypes(benefitTypes?.benefitInformation);
      console.log('benefits ', benefitTypes?.benefitInformation);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  const handleClick = async () => {
    try {
      const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

      setUpdatePatientSpinner(true);
      // actions.clearPhase2Form([]);
      url = new URL(window.location.href);
      let epno = url.searchParams.get('Epno');
      let companyId = url.searchParams.get('CompanyId');
      let checkIn = false;

      if (epno === null || companyId === null) {
        epno = '' + patientForm.validationResponse.epno;
        companyId = patientForm.patientForm.companyId;
        checkIn = true;
      }

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );

      const response = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/SubmitAssignmentofbenefits`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            epno: epno,
            companyId: companyId,
            deductible: benefitTypes && benefitTypes.deductible,
            deductibleUsed: benefitTypes && benefitTypes.deductibleUsed,
            coinsurance: benefitTypes && benefitTypes.coinsurance,
            coPay: benefitTypes && benefitTypes.coPay,
            signature: canvasRef.toDataURL(),
            signatureExtension: 'jpeg',
          }),
        }
      );
      clearTimeout(id);

      const responseData = await response.json();
      if (responseData.responseCode === 200) {
        if (checkIn) {
          navigate(`/patient-checkin/signature`, { state: { fromFlow: true } });
        } else {
          actions.clearPhase2Form([]);
          navigate('/patient-checkin/form-submitted', {
            state: {
              response: responseData.message,
              newPatient: false,
            },
          });
        }
      } else {
        setUpdatePatientSpinner(false);
        setApiErrorMessage(true);
      }
    } catch (error) {
      setUpdatePatientSpinner(false);
      setApiErrorMessage(true);
    }
  };

  const redoButtonClear = () => {
    canvasRef.clear();
    setHasSignature(false);
    setCanvasEmpty(true);
  };

  canvasRef = patientForm.signature;
  return (
    <>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            {_resources.Checkin.ERROR}
          </span>
        </div>
      </Modal>
      <Header />
      <Helmet>
        <title>Cora Health | Appointment Submitted</title>
      </Helmet>

      <div className="confirm-and-book-appointment checkin-page-carousel d-flex justify-content-between">
        <div className="col-lg-7 signature-data">
          <div className="signature-heading">
            <p className="signature">
              {_resources.VerificationOfBenefits.HEADING}
            </p>
          </div>
          {/* <div className="signature-canvas"> */}
          <p className="pop-up-modal-text-small benefit-assig-notif">
            {_resourcesENG.VerificationOfBenefits.PART_1}{' '}
            <strong>${benefitTypes.deductible}</strong>{' '}
            {_resourcesENG.VerificationOfBenefits.PART_2}
            <strong>${benefitTypes.deductibleUsed}</strong>
            {_resourcesENG.VerificationOfBenefits.PART_3}{' '}
            <strong>{100 - benefitTypes.coinsurance}%</strong>
            {_resourcesENG.VerificationOfBenefits.PART_4}
            <strong>${benefitTypes.coPay}</strong>
            {_resourcesENG.VerificationOfBenefits.PART_4_5}
            <strong>{benefitTypes.coinsurance}%</strong>
            {_resourcesENG.VerificationOfBenefits.PART_5}.
          </p>
          <p className="pop-up-modal-text-small benefit-assig-notif">
            {_resourcesSPA.VerificationOfBenefits.PART_1}{' '}
            <strong>${benefitTypes.deductible}</strong>{' '}
            {_resourcesSPA.VerificationOfBenefits.PART_2}
            <strong>${benefitTypes.deductibleUsed}</strong>
            {_resourcesSPA.VerificationOfBenefits.PART_3}{' '}
            <strong>{100 - benefitTypes.coinsurance}%</strong>
            {_resourcesSPA.VerificationOfBenefits.PART_4}
            <strong>${benefitTypes.coPay}</strong>
            {_resourcesSPA.VerificationOfBenefits.PART_4_5}
            <strong>{benefitTypes.coinsurance}%</strong>
            {_resourcesSPA.VerificationOfBenefits.PART_5}.
          </p>
          <p className="pop-up-modal-text-small benefit-assig-notif">
            {_resourcesCr.VerificationOfBenefits.PART_1}{' '}
            <strong>${benefitTypes.deductible}</strong>{' '}
            {_resourcesCr.VerificationOfBenefits.PART_2}
            <strong>${benefitTypes.deductibleUsed}</strong>
            {_resourcesCr.VerificationOfBenefits.PART_3}{' '}
            <strong>{100 - benefitTypes.coinsurance}%</strong>
            {_resourcesCr.VerificationOfBenefits.PART_4}
            <strong>${benefitTypes.coPay}</strong>
            {_resourcesCr.VerificationOfBenefits.PART_4_5}
            <strong>{benefitTypes.coinsurance}%</strong>
            {_resourcesCr.VerificationOfBenefits.PART_5}.
          </p>
          {/* </div> */}
          <div className="signature-canvas">
            <SignatureCanvas
              ref={(ref) => (canvasRef = ref)}
              onEnd={(e) => {
                if (e['composed']) {
                  setCanvasEmpty(false);
                }
              }}
              onBegin={() => {
                setHasSignature(true);
              }}
              va
              penColor="green"
              // canvasProps={{ width: 300, height: 350, className: "sigCanvas" }}
              canvasProps={{
                // width: 700,
                // height: 350,
                className: 'sigCanvas',
              }}
            />
            {!hasSignature && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {_resources.VerificationOfBenefits.SIGN_HERE}
              </div>
            )}

            <Button className="redo" onClick={redoButtonClear}>
              <img src={redoBtn} alt="date" />
              <span className="ml-2">
                {_resources.VerificationOfBenefits.REDO}
              </span>
            </Button>
          </div>

          <div className="screen">
            <Button
              className="dual-book-button continue-btn"
              onClick={() => handleClick()}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={canvasEmpty ? true : false}
            >
              {updatePatientSpinner && (
                <Spin indicator={antIcon} className="mr-3 submit-btn-loader" />
              )}{' '}
              Submit
            </Button>
            <Button
              size="large"
              id="back-btn"
              onClick={() => window.history.back()}
              className="dual-book-button back-button"
              variant="contained"
            >
              Back
            </Button>
          </div>
        </div>
        <div className="col-lg-5 p-0 carousel-column">
          <Carousel>
            <div>
              <div className="clinic-pic-one"></div>
            </div>
            <div>
              <div className="clinic-pic-two"></div>
            </div>
            <div>
              <div className="clinic-pic-three"></div>
            </div>
            <div>
              <div className="clinic-pic-four"></div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default AssignmentOfBenefit;
