/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';
import { Modal, Spin } from 'antd';
import { TextField, Button } from '@material-ui/core';
import { Carousel } from 'antd';
import 'antd/dist/antd.min.css';
import { LoadingOutlined } from '@ant-design/icons';
import phyTherapyIcon from './../assets/img/phy-therapy-icon.svg';
import occTherapyIcon from './../assets/img/occ-therapy-icon.svg';
import speechTherapyIcon from './../assets/img/speech-therapy-icon.svg';
import calanderIcon from './../assets/img/calendar-icon.png';
import Header from './header';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useDispatch, useSelector } from 'react-redux';
import { resources } from '../resource';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import validateDate from '../utils/validateDate';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
}));
function Checkin(props) {
  const initialFormData = Object.freeze({
    dob: null,
    mobileNumber: null,
    lastName: '',
  });

  useEffect(() => {
    actions.setAuth(false);
  }, []);

  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [checkInMessage, setCheckInMessage] = useState("");
  const [loader] = useState(false);
  const [address] = useState('');
  const [verifyCheckIn, setVerifyCheckIn] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(true);
  const [clinicNotFoundModal, setClinicNotFoundModal] = useState(false);
  const [patientHaveAppointment, setPatientHaveAppointmentModel] =
    useState(false);
  const [patientVerificationSpinner, setPatientVerificationSpinner] =
    useState(false);
  const [registraionPopup, setRegistraionPopup] = useState(false);

  const [dualRecords, setDualRecords] = useState(false);

  const [formData, updateFormData] = React.useState(initialFormData);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

  const _resources = resources[props.language];

  var today = new Date();
  const classes = useStyles();
  const navigate = useNavigate();

  function getNumber() {
    const inputField = document.getElementById('mobileNumber');
    const formattedInputValue = formatPhoneNumber(inputField?.value);
    return formattedInputValue;
  }

  const patientForm = useSelector((state) => state.patientForm);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var date =
    monthNames[today.getMonth()] +
    ' ' +
    today.getDate() +
    ', ' +
    today.getFullYear();

  //Verify Url

  useEffect(() => {
    localStorage.clear();
    actions.updateEditButtonForNewPatient(false);
    actions.updateEditButtonForExistingPatient(false);
    actions.patientFormNextButton(false);
    actions.medicalFormNextButton(false);
    actions.uploadPatientForm([]);
    actions.uploadMedicalForm([]);
    actions.clearPhase2Form([]);
    actions.clearAllMultipleDocument([]);
    actions.setClinicUrl(window.location.href);
    //localStorage.clear();
    // localStorage.setItem("persist:root", null);
    //storage.removeItem('persist:root');
  }, [address]);

  //Format phone number
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const verifyPatient = async (data) => {
    if (dobError) return;

    try {
      let obj;
      if (dualRecords) {
        obj = {
          dob: moment(data['dateOfBirth']).format('YYYY-MM-DD'),
          cellAreaCode: formData.mobileNumber.slice(1, 4),
          cellPhone: formData.mobileNumber.slice(6, 14).replaceAll('-', ''),
          lastName: formData.lastName,
        };
      } else {
        obj = {
          dob: moment(data['dateOfBirth']).format('YYYY-MM-DD'),
          lastName: formData.lastName,
        };
      }

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );

      setPatientVerificationSpinner(true);
      const responseAPI = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/PatientVerification`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(obj),
        }
      );
      clearTimeout(id);

      const response = await responseAPI.json();
      setPatientVerificationSpinner(false);

      if (!dualRecords) {
        if (response.patientAddress.singleRecord == 'false') {
          setDualRecords(true);
          return;
        }
      }

      if (response.patientAddress.singleRecord == 'false') {
        setRegistraionPopup(true);
        return;
      }

      if (response.patientAddress !== null) {
        actions.setCheckInScreeningEnabled(
          response.patientAddress.screeningEnabled
        );
        actions.setVOBNeeded(response.patientAddress.vobNeeded);
        actions.setAmountDue(response.patientAddress.amountDue);

        actions.setAuth(true);

        if (response.patientAddress.visitType === 'Neither') {
          setRegistraionPopup(true);
          const { message } = response.patientCheckInStatus;
          setCheckInMessage(message)
        } else {
          if (response.patientHistory['autoHealthInsurancePhone'] !== null) {
            response.patientHistory['autoHealthInsurancePhone'] =
              response.patientHistory['autoHealthInsuranceAreaCode'] +
              response.patientHistory['autoHealthInsurancePhone'];
          }

          if (response.patientHistory['autoAttorneyPhone'] !== null) {
            response.patientHistory['autoAttorneyPhone'] =
              response.patientHistory['autoAttorneyAreaCode'] +
              response.patientHistory['autoAttorneyPhone'];
          }

          if (response.patientHistory['emergencyContactPhone'] !== null) {
            response.patientHistory['emergencyContactPhone'] =
              response.patientHistory['emergencyContactAreaCode'] +
              response.patientHistory['emergencyContactPhone'];
          }

          if (response.patientHistory['employerPhone'] !== null) {
            response.patientHistory['employerPhone'] =
              response.patientHistory['employerAreaCode'] +
              response.patientHistory['employerPhone'];
          }

          if (response.patientHistory['mobilePhone'] !== null) {
            response.patientHistory['mobilePhone'] =
              response.patientHistory['mobileAreaCode'] +
              response.patientHistory['mobilePhone'];
          }

          if (response.patientHistory['referredByPhone'] !== null) {
            response.patientHistory['referredByPhone'] =
              response.patientHistory['referredByAreaCode'] +
              response.patientHistory['referredByPhone'];
          }

          if (response.patientHistory['primaryCarePhysicianPhone'] !== null) {
            response.patientHistory['primaryCarePhysicianPhone'] =
              response.patientHistory['primaryCarePhysicianAreaCode'] +
              response.patientHistory['primaryCarePhysicianPhone'];
          }
          //preRegisteration = neither
          if (response.patientAddress.visitType === 'Eval') {
            if (response.patientAddress.preRegistrationComplete === false) {
              //Please check this
              // actions.clearPhase2Form([]);
              response.patientHistory['zip'] = '';
              response.patientHistory['state'] = '';
              response.patientHistory['city'] = '';

              actions.validationResponse(response.patientAddress);
              actions.uploadPatientForm(response.patientHistory);


              const { checkInStatus, message } = response.patientCheckInStatus;
              if(checkInStatus == "1"){
                  navigate(`/patient-checkin/patient-form`, {
                  state: {
                    prevPath: document.URL,
                    dob: data['dateOfBirth'],
                    gender: response.patientHistory['gender'],
                    cellAreaCode: response.patientHistory['mobileAreaCode'],
                    cell: response.patientHistory['mobilePhone'],
                    fromFlow: true,
                    //                  mobile: formatPhoneNumber(formData.mobileNumber),
                  },
                });
                
                setCheckInMessage("")
              } else if(checkInStatus == "2") {
                setCheckInMessage(message)
                return
              } else if(checkInStatus == "3") {
                setCheckInMessage(message)
                return
              } else if(checkInStatus == "4") {
                setCheckInMessage(message)
                return
              }
              
              setVerificationStatus(false);
              setClinicNotFoundModal(true);
              actions.updateEditButtonForNewPatient(true);
            } else {
              const imageType = {
                imageUrl: response.patientHistory['letterOfExhaustionFromCopy'],
                extension:
                  response.patientHistory[
                    'letterOfExhaustionFromCopyExtension'
                  ],
              };

              actions.validationResponse(response.patientAddress);
              actions.uploadLetterOfExhaustionCopy(imageType);
              actions.uploadPatientForm(response.patientHistory);
              actions.uploadMedicalForm(response.patientHistory);
              actions.uploadMedicareForm(response.patientHistory);
              // actions.uploadDocumentsFromResponse(response.documents);

              actions.updateEditButtonForExistingPatient(true);
              // if (
              //   response.patientAddress.screeningEnabled &&
              //   !response.patientAddress.amountDue
              // ) {
              //   navigate(`/patient-checkin/checkInScreening`, {
              //     state: { fromFlow: true },
              //   });
              // } else

              const { checkInStatus, message } = response.patientCheckInStatus;
              if(checkInStatus == "1"){
                  if (response.patientAddress.vobNeeded) {
                    navigate(`/assignmentOfBenefit`);
                  } else {
                    navigate(`/patient-checkin/signature`, {
                      state: { fromFlow: true },
                    });
                  }
                  setCheckInMessage("")
              } else if(checkInStatus == "2") {
                  setCheckInMessage(message)
                  return
              } else if(checkInStatus == "3") {
                  setCheckInMessage(message)
                  return
              } else if(checkInStatus == "4") {
                  setCheckInMessage(message)
                  return
              }
            }
          }

          if (response.patientAddress.visitType === 'Visit') {
            actions.validationResponse(response.patientAddress);
            actions.uploadPatientForm(response.patientHistory);
            actions.uploadMedicalForm(response.patientHistory);
            actions.uploadMedicareForm(response.patientHistory);
            // actions.uploadDocumentsFromResponse(response.documents);
            actions.updateEditButtonForExistingPatient(true);

            const { checkInStatus, message } = response.patientCheckInStatus;
            
            if(checkInStatus == "1"){
              setPatientHaveAppointmentModel(true);
              setCheckInMessage("")
            } else if(checkInStatus == "2") {
              setCheckInMessage(message)
              return
            } else if(checkInStatus == "3") {
              setCheckInMessage(message)
              return
            } else if(checkInStatus == "4") {
              setCheckInMessage(message)
              return
            }
            
          }
        }
      } else {
        const messageText = `No record Found`;
        message.error(messageText, [1.5]);
      }
    } catch (error) {
      setApiErrorMessage(true);
      setPatientVerificationSpinner(false);
    }
  };

  const handleChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: formattedPhoneNumber.trim(),
    });
  };

  const updateLastName = (e) => {
    updateFormData({
      ...formData,
      lastName: e.target.value,
    });
  };

  const { register, handleSubmit, control, errors, clearErrors, setValue } =
    useForm();

  const checkInModalOpen = () => {
    setVerifyCheckIn(true);
    setValue('mobileNumber', null);
    setValue('dateOfBirth', null);
    setValue('lastName', '');
    setDualRecords(false);
  };

  const yesOnClick = () => {
    navigate(`/patient-checkin/patient-form`, { state: { fromFlow: true } });
    actions.existingPatientWithAppointment(true);
    actions.medicalFormNextButton(true);
  };

  function onCloseVerificationModal() {
    setDobError(false);
    setCheckInMessage("")
    setVerifyCheckIn(false);
    clearErrors(['dateOfBirth', 'mobileNumber', 'lastName']);
    setValue('mobileNumber', null);
    setValue('dateOfBirth', null);
    setValue('lastName', '');
    formData.dob = null;
    formData.mobileNumber = null;
    formData.lastName = '';
    setPatientHaveAppointmentModel(false);
    setRegistraionPopup(false);
    setDualRecords(false);
  }

  const [dobError, setDobError] = useState(false);

  return (
    <div className="home-page checkin-page-carousel  d-flex flex-column justify-content-between">
      {loader ? (
        <div className="verification">
          <Spin
            className="mr-3 search-clinic-btn-loader"
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
          />
          <span>{_resources.Checkin.PLEASE_WAIT}</span>
        </div>
      ) : (
        <>
          <Modal
            visible={clinicNotFoundModal}
            className="exception-modal"
            onCancel={() => navigate(`/patient-checkin/form-submitted`)}
            onOk={() => navigate(`/patient-checkin/form-submitted`)}
            footer={null}
            centered
          >
            <div className="modal-content">
              <span className="exception-modal-text">
                {_resources.Checkin.DATE}check in. For more information, please
                proceed to front desk
              </span>
            </div>
          </Modal>
          <Modal
            visible={verifyCheckIn}
            className="exception-modal"
            onCancel={() => onCloseVerificationModal()}
            onOk={() => onCloseVerificationModal()}
            footer={null}
            centered
          >
            <form onSubmit={handleSubmit(verifyPatient)} className="modal-form">
              <div className="pop-up-verify">
                {patientHaveAppointment ? (
                  <>
                    <h2 className="pop-up-details">
                      {_resources.Checkin.WELCOME}
                      <span>
                        {' ' +
                          patientForm.patientForm.firstName +
                          ' ' +
                          patientForm.patientForm.lastName}
                      </span>
                    </h2>
                    <h3 className="pop-up-details">
                      {_resources.Checkin.INFO_CHANGED_TEXT}
                    </h3>
                    <br />

                    <div className="row">
                      <div className="col-lg-12 pateint-column-field">
                        <TextField
                          label={_resources.Checkin.PHONE_NUMBER}
                          variant="outlined"
                          size="small"
                          disabled={true}
                          fullWidth
                          className={classes.inputField}
                          onChange={handleChange}
                          name="phoneNumber"
                          id="phoneNumber"
                          value={formatPhoneNumber(
                            patientForm.patientForm.mobilePhone
                          )}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 pateint-column-field">
                        <TextField
                          label={_resources.Checkin.EMAIL}
                          variant="outlined"
                          size="small"
                          disabled={true}
                          fullWidth
                          className={classes.inputField}
                          onChange={handleChange}
                          name="email"
                          id="email"
                          value={patientForm.patientForm.email}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 pateint-column-field">
                        <TextField
                          label={_resources.Checkin.ADDRESS}
                          variant="outlined"
                          size="small"
                          disabled={true}
                          fullWidth
                          className={classes.inputField}
                          onChange={handleChange}
                          name="address1"
                          id="address1"
                          value={patientForm.patientForm.address1}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 pateint-column-field">
                        <TextField
                          label={_resources.Checkin.INSURANCE}
                          variant="outlined"
                          size="small"
                          disabled={true}
                          fullWidth
                          className={classes.inputField}
                          onChange={handleChange}
                          name="email"
                          id="email"
                          value={patientForm.patientForm.insuranceProvider}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  !registraionPopup && (
                    <h2 className="pop-up-details">
                      {_resources.Checkin.ENTER_YOUR_DETAILS}
                    </h2>
                  )
                )}
              </div>

              {!patientHaveAppointment && !registraionPopup && (
                <>
                  {dualRecords && (
                    <p className="text-center">
                      Please provide phone number for verification.
                    </p>
                  )}
                  <div className="dob-datepicker-col">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        render={(props) => (
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="dob"
                            name="dob"
                            disabled={patientHaveAppointment}
                            label={_resources.Checkin.DOB_PLACEHOLDER}
                            value={props.value}
                            disableFuture={true}
                            onChange={(value) => {
                              props.onChange(value);
                              const dateValidated = validateDate(value);
                              setDobError(dateValidated);
                            }}
                            fullWidth
                            error={Boolean(errors.dateOfBirth) || dobError}
                            helperText={
                              errors.dateOfBirth?.message ||
                              (dobError && 'Invalid Date')
                            }
                            size="small"
                          />
                        )}
                        name="dateOfBirth"
                        defaultValue={null}
                        control={control}
                        rules={{
                          required: _resources.Checkin.DOB_REQUIRED,
                        }}
                        size="small"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    <TextField
                      label={_resources.Checkin.LAST_NAME}
                      disabled={patientHaveAppointment}
                      variant="outlined"
                      size="small"
                      id="lastName"
                      fullWidth
                      onChange={(e) => updateLastName(e)}
                      className={classes.inputField}
                      name="lastName"
                      inputRef={register({
                        required: _resources.Checkin.REQ_LAST_NAME,
                        pattern: {
                          value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                          message: _resources.Checkin.SHOULD_VALID_LAST_NAME,
                        },
                      })}
                      defaultValue={formData.lastName}
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName?.message}
                      inputProps={{ maxLength: 50 }}
                      autoComplete="off"
                    />
                  </div>

                  <div
                    className={
                      dualRecords
                        ? 'phone-number-col'
                        : 'phone-number-col d-none'
                    }
                  >
                    <TextField
                      placeholder="Phone Number*"
                      label={_resources.Checkin.MOBILE_PLACEHOLDER}
                      disabled={patientHaveAppointment}
                      variant="outlined"
                      size="small"
                      id="mobileNumber"
                      fullWidth
                      onChange={handleChange}
                      className={classes.inputField}
                      name="mobileNumber"
                      inputRef={register({
                        required: dualRecords
                          ? _resources.Checkin.PHONE_REQUIRED
                          : false,
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                          message: _resources.Checkin.PHONE_VALID_NUMBER,
                        },
                      })}
                      value={getNumber()}
                      error={Boolean(errors.mobileNumber)}
                      helperText={errors.mobileNumber?.message}
                      inputProps={{ maxLength: 14 }}
                    />
                  </div>
                </>
              )}

              {registraionPopup && (
                <>
                  <h2 className="pop-up-details">
                    {checkInMessage}
                  </h2>
                </>
              )}
              {patientHaveAppointment ? (
                <>
                  {' '}
                  <Button
                    className="dual-book-button mt-3"
                    variant="contained"
                    // color="primary"
                    size="large"
                    onClick={() => {
                      // if (
                      //   patientForm.checkInScreeningEnabled &&
                      //   !patientForm.amountDue
                      // ) {
                      //   navigate(`/patient-checkin/checkInScreening`, {
                      //     state: { fromFlow: true },
                      //   });
                      // } else

                      if(checkInMessage == ""){
                        if (patientForm.vobNeeded) {
                          navigate(`/assignmentOfBenefit`);
                        } else {
                          navigate(`/patient-checkin/signature`, {
                            state: { fromFlow: true },
                          });
                        }
                      }
                    }}
                  >
                    {_resources.Checkin.NO}
                  </Button>
                  <Button
                    className="dual-book-button no-button"
                    variant="contained"
                    // color="primary"
                    onClick={() => yesOnClick()}
                    size="large"
                  >
                    {_resources.Checkin.YES}
                  </Button>
                </>
              ) : (
                !registraionPopup && (
                  <>
                    <Button
                      className="dual-book-button mb-2"
                      variant="contained"
                      // color="primary"
                      type="submit"
                      // size="large"
                    >
                      {patientVerificationSpinner && (
                        <Spin
                          indicator={antIcon}
                          className="mr-3 submit-btn-loader"
                        />
                      )}{' '}
                      {_resources.Checkin.VALIDATE}
                    </Button>
                    <span className='mt-2 text-danger'>{checkInMessage}</span>
                  </>
                )
              )}
            </form>
          </Modal>

          <Modal
            visible={apiErrorMessage}
            className="exception-modal"
            onCancel={() => setApiErrorMessage(false)}
            onOk={() => setApiErrorMessage(false)}
            footer={null}
            centered
          >
            <div className="modal-content">
              <img
                className="error-img"
                // src={errorImage}
                alt="Something went wrong."
              />
              <span className="exception-modal-text">
                {_resources.Checkin.ERROR}
              </span>
            </div>
          </Modal>
          {verificationStatus && (
            <>
              <Helmet>
                <title>CORA Health | Checkin</title>
              </Helmet>
              <Header />
              <div className="row no-gutters main-row">
                <div className="col-lg-7 form-section">
                  <div className="checkin-page">
                    <div className="headline-checkin">
                      <p>{_resources.Checkin.HEADING}</p>
                    </div>
                    <div className="main-controls">
                      <div className="filters-checkin" data-testid="step-1">
                        <div className="checkin-wrapper">
                          <div className="checkin-wrapper-content">
                            <div className="date-info">
                              <div>
                                {' '}
                                <img src={calanderIcon} alt="date" />{' '}
                              </div>
                              <div className="date-text">
                                <p className="date">
                                  {' '}
                                  {_resources.Checkin.DATE}
                                </p>
                                <p>{date}</p>
                              </div>
                            </div>
                          </div>
                          <div className="checkin-wrapper-info">
                            <div className="apply-btn">
                              <Button
                                type="submit"
                                id="search-clinic-button"
                                onClick={checkInModalOpen}
                              >
                                {_resources.Checkin.CHECKIN_BUTTON}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <footer className="footer-home-page p-3 text-center">
                      <p className="footer-text">
                        {_resources.Checkin.LOWER_MODEL_HEADING}
                      </p>
                      <div className="footer-icons row">
                        <div className="col-4 ">
                          <img src={phyTherapyIcon} alt="Physical Therapy" />
                          <p> {_resources.Checkin.PHYSICAL_THERAPY}</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={occTherapyIcon}
                            alt="Occupational Therapy"
                          />
                          <p> {_resources.Checkin.OCCUPATIONAL_THERAPY}</p>
                        </div>
                        <div className="col-4">
                          <img src={speechTherapyIcon} alt="Speech Therapy" />
                          <p> {_resources.Checkin.SPEACH_THERAPY}</p>
                        </div>
                      </div>
                    </footer>
                  </div>
                </div>
                <div className="col-lg-5 carousel-column">
                  <Carousel>
                    <div>
                      <div className="clinic-pic-one"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-two"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-three"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-four"></div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Checkin;
