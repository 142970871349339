const reducer = (therapyValues = {}, { type, payload }) => {
  switch (type) {
    case "fetch":
      return payload;
    default:
      return therapyValues;
  }
};

export default reducer;
