import { applyMiddleware, createStore } from "redux";

import thunk from "redux-thunk";

import reducers from "./reducers/index";

import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage"; 

import storageSession from "redux-persist/lib/storage/session";// defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage: storageSession,
};

//added redux to persist data on refresh

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,

  {},

  applyMiddleware(thunk)
);

export const persistor = persistStore(store);

//export default store;

// export default () => {
//   return { store, persistor }
// }

