import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import "../App.css";
import "antd/dist/antd.min.css";

import phyTherapyIcon from "./../assets/img/phy-therapy-icon.svg";
import occTherapyIcon from "./../assets/img/occ-therapy-icon.svg";
import speechTherapyIcon from "./../assets/img/speech-therapy-icon.svg";
import teamPic from "./../assets/img/team-pic.png";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { useDispatch } from "react-redux";

import SearchClinicComponent from "./searchClinicComponent";
import Header from "./header";

function LandingPage(props) {
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  useEffect(() => {
    actions.setConfirmed(false);
    actions.clearAllMultipleDocument()
    localStorage.clear();
    actions.clearForm([]);
  }, []);
  return (
    <div className="home-page  d-flex flex-column justify-content-between">
      <Helmet>
        <title>CORA Health | Home</title>
      </Helmet>
      <Header />
      <div className="row no-gutters main-row">
        <div className="col-lg-6 form-section">
          <div className="headline">
            <span>Get On Our Books And Get On With Your Life.</span>
          </div>
          <div className="main-controls px-3">
            <SearchClinicComponent />
          </div>
        </div>
        <div className="col-lg-6 info-section">
          <div className="backdrop"></div>
          <div className="section-wrapper">
            <div className="cosmetic-circles">
              <span className="circle-1"></span>
              <span className="circle-2"></span>
            </div>
            <div>
              <div className="team-pic" id="cora-team-picture">
                <img src={teamPic} alt="Team-img" />
              </div>
            </div>
            <div className="home-page-footer p-3 text-center">
              <span className="footer-text">
                The sooner you make an appointment at a CORA Clinic near you,
                the sooner you’ll feel better. Let’s do this!
              </span>
              <div className="footer-icons my-3 row">
                <div className="col-4">
                  <img src={phyTherapyIcon} alt="Physical Therapy" />
                  <span>Physical Therapy</span>
                </div>
                <div className="col-4">
                  <img src={occTherapyIcon} alt="Occupational Therapy" />
                  <span>Occupational Therapy</span>
                </div>
                <div className="col-4">
                  <img src={speechTherapyIcon} alt="Speech Therapy" />
                  <span>Speech Therapy</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
