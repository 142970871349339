import './App.css';
import './styles/css/icons.min.css';
import './styles/fonts/css/all.min.css';
import './styles/scss/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import LandingPage from './Components/landingPage';
import ClinicList from './Components/clinicList';
import ClinicDetail from './Components/clinicDetail';
import ConfirmAppointment from './Components/confirmAppointment';
import AppointmentSubmitted from './Components/appointmentSubmitted';
import UploadDocuments from './Components/uploadDocuments';
import BookAppointment from './Components/bookAppointment';
import NotFound from './Components/notFoundRoute';
import PatientForm from './Components/patientForm';
import CheckIn from './Components/Checkin';
import ReviewForm from './Components/formReview';
import Signature from './Components/signature';
import CheckInScreening from './Components/checkInScreening';
import MedicarePatient from './Components/medicarePatient';
import { Routes, Route } from 'react-router-dom';
import AssignmentOfBenefit from './Components/assignmentOfBenefit';
import Submission from './Components/submission';

function App() {
  const [language, setLanguage] = React.useState('en');

  return (
    <div className="App">
      <div style={{ overflow: 'hidden' }}>
        <select
          onChange={(e) => setLanguage(e.target.value)}
          style={{ float: 'right' }}
        >
          <option value="en">English</option>
          <option value="spa">Spanish</option>
          <option value="cr">Creole</option>
        </select>
      </div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="clinic-list" element={<ClinicList />} />
        <Route path="clinic-detail/:param1" element={<ClinicDetail />} />
        <Route path="confirm-appointment" element={<ConfirmAppointment />} />
        <Route
          path="appointment-submitted"
          element={<AppointmentSubmitted />}
        />
        {/* <Route path="upload-documents/:id=id?" element={<UploadDocuments />} /> */}
        <Route path="upload-documents">
          <Route path=":id" element={<UploadDocuments />} />
          <Route path="" element={<UploadDocuments />} />
        </Route>
        <Route path="book-appointment" element={<BookAppointment />} />
        <Route
          path="patient-checkin/patient-form"
          element={<PatientForm language={language} />}
        >
          <Route
            path=":appointmentGuid"
            element={<PatientForm language={language} />}
          />
          <Route path="" element={<PatientForm />} />
        </Route>
        <Route
          path="patient-checkin/medicare-patient"
          element={<MedicarePatient language={language} />}
        />
        <Route path="patient-checkin/checkin">
          <Route path=":clinicId" element={<CheckIn language={language} />} />
          <Route path="" element={<CheckIn language={language} />} />
        </Route>
        <Route
          path="patient-checkin/review-form"
          element={<ReviewForm language={language} />}
        />
        {/* <Route path="patient-checkin/screening" element={<Screening />} /> */}
        <Route
          path="patient-checkin/signature"
          element={<Signature language={language} />}
        />
        <Route
          path="patient-checkin/checkInScreening"
          element={<CheckInScreening language={language} />}
        />
        <Route
          path="patient-checkin/form-submitted"
          element={<Submission language={language} />}
        />
        <Route path="patient-checkin/checkin">
          <Route path=":clinicId" element={<CheckIn />} />
          <Route path="" element={<CheckIn />} />
        </Route>
        {/* assignmentOfBenefit?CompanyId=1&Epno=12345 */}
        <Route path="assignmentOfBenefit">
          <Route
            path=":CompanyId"
            element={<AssignmentOfBenefit language={language} />}
          />
          <Route
            path=":Epno"
            element={<AssignmentOfBenefit language={language} />}
          />
          <Route
            path=""
            element={<AssignmentOfBenefit language={language} />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
