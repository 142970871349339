import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="not-found-msg pt-5">
    <h1>404</h1>
    <h2 className="pl-4">Page Not Found</h2>
    <p className="px-3 text-justify">
      The page you are looking for no longer exists. Perhaps you can return back
      to the <Link to="/">Go Home</Link> and see if you can find what you are
      looking for.
    </p>
  </div>
);

export default NotFound;
