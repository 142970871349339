import React, { useEffect, useState } from 'react';
import '../App.css';
import { Carousel } from 'antd';
import { Helmet } from 'react-helmet';
import Header from './headerPhase2';
import PatientInformationComponent from './patientInformationComponent';
import MedicalHistoryComponent from './medicalHistoryComponent';
import UploadDocumentComponent from './uploadDocumentComponent';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import 'antd/dist/antd.min.css';
import MedicarePatient from './medicarePatient';
import editIcon from './../assets/img/edit-icon.png';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { resources } from '../resource';
import { useLocation } from 'react-router-dom';

function ReviewForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const clinicUrl = useSelector((state) => state.auth.clinicUrl);
  const [expanded, setExpanded] = React.useState(false);
  const [screen, setScreen] = useState();

  const patientForm = useSelector((state) => state.patientForm);
  const multipleUploadDocument = useSelector(
    (state) => state.multipleUploadDocument
  );
  const _resources = resources[props.language];

  const dob = patientForm.patientForm.dob;
  const mobile = patientForm.patientForm.mobilePhone;

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const verifiedData = {
    dob,
    cellAreaCode: mobile?.substring(1, 4) || '',
    cell: mobile?.substring(6).replace('-', '') || '',
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    isMobileDevice();
    if (location.state == null) {
      if (clinicUrl && clinicUrl != '') {
        window.location.replace(clinicUrl);
      }
    }
    window.history.replaceState(null, '');
  }, []);

  function isMobileDevice() {
    if (window.innerWidth <= 1024) {
      setScreen('mobile');
    } else {
      setScreen('desktop');
    }
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
      textAlign: 'center',
    },
    imgBox: {
      maxWidth: '80%',
      maxHeight: '80%',
      margin: '10px',
    },
    img: {
      height: 'inherit',
      maxWidth: 'inherit',
    },
    input: {
      display: 'none',
    },
  }));
  const classes = useStyles();

  const editButton = () => {
    actions.patientFormNextButton(false);
    actions.medicalFormNextButton(false);
    navigate(`/patient-checkin/patient-form`, { state: { fromFlow: true } });
  };

  const nextButton = () => {
    // if (patientForm.checkInScreeningEnabled && !patientForm.amountDue) {
    //   navigate(`/patient-checkin/checkInScreening` , { state: {fromFlow: true} });
    // } else
    if (patientForm.vobNeeded) {
      navigate(`/assignmentOfBenefit`);
    } else {
      navigate('/patient-checkin/signature', { state: { fromFlow: true, fromReview: true } });
    }
  };

  return (
    <>
      <div className="appointment-booked-page">
        <Helmet>
          <title>CORA Health | Review Form</title>
        </Helmet>
        <Header />
      </div>

      <div className="content-wrapper-row row no-gutters form-review-carousel ">
        <div className="col-lg-7 form-review-container">
          <div className="edit-text">
            <span className="review">
              {_resources.ReviewPage.REVIEW_HEADING}
            </span>
            <span className="edit" onClick={editButton}>
              <img className="mr-1" src={editIcon} alt="edit icon" />
              {_resources.ReviewPage.EDIT}
            </span>
          </div>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className="medical-edit-form"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {_resources.ReviewPage.PATIENT_INFORMATION}
              </Typography>
            </AccordionSummary>
            <div className="patient-edit-form">
              <PatientInformationComponent
                classes={classes}
                screen={screen}
                title={_resources.ReviewPage.LETTER_OF_EXC_COPY}
                verifiedData={verifiedData}
                language={props.language}
              />
            </div>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {_resources.ReviewPage.MEDICAL_INFORMATION}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MedicalHistoryComponent language={props.language} />
            </AccordionDetails>
          </Accordion>

          {patientForm.patientForm.hasMedicalInsurance && (
            <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {_resources.ReviewPage.MEDICARE_FORM}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MedicarePatient language={props.language} />
              </AccordionDetails>
            </Accordion>
          )}

          {multipleUploadDocument.imageList.length > 0 ? (
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {_resources.ReviewPage.UPLOAD_DOCS}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UploadDocumentComponent
                  classes={classes}
                  screen={screen}
                  title={_resources.ReviewPage.UPLOAD_WORD}
                  type={_resources.ReviewPage.PRESCRIPTION}
                  language={props.language}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}

          <Button
            className="dual-book-button next-btn"
            onClick={() => nextButton()}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            {_resources.ReviewPage.NEXT}
          </Button>
          <Button
            size="large"
            id="back-btn"
            onClick={() => {
              navigate('/patient-checkin/patient-form', { state: { fromReview: true } });
            }}
            className="dual-book-button back-button"
            variant="contained"
          >
            {_resources.ReviewPage.BACK}
          </Button>
        </div>
        <div className="col-lg-5  carousel-column">
          <Carousel initialSlide={Math.floor(Math.random() * 4)}>
            <div>
              <div className="clinic-pic-one"></div>
            </div>
            <div>
              <div className="clinic-pic-two"></div>
            </div>
            <div>
              <div className="clinic-pic-three"></div>
            </div>
            <div>
              <div className="clinic-pic-four"></div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default ReviewForm;
