const initialState = {
  imageList: [],
  prescription: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "upload":
      return {
        ...state,
        imageList: [...state.imageList, payload],
      };

    case "remove-image":
      const filteredImages = state.imageList.filter(
        (item) => item.imageUrl !== payload
      );
      return {
        ...state,
        imageList: [...filteredImages],
      };

    case "prescription":
      return {
        ...state,
        prescription: payload,
      };
    case "removePrescriptions":
      return {
        ...state,
        prescription: {},
      };

    case "clearAll":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
