/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useSelector, useDispatch } from 'react-redux';
import infoIcon from './../assets/img/info-icon.svg';
import { Helmet } from 'react-helmet';
import Header from './headerPhase2';
import backArrow from './../assets/img/back-arrow.svg';
import { Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import { resources } from '../resource';

function MedicarePatient(props) {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const _resources = resources[props.language];

  const initialFormData = Object.freeze({
    physicalOccupationalSpeechThreapy: null,
    woundCare: null,
    injectionsMedications: null,
    bathingPersonalCare: null,
    ivCare: null,
    anyServicesNotListedAbove: null,
    homeHealthRepresentativeNursAide: null,
  });

  const [formData, updateFormData] = React.useState(initialFormData);
  const patientForm = useSelector((state) => state.patientForm);

  const navigate = useNavigate();

  useEffect(() => {
    if (patientForm.medicareForm?.length !== 0) {
      updateFormData(patientForm.medicareForm);
    }
  }, [patientForm]);

  function onSubmit() {
    actions.uploadMedicareForm(formData);
    navigate('/patient-checkin/review-form', { state: { fromFlow: true } });
  }

  const handleChange = (e) => {
    if (e.target.value === 'true' || e.target.value === 'false') {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value === 'true' ? true : false,
      });
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      {window.location.href.includes('review-form') ? (
        <div className="medicare-review-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="medicare-patient-text ">
              {/* <p className="heading">Medicare Patient</p> */}
              <p className="eligibility-text">
                {_resources.MedicarePatient.MEDICARE_HEADING_1}
              </p>
              <p className="representative-text">
                {_resources.MedicarePatient.MEDICARE_HEADING_2}
              </p>
            </div>

            <div className="medical-heading">
              <p>
                {
                  _resources.MedicarePatient
                    .PHYSICAL_OCCUPATIONAL_OR_SPEECH_THERAPY
                }
              </p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="physicalOccupationalSpeechThreapy"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['physicalOccupationalSpeechThreapy'] === null
                            ? false
                            : formData['physicalOccupationalSpeechThreapy']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['physicalOccupationalSpeechThreapy'] === null
                            ? false
                            : !formData['physicalOccupationalSpeechThreapy']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.NO}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="medical-heading">
              <p>{_resources.MedicarePatient.WOUND_CARE}</p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="woundCare"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['woundCare'] === null
                            ? false
                            : formData['woundCare']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['woundCare'] === null
                            ? false
                            : !formData['woundCare']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.NO}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="medical-heading">
              <p>{_resources.MedicarePatient.INJECTIONS_OR_MEDICATIONS}</p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="injectionsMedications"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['injectionsMedications'] === null
                            ? false
                            : formData['injectionsMedications']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['injectionsMedications'] === null
                            ? false
                            : !formData['injectionsMedications']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.NO}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="medical-heading">
              <p>{_resources.MedicarePatient.BATHING_OR_PERSONAL_CARE}</p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="bathingPersonalCare"
                  id="bathingPersonalCare"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['bathingPersonalCare'] === null
                            ? false
                            : formData['bathingPersonalCare']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['bathingPersonalCare'] === null
                            ? false
                            : !formData['bathingPersonalCare']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.NO}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="medical-heading">
              <p>{_resources.MedicarePatient.IV_CARE}</p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="ivCare"
                  id="ivCare"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['ivCare'] === null
                            ? false
                            : formData['ivCare']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['ivCare'] === null
                            ? false
                            : !formData['ivCare']
                            ? true
                            : false
                        }
                      />
                    }
                    label={_resources.MedicarePatient.NO}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="medical-heading">
              <p>{_resources.MedicarePatient.HOME_HEALTH_REPRESENTATIVE}</p>
            </div>
            <div className="row">
              <div className="col-6 col-md-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="homeHealthRepresentativeNursAide"
                  id="homeHealthRepresentativeNursAide"
                >
                  <FormControlLabel
                    onChange={handleChange}
                    value={true}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['homeHealthRepresentativeNursAide'] === null
                            ? false
                            : formData['homeHealthRepresentativeNursAide']
                            ? false
                            : true
                        }
                      />
                    }
                    label={_resources.MedicarePatient.YES}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    value={false}
                    control={
                      <Radio
                        disabled={
                          window.location.href.includes('review-form')
                            ? true
                            : false
                        }
                        checked={
                          formData['homeHealthRepresentativeNursAide'] === null
                            ? false
                            : !formData['homeHealthRepresentativeNursAide']
                            ? false
                            : true
                        }
                      />
                    }
                    label={_resources.MedicarePatient.NO}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="icon-text">
              <img src={infoIcon} alt="Info Icon" />
              <span className="consent-para">
                {_resources.MedicarePatient.IF_YOU_ANS_YES}
              </span>
            </div>
            {window.location.href.includes('medicare-patient') ? (
              <Button
                className="dual-book-button continue-btn"
                variant="contained"
                color="primary"
                type="submit"
                size="large"
              >
                {_resources.MedicarePatient.CONTINUE_TO_REVIEW_FORM}
              </Button>
            ) : null}
          </form>
        </div>
      ) : (
        <>
          <Header />
          <Helmet>
            <title>CORA Health | Appointment Submitted</title>
          </Helmet>

          <div className="checkin-page-carousel d-flex justify-content-between medicare-patient-data">
            <div className="col-lg-7 medicare-patient-form">
              <div className="signature-heading d-flex align-items-start">
                <img
                  onClick={() => window.history.back()}
                  className="back-arrow mr-2 mt-2"
                  src={backArrow}
                  alt="date"
                />
                <p className="signature">
                  {_resources.ReviewPage.MEDICARE_FORM}
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="medicare-patient-text">
                  <p className="eligibility-text">
                    {_resources.MedicarePatient.MEDICARE_HEADING_1}
                  </p>
                  <p className="representative-text">
                    {_resources.MedicarePatient.MEDICARE_HEADING_2}
                  </p>
                </div>

                <div className="medical-heading">
                  <p>
                    {
                      _resources.MedicarePatient
                        .PHYSICAL_OCCUPATIONAL_OR_SPEECH_THERAPY
                    }
                  </p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="physicalOccupationalSpeechThreapy"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['physicalOccupationalSpeechThreapy'] ===
                              null
                                ? false
                                : formData['physicalOccupationalSpeechThreapy']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.YES}
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['physicalOccupationalSpeechThreapy'] ===
                              null
                                ? false
                                : !formData['physicalOccupationalSpeechThreapy']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.NO}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="medical-heading">
                  <p>{_resources.MedicarePatient.WOUND_CARE}</p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="woundCare"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['woundCare'] === null
                                ? false
                                : formData['woundCare']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.YES}
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['woundCare'] === null
                                ? false
                                : !formData['woundCare']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.NO}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="medical-heading">
                  <p>{_resources.MedicarePatient.INJECTIONS_OR_MEDICATIONS}</p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="injectionsMedications"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['injectionsMedications'] === null
                                ? false
                                : formData['injectionsMedications']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.YES}
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['injectionsMedications'] === null
                                ? false
                                : !formData['injectionsMedications']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.NO}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="medical-heading">
                  <p>{_resources.MedicarePatient.BATHING_OR_PERSONAL_CARE}</p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="bathingPersonalCare"
                      id="bathingPersonalCare"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['bathingPersonalCare'] === null
                                ? false
                                : formData['bathingPersonalCare']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.YES}
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['bathingPersonalCare'] === null
                                ? false
                                : !formData['bathingPersonalCare']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.NO}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="medical-heading">
                  <p>{_resources.MedicarePatient.IV_CARE}</p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="ivCare"
                      id="ivCare"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['ivCare'] === null
                                ? false
                                : formData['ivCare']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.YES}
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['ivCare'] === null
                                ? false
                                : !formData['ivCare']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.NO}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="medical-heading">
                  <p>{_resources.MedicarePatient.HOME_HEALTH_REPRESENTATIVE}</p>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="homeHealthRepresentativeNursAide"
                      id="homeHealthRepresentativeNursAide"
                    >
                      <FormControlLabel
                        onChange={handleChange}
                        value={true}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['homeHealthRepresentativeNursAide'] ===
                              null
                                ? false
                                : formData['homeHealthRepresentativeNursAide']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.YES}
                      />
                      <FormControlLabel
                        onChange={handleChange}
                        value={false}
                        control={
                          <Radio
                            disabled={
                              window.location.href.includes('review-form')
                                ? true
                                : patientForm['updateEditButtonForNewPatient']
                                ? false
                                : patientForm['updateEditButtonExistingPatient']
                                ? false
                                : true
                            }
                            checked={
                              formData['homeHealthRepresentativeNursAide'] ===
                              null
                                ? false
                                : !formData['homeHealthRepresentativeNursAide']
                                ? true
                                : false
                            }
                          />
                        }
                        label={_resources.MedicarePatient.NO}
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div className="icon-text">
                  <img src={infoIcon} alt="Info Icon" />
                  <span className="consent-para">
                    {_resources.MedicarePatient.IF_YOU_ANS_YES}
                  </span>
                </div>
                {window.location.href.includes('medicare-patient') ? (
                  <>
                    <Button
                      className="dual-book-button continue-btn"
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                    >
                      {_resources.MedicarePatient.CONTINUE_TO_REVIEW_FORM}
                    </Button>
                    <Button
                      size="large"
                      id="back-btn"
                      onClick={() => window.history.back()}
                      className="dual-book-button back-button"
                      variant="contained"
                    >
                      {_resources.MedicarePatient.BACK}
                    </Button>
                  </>
                ) : null}
              </form>
            </div>

            <div className="col-lg-5 p-0 carousel-column">
              <Carousel>
                <div>
                  <div className="clinic-pic-one"></div>
                </div>
                <div>
                  <div className="clinic-pic-two"></div>
                </div>
                <div>
                  <div className="clinic-pic-three"></div>
                </div>
                <div>
                  <div className="clinic-pic-four"></div>
                </div>
              </Carousel>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default MedicarePatient;
