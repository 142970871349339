import { combineReducers } from "redux";
import searchClinics from "./searchClinics";
import insuranceTypes from "./insuranceTypeValues";
import clinicList from "./clinicList";
import therapyTypes from "./therapyTypeValues";
import uploadDocments from "./uploadDocuments";
import form from "./form";
import bookAppointmentId from "./bookAppointmentId";
import patientForm from "./patientForm";
import multipleUploadDocument from "./multipleUploadDocument";
import auth from "./auth";
import confirmedAppointment from "./confirmedAppointment";

const reducers = combineReducers({
  clinics: searchClinics,
  insuranceTypes: insuranceTypes,
  clinicList: clinicList,
  therapyTypes: therapyTypes,
  documents: uploadDocments,
  form: form,
  bookAppointmentId: bookAppointmentId,
  patientForm: patientForm,
  multipleUploadDocument: multipleUploadDocument,
  auth: auth,
  confirmedAppointment: confirmedAppointment,
});

export default reducers;
