const initialState = {
  authenticated: false,
  clinicUrl: ""
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "setAuth":
      return {
        ...state,
        authenticated: payload,
      };

      case "setClinicUrl":
        return {
          ...state,
          clinicUrl: payload,
        };

    default:
      return state;
  }
};

export default reducer;
