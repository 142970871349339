/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PatientInformationComponent from './patientInformationComponent';
import MedicalHistoryComponent from './medicalHistoryComponent';
import UploadDocumentComponent from './uploadDocumentComponent';
import { Steps, Button, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { actionCreators } from '../state';
import { Spin, Carousel } from 'antd';
import errorImage from './../assets/img/error-image.svg';
import Header from './headerPhase2';
import { resources } from '../resource';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import validateDate from '../utils/validateDate';
import moment from 'moment';

const { Step } = Steps;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    textAlign: 'center',
  },
  imgBox: {
    maxWidth: '80%',
    maxHeight: '80%',
    margin: '10px',
  },
  img: {
    height: 'inherit',
    maxWidth: 'inherit',
  },
  input: {
    display: 'none',
  },
}));
const PatientForm = (props) => {
  const location = useLocation();
  const [showLoader, setShowLoader] = React.useState(false);
  const clinicUrl = useSelector((state) => state.auth.clinicUrl);
  const [current, setCurrent] = useState(location?.state?.fromReview ? 2 : 0);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [invalidCurrentDate, setInvalidCurrentDate] = useState(false);
  const [verifyCall, setVerifyCall] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [verifiedResponse, setVerifiedResponse] = useState({});
  const [screen, setScreen] = useState();
  const [spinner, setSpinner] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [verifyUploadDocumentPageModal, setVerifyUploadDocumentPageModal] =
    useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [documentRequired, setDocumentRequired] = useState(false);
  const [verifiedDataByAppointmentId, setVerifiedDataByAppointmentId] =
    useState('');

  let languageCopy = props?.language;

  if (
    Object.keys(location).includes('state') &&
    location.state !== null &&
    location.state.language === 'spa'
  ) {
    languageCopy = 'spa';
  }
  const _resources = resources[languageCopy];

  const handleCancel = () => {
    setVerifyUploadDocumentPageModal(false);
    redirectHome();
  };

  const verifyRequest = async (event) => {
    event.preventDefault();
    setVerifyCall(true);
    if (currentDate == null || invalidCurrentDate) {
      return;
    }

    const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

    let url = new URL(window.location.href);
    let encryptedId = url.searchParams.get('appointmentGuid');

    try {
      setShowLoader(true);
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const responseAPI = await fetch(
        `${REACT_APP_CORA_API_URL}PatientCheckIn/ValidateAppointment`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            dob: moment(currentDate.$d).format('MM-DD-YYYY'),
            appointmentGUID: encryptedId,
          }),
        }
      );

      const response = await responseAPI.json();

      if (response.responseCode === 200) {
        if (response.message == 'True') {
          let message = {
            type: 'Success',
            message: _resources.patientForm.SUCCESS,
          };

          setVerifiedResponse(message);

          setTimeout(() => {
            verifyGuid();
          }, 1500);
        } else if (response.message == 'False') {
          let message = {
            type: 'Error',
            message: _resources.patientForm.DANGER,
          };
          setVerifiedResponse(
            message + `${process.env.REACT_APP_PHONE_NUMBER}`
          );
          setShowLoader(false);
        }
      } else {
        throw Error('Error message');
      }

      clearTimeout(id);
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  const redirectHome = () => {
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
  };

  useEffect(() => {
    localStorage.clear();
    window.scrollTo(0, 0);
    if (location.state == null) {
      if (clinicUrl && clinicUrl != '') {
        window.location.replace(clinicUrl);
      }
    }
    window.history.replaceState(null, '');
  }, []);

  useEffect(() => {
    if (window.location.href.includes('appointmentGuid')) {
      let url = new URL(window.location.href);
      let id = url.searchParams.get('appointmentGuid');

      if (id == '') {
        setInvalidUrl(true);
      } else {
        setShowDateModal(true);
      }
    }
  }, []);

  async function verifyGuid() {
    const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

    if (window.location.href.includes('appointmentGuid')) {
      var url = new URL(window.location.href);
      if (!url.href.includes('?appointmentGuid=')) {
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
      } else {
        //check if SMS url is valid
        setSpinner(true);
        var encryptedId = url.searchParams.get('appointmentGuid');

        try {
          const controller = new AbortController();
          const id = setTimeout(
            () => controller.abort(),
            process.env.REACT_APP_API_TIMEOUT
          );
          const responseAPI = await fetch(
            `${REACT_APP_CORA_API_URL}Appointment/VerifyAppointmentId`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify({ bookAppointmentId: encryptedId }),
            }
          );
          clearTimeout(id);

          const response = await responseAPI.json();

          actions.updateEditButtonForNewPatient(true);
          let clinicId =
            response.verifyAppointment.aptID !== 0 &&
            response.verifyAppointment.clinicId?.replaceAll(' ', '%20');

          clinicId =
            process.env.REACT_APP_WEBSITE_URL +
            '/patient-checkin/checkin?clinicId=' +
            clinicId;
          actions.newPatientValidation(response.verifyAppointment);

          actions.updateClinicName(clinicId);

          if (response.responseCode === 200) {
            setShowLoader(false);
            setShowDateModal(false);
            if (response.verifyAppointment.aptID === 0) {
              setVerifyUploadDocumentPageModal(true);
              setResponseMessage(response.verifyAppointment.msg);
            } else {
              setVerifiedDataByAppointmentId(response.verifyAppointment);
            }
          } else {
            throw Error('Error message');
          }
          setSpinner(false);
        } catch (error) {
          setApiErrorMessage(true);
          setSpinner(false);
        }
      }
    }
  }

  const verifiedData = location.state;

  const classes = useStyles();
  const documents = useSelector((state) => state.documents);
  const patientForm = useSelector((state) => state.patientForm);

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    isMobileDevice();
  }, []);

  useEffect(() => {
    checkNextButtons();
  }, [patientForm]);

  const checkNextButtons = () => {
    if (patientForm.patientFormNextButton && current === 0) {
      setCurrent(current + 1);
    }
    if (patientForm.medicalFormNextButton && current === 1) {
      setCurrent(current + 1);
    }
    if (
      patientForm.medicalFormNextButton &&
      patientForm.patientFormNextButton &&
      current === 0
    ) {
      setCurrent(current + 2);
    }
  };

  function isMobileDevice() {
    if (window.innerWidth <= 1024) {
      setScreen('mobile');
    } else {
      setScreen('desktop');
    }
  }

  const prev = () => {
    if (current === 1 && !patientForm.existingPatientWithAppointment) {
      actions.patientFormNextButton(false);
      actions.medicalFormNextButton(false);
      setCurrent(current - 1);
    } else if (current === 2 && !patientForm.existingPatientWithAppointment) {
      actions.medicalFormNextButton(false);
      setCurrent(current - 1);
    } else {
      setCurrent(current - 2);
    }
  };

  const steps = [
    {
      title: _resources.ReviewPage.PATIENT_INFORMATION,
      content: (
        <>
          <PatientInformationComponent
            classes={classes}
            screen={screen}
            title={_resources.ReviewPage.LETTER_OF_EXC_COPY}
            verifiedData={
              window.location.href.includes('appointmentGuid')
                ? verifiedDataByAppointmentId
                : verifiedData
            }
            language={languageCopy}
          />
        </>
      ),
    },
    {
      title: _resources.ReviewPage.MEDICAL_INFORMATION,
      content: <MedicalHistoryComponent prev={prev} language={languageCopy} />,
    },
    {
      title: _resources.ReviewPage.UPLOAD_DOCS,
      content: (
        <>
          <UploadDocumentComponent
            classes={classes}
            screen={screen}
            title={_resources.ReviewPage.UPLOAD_WORD}
            type={_resources.ReviewPage.PRESCRIPTION}
            language={languageCopy}
            prev={prev}
          />
        </>
      ),
    },
  ];

  // const next = () => {
  //   setCurrent(current + 1);
  // };

  const list = [];

  if (Object.keys(documents).includes('imageList')) {
    if (documents.imageList.length > 0) {
      documents && documents?.imageList.map((item) => list.push(item.imageKey));
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        visible={invalidUrl}
        className="exception-modal"
        onCancel={() => redirectHome()}
        footer={null}
        centered
        maskClosable={false}
      >
        <div className="modal-content">
          <span className="exception-modal-text">
            {_resources.patientForm.INVALID_URL}
          </span>
        </div>
      </Modal>
      <Modal
        visible={showDateModal}
        className="exception-modal date-modal"
        onCancel={() => redirectHome()}
        footer={null}
        maskClosable={false}
        centered
      >
        <div className="modal-content">
          <form onSubmit={verifyRequest}>
            <label
              htmlFor=""
              style={{
                fontSize: '19px',
                fontWeight: '500',
                marginBottom: '1rem',
              }}
            >
              {_resources.patientForm.ENTER_BIRTH}
            </label>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={_resources.patientForm.SELECT_DATE}
                value={currentDate}
                onChange={(newValue) => {
                  setCurrentDate(newValue);
                  if (newValue?.$d != null) {
                    const dateValidated = validateDate(newValue.$d);

                    setInvalidCurrentDate(dateValidated);
                  }
                }}
                disableFuture
                className="w-100"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={invalidCurrentDate}
                    helperText={invalidCurrentDate && 'Invalid Date'}
                  />
                )}
              />
            </LocalizationProvider>
            {invalidCurrentDate && <p class="mb-0 text-danger">Invalid Date</p>}
            <button
              type="submit"
              className={
                currentDate == null || invalidCurrentDate
                  ? 'verify-btn-disabled'
                  : 'verify-btn'
              }
              disabled={currentDate == null || invalidCurrentDate}
            >
              {_resources.patientForm.VERIFY}
            </button>
            {verifiedResponse && (
              <p
                className={
                  verifiedResponse.type == 'Success'
                    ? 'text-success mt-1 d-none'
                    : 'text-danger mt-1'
                }
              >
                {verifiedResponse.message}
              </p>
            )}
          </form>
        </div>
      </Modal>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            Something went wrong. Please try again later.
          </span>
        </div>
      </Modal>
      <Modal
        visible={documentRequired}
        className="no-slot-modal"
        onCancel={() => setDocumentRequired(false)}
        onOk={() => setDocumentRequired(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <span className="slot-booked-text">
            At least one document is required
          </span>
        </div>
      </Modal>
      {window.location.href.includes('?appointmentGuid=') && spinner ? (
        <div
          className={
            invalidUrl || showDateModal ? 'd-none verification' : 'verification'
          }
        >
          <Spin
            className="mr-3 search-clinic-btn-loader"
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
          />
          <span>Please wait</span>
        </div>
      ) : (
        <>
          <Modal
            visible={verifyUploadDocumentPageModal}
            className="no-slot-modal"
            onCancel={handleCancel}
            onOk={() => setVerifyUploadDocumentPageModal(false)}
            footer={null}
            centered
          >
            <div className="modal-content">
              <span className="slot-booked-text">{responseMessage}</span>
            </div>
          </Modal>
          {!verifyUploadDocumentPageModal && (
            <>
              <div className="appointment-booked-page">
                <Helmet>
                  <title>CORA Health | Patient Form</title>
                </Helmet>
                <Header />
              </div>

              <div className="content-wrapper-row row no-gutters patient-form-data medical-history-form-data">
                <div className="col-lg-7 patient-history-data">
                  <div className="content-card ">
                    <Steps
                      responsive={false}
                      labelPlacement="vertical"
                      current={current}
                    >
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                    <div>{steps[current].content}</div>
                    <div className="back-button">
                      {current > 0 && (
                        <Button
                          size="large"
                          id="back-btn"
                          style={{ margin: '0 8px' }}
                          onClick={() => prev()}
                          className="dual-book-button"
                          variant="contained"
                          // color="primary"
                          // type="submit"
                          // size="large"
                        >
                          {_resources.ReviewPage.BACK}
                        </Button>
                      )}
                      {/* {current === 1 && (
                            <Button
                              type="primary"
                              size="large"
                              id="next-btn"
                              // disabled={insuranceNextButton}
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          )}
                          {current === 0 && (
                            <Button
                              type="primary"
                              size="large"
                              id="next-btn"
                              // disabled={idNextButton}
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          )} */}

                      {/* {current === steps.length - 1 && (
                            <Button
                              type="primary"
                              size="large"
                              id="next-details-btn"
                              // disabled={nextPrescription}
                              onClick={checkifEmptyDocuments}
                            >
                              Next, Add Details
                            </Button>
                          )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5  carousel-column">
                  <Carousel initialSlide={Math.floor(Math.random() * 4)}>
                    <div>
                      <div className="clinic-pic-one"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-two"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-three"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-four"></div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PatientForm;
