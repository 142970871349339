/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IdDocumentsComponent from "./idDocumentsComponent";
import InsuranceDocumentsComponent from "./insuranceDocumentsComponent";
import PresciptionDocumentsComponent from "./presciptionDocumentsComponent";
import { Steps, Button, Modal } from "antd";
import { bindActionCreators } from "redux";
import { LoadingOutlined } from "@ant-design/icons";
import { actionCreators } from "../state";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Spin, Carousel } from "antd";
import handIcon from "./../assets/img/hand-icon.svg";
import dateIcon from "./../assets/img/date-icon.svg";
import locationIcon from "./../assets/img/location-pin-icon-blue.svg";
import errorImage from "./../assets/img/error-image.svg";
import Header from "./header";
import { setDate } from "date-fns/esm";
import moment from "moment";
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Step } = Steps;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    textAlign: "center",
  },
  imgBox: {
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "10px",
  },
  img: {
    height: "inherit",
    maxWidth: "inherit",
  },
  input: {
    display: "none",
  },
}));

const UploadDocuments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [briefDetailsTextBox, setBriefDetailsTextBox] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [brifeDetails, setBriefDetails] = useState("");
  const [screen, setScreen] = useState();
  const [spinner, setSpinner] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");
  const [bookAppointmentId, setBookAppointmentId] = useState();
  const [verifyUploadDocumentPageModal, setVerifyUploadDocumentPageModal] =
    useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [documentRequired, setDocumentRequired] = useState(false);
  const [therapyName, setTherayName] = useState("");
  const [verificationCallFlag, setVerificationFlag] = useState(false);
  const [clinicAddress, setClinicAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    validateUploadDocumentsPage();
  }, []);

  //format dates from API
  const formatDate = (date1) => {
    let date = date1.slice(0, 10);
    let monthDate = moment(date).format("MMMM-DD").replaceAll("-", " ");
    setDate(monthDate + ", " + date.slice(6, 10));
  };

  async function validateUploadDocumentsPage() {
    if (!window.location.href.includes("Id")) {
      if (location.state === null) {
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
      }
    } else {
      var url = new URL(window.location.href);
      if (!url.href.includes("?Id=")) {
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
      } else {
        //check if SMS url is valid
        setVerificationFlag(true);
        var encryptedId = url.searchParams.get("Id");

        try {
          const controller = new AbortController();
          const id = setTimeout(
            () => controller.abort(),
            process.env.REACT_APP_API_TIMEOUT
          );
          const responseAPI = await fetch(
            `${REACT_APP_CORA_API_URL}Appointment/VerifyAppointmentId`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({ bookAppointmentId: encryptedId }),
            }
          );
          clearTimeout(id);

          const response = await responseAPI.json();
          if (response.responseCode === 200) {
            if (response.verifyAppointment.aptID === 0) {
              setVerifyUploadDocumentPageModal(true);
              setResponseMessage(response.verifyAppointment.msg);
            } else {
              setTherayName(response.verifyAppointment.typeOfTherapy);
              setClinicAddress(response.verifyAppointment.clinicAddress);
              formatDate(response.verifyAppointment.apptDateTime);
              setTime(response.verifyAppointment.apptDateTime.slice(11, 19));
              setBookAppointmentId(response.verifyAppointment.aptID);
            }
          } else {
            throw Error("Error message");
          }
          setSpinner(false);
        } catch (error) {
          setApiErrorMessage(true);
          setSpinner(false);
        }
      }
    }
  }

  const handleCancel = () => {
    setVerifyUploadDocumentPageModal(false);
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
  };

  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

  const classes = useStyles();
  const documents = useSelector((state) => state.documents);
  const bookappointmentId = useSelector(
    (state) => state.bookAppointmentId
  ).payload;

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    isMobileDevice();
  }, []);

  const handleChangeBriefDetails = (e) => {
    setBriefDetails(e.target.value);
  };

  function isMobileDevice() {
    if (window.innerWidth <= 1024) {
      setScreen("mobile");
    } else {
      setScreen("desktop");
    }
  }

  const steps = [
    {
      title: "Upload ID Card ",
      content: (
        <>
          <IdDocumentsComponent
            classes={classes}
            screen={screen}
            title={"Upload Front Side"}
            type={"IdCard"}
          />
          <IdDocumentsComponent
            classes={classes}
            screen={screen}
            title={"Upload Back Side"}
            type={"IdCard"}
          />
        </>
      ),
    },
    {
      title: "Insurance Card",
      content: (
        <>
          <InsuranceDocumentsComponent
            classes={classes}
            screen={screen}
            title={"Upload Front Side"}
            type={"InsuranceCard"}
          />
          <InsuranceDocumentsComponent
            classes={classes}
            screen={screen}
            title={"Upload Back Side"}
            type={"InsuranceCard"}
          />
        </>
      ),
    },
    {
      title: "Doctor’s Prescription",
      content: (
        <>
          <PresciptionDocumentsComponent
            classes={classes}
            screen={screen}
            title={"Upload Document"}
            type={"Prescription"}
          />
        </>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = async () => {
    const idCardFront = documents.imageList.find(
      (item) => item.imageKey === "IdCard-Upload-Front-Side"
    );
    const idCardBack = documents.imageList.find(
      (item) => item.imageKey === "IdCard-Upload-Back-Side"
    );

    const insCardFront = documents.imageList.find(
      (item) => item.imageKey === "InsuranceCard-Upload-Front-Side"
    );
    const insCardBack = documents.imageList.find(
      (item) => item.imageKey === "InsuranceCard-Upload-Back-Side"
    );

    const prescriptionDocument = documents.imageList.find(
      (item) => item.imageKey === "Prescription-Upload-Document"
    );

    const payload = {
      bookAppointmentId:
        bookappointmentId !== undefined
          ? bookappointmentId
          : window.location.href.includes("?Id=")
          ? bookAppointmentId
          : "",

      idCardFrontSide:
        idCardFront?.imageUrl === undefined ? "" : idCardFront?.imageUrl,
      idCardFrontSideExtension:
        idCardFront?.extension === undefined ? "" : idCardFront?.extension,

      idCardBackSide:
        idCardBack?.imageUrl === undefined ? "" : idCardBack?.imageUrl,
      idCardBackSideExtension:
        idCardBack?.extension === undefined ? "" : idCardBack?.extension,

      insuranceCardFrontSide:
        insCardFront?.imageUrl === undefined ? "" : insCardFront?.imageUrl,
      insuranceCardFrontSideExtension:
        insCardFront?.extension === undefined ? "" : insCardFront?.extension,

      insuranceCardBackSide:
        insCardBack?.imageUrl === undefined ? "" : insCardBack?.imageUrl,
      insuranceCardBackSideExtension:
        insCardBack?.extension === undefined ? "" : insCardBack?.extension,

      doctorPrescription:
        prescriptionDocument?.imageUrl === undefined
          ? ""
          : prescriptionDocument?.imageUrl,
      doctorPrescriptionExtension:
        prescriptionDocument?.extension === undefined
          ? ""
          : prescriptionDocument?.extension,
      brifeDetails: brifeDetails === undefined ? "" : brifeDetails,
    };

    //upload-documents
    try {
      setSubmitLoader(true);

      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );
      const response = await fetch(
        `${REACT_APP_CORA_API_URL}Appointment/UploadAdditionalDocument`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        }
      );
      clearTimeout(id);

      const updatedResponse = await response.json();

      if (!Object.keys(updatedResponse).includes("errors")) {
        setSubmitLoader(false);
        actions.clearState([]);

        actions.clearBookAppointmentId("");
        navigate("/appointment-submitted");
      } else {
        throw Error(updatedResponse.title);
      }
    } catch (error) {
      setSubmitLoader(false);
      setApiErrorMessage(true);
    }
  };

  const list = [];

  if (Object.keys(documents).includes("imageList")) {
    if (documents.imageList.length > 0) {
      documents && documents?.imageList.map((item) => list.push(item.imageKey));
    }
  }

  //At least one document should be there
  const checkifEmptyDocuments = () => {
    if (documents.imageList.length !== 0) {
      setBriefDetailsTextBox(true);
    } else {
      setDocumentRequired(true);
    }
  };

  return (
    <>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            Something went wrong. Please try again later.
          </span>
        </div>
      </Modal>
      <Modal
        visible={documentRequired}
        className="no-slot-modal"
        onCancel={() => setDocumentRequired(false)}
        onOk={() => setDocumentRequired(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <span className="slot-booked-text">
            At least one document is required
          </span>
        </div>
      </Modal>
      {window.location.href.includes("?Id=") && spinner ? (
        <div className="verification">
          <Spin
            className="mr-3 search-clinic-btn-loader"
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
          />
          <span>Please wait while we are verifying your request</span>
        </div>
      ) : (
        <>
          <Modal
            visible={verifyUploadDocumentPageModal}
            className="no-slot-modal"
            onCancel={handleCancel}
            onOk={() => setVerifyUploadDocumentPageModal(false)}
            footer={null}
            centered
          >
            <div className="modal-content">
              <span className="slot-booked-text">{responseMessage}</span>
            </div>
          </Modal>
          {!verifyUploadDocumentPageModal && (
            <>
              <div className="appointment-booked-page">
                <Helmet>
                  <title>CORA Health | Upload Documents</title>
                </Helmet>
                <Header />
              </div>

              <div className="content-wrapper-row row no-gutters upload-steps">
                <div className="col-lg-7">
                  <div className="appointment-info-container">
                    <div className="appointment">
                      <div className="appointment-icon">
                        <img src={handIcon} alt="Appointment-display" />
                      </div>
                      <div className="appointment-text-info">
                        <span className="appointment-title">
                          Appointment For
                        </span>
                        <span className="appointment-value">
                          {verificationCallFlag
                            ? therapyName
                            : location && location.state?.therapyName}
                        </span>
                      </div>
                    </div>
                    <div className="clinic-address">
                      <div className="address-icon">
                        <img src={locationIcon} alt="Address Icon" />
                      </div>
                      <div className="address-text">
                        <span className="address-title">Clinic Address</span>
                        <span className="address-value">
                          {verificationCallFlag
                            ? clinicAddress
                            : location && location.state?.clinicAddress}
                        </span>
                      </div>
                    </div>
                    <div className="date-n-time">
                      <div className="date-icon">
                        <img src={dateIcon} alt="Date Icon" />
                      </div>
                      <div className="date-text">
                        <span className="date-title">Date &amp; Time</span>
                        <span className="date-value">
                          {verificationCallFlag
                            ? date
                            : location && location.state?.date}{" "}
                          {" at "}
                          {verificationCallFlag
                            ? time
                            : location && location.state?.time}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="content-card ">
                    {!briefDetailsTextBox && (
                      <>
                        <Steps current={current}>
                          {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                          ))}
                        </Steps>
                        <div className="steps-content">
                          {steps[current].content}
                        </div>
                        <div className="steps-action">
                          {current > 0 && (
                            <Button
                              size="large"
                              id="back-btn"
                              style={{ margin: "0 8px" }}
                              onClick={() => prev()}
                            >
                              Back
                            </Button>
                          )}
                          {current === 1 && (
                            <Button
                              type="primary"
                              size="large"
                              id="next-btn"
                              // disabled={insuranceNextButton}
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          )}
                          {current === 0 && (
                            <Button
                              type="primary"
                              size="large"
                              id="next-btn"
                              // disabled={idNextButton}
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          )}

                          {current === steps.length - 1 && (
                            <Button
                              type="primary"
                              size="large"
                              id="next-details-btn"
                              // disabled={nextPrescription}
                              onClick={checkifEmptyDocuments}
                            >
                              Next, Add Details
                            </Button>
                          )}
                        </div>
                      </>
                    )}

                    {briefDetailsTextBox && (
                      <div className="textarea-wrapper">
                        <TextareaAutosize
                          aria-label="minimum height"
                          maxRows={3}
                          name="breifDetails"
                          onChange={handleChangeBriefDetails}
                          placeholder="Enter brief details"
                          style={{ width: 200 }}
                          minLength={30}
                          maxLength={350}
                        />

                        <Button
                          size="large"
                          onClick={onSubmit}
                          className="submit-textarea"
                          id="submit-btn"
                        >
                          {" "}
                          {submitLoader && (
                            <Spin
                              className="mr-3 search-clinic-btn-loader"
                              indicator={antIcon}
                            />
                          )}
                          Submit
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-5  carousel-column">
                  <Carousel initialSlide={Math.floor(Math.random() * 4)}>
                    <div>
                      <div className="clinic-pic-one"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-two"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-three"></div>
                    </div>
                    <div>
                      <div className="clinic-pic-four"></div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UploadDocuments;
