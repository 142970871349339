const initialState = {
  bookappointmentId: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "setBookAppointmentId":
      return {
        payload,
      };

    case "clearBookAppointmentId":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
