/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal, Carousel, Tooltip } from "antd";
import moment from "moment";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./header";
import clinicLocIcon from "./../assets/img/loc-icon-details-page.svg";
import clinicPicture1 from "./../assets/img/420x200-CORA-clinic1.jpg";
import clinicPicture2 from "./../assets/img/420x200-CORA-clinic2.jpg";
import clinicPicture3 from "./../assets/img/420x200-CORA-treatment1.jpg";
import clinicPicture4 from "./../assets/img/420x200-CORA-treatment2.jpg";
import nearByIcon from "./../assets/img/nearby-icon-details-page.svg";
import phoneIcon from "./../assets/img/phone-icon.svg";
import noSlotImg from "./../assets/img/no-slot-icon.svg";
import Slider from "react-slick";
import clinicServices from "./../assets/img/clinicServices.png";

function ClinicDetail() {
  const location = useLocation();
  let stateFromBookingPage = location.state;

  if (stateFromBookingPage === null) {
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
  }

  const { appointmentDates, selectedClinic, formData, clinicData } =
    stateFromBookingPage;

  const [appointmentTime, setAppointmentTime] = useState("");
  const [bookAppointment, setBookAppointment] = useState(true);
  const [selectedDate, setSelectedDate] = useState();
  const [appointmentSlotBookModal, setAppointmentSlotBookModal] =
    useState(false);

  const standardDataFormat = "YYYY-MM-DD";

  const {
    clinicName,
    clinicAddress,
    clinicPhone,
    clinicHours,
    mondayStartTime,
    mondayCloseTime,
    tuesdayCloseTime,
    tuesdayStartTime,
    wednesdayStartTime,
    wednesdayCloseTime,
    thrusdayStartTime,
    thrusdayCLoseTime,
    fridayStartTime,
    fridayCloseTime,
    saturDayCLoseTime,
    saturdayStartTime,
    sundayCloseTime,
    sundayStartTime,
    webUrl,
    areCode,
    lccity,
    lcstate,
    lczip,
  } = stateFromBookingPage["selectedClinic"];

  //Format phone number
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const selectAppointmentTime = (appointmentTimeObject) => {
    if (appointmentTimeObject.isAvailable) {
      if (
        appointmentTime !== "" &&
        appointmentTimeObject.startTime === appointmentTime
      ) {
        setAppointmentTime("");
        setBookAppointment(true);
      } else {
        setAppointmentTime(appointmentTimeObject.startTime);
        setBookAppointment(false);
      }
    } else {
      setAppointmentSlotBookModal(true);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const clinicsSearchFields = useSelector((state) => state.clinics);
  // constclinicData = useSelector((state) => state.clinicDaclinicData);

  useEffect(() => {
    if (Object.keys(stateFromBookingPage).length !== 0) {
      let currentDate =
        stateFromBookingPage && stateFromBookingPage["selectedDate"];
      let formattedCurrentDate = moment(currentDate).format(standardDataFormat);
      setSelectedDate(formattedCurrentDate);
    }
  }, []);

  //get the updated date and update in redux state
  function onChangeDate(date) {
    //updating searchClinic Fields
    clinicsSearchFields.appointmentDate = date;
    actions.searchClinics(clinicsSearchFields);
    let formattedDate = moment(date).format(standardDataFormat);

    //update the current date
    setSelectedDate(formattedDate);
    setAppointmentTime("");
    setBookAppointment(true);
  }

  function bookAppiontmentButton() {
    navigate("/confirm-appointment", {
      state: {
        clinicData: stateFromBookingPage.selectedClinic,
        appointmentTime: {
          startTime: appointmentTime,
          date: selectedDate,
          clinicId: stateFromBookingPage.selectedClinic.clinicId,
        },
      },
    });
  }

  //date slider setting
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  //timeFormatter
  const timeFormatter = (startTime) => {
    var timesplit = startTime.split(".");
    var hours = timesplit[0];
    var minutes = timesplit[1].slice(0, 2);

    if (timesplit[0].length === 1) {
      hours = "0" + timesplit[0];
    }
    if (timesplit[1].length === 1) {
      minutes = timesplit[1] + "0";
    }

    startTime = hours + "." + minutes;
    let formattedTime;

    if (parseInt(startTime) > 12) {
      var timesplit1 = startTime.split(".");
      var hours1 = timesplit1[0];
      var minutes1 = timesplit1[1].slice(0, 2);

      if (timesplit1[1].length === 1) {
        minutes1 = timesplit1[1] + "0";
      }

      hours1 = hours1 - 12;
      hours1 = "" + hours1;

      if (hours1.length === 1) {
        hours1 = "0" + hours1;
      }

      formattedTime = hours1 + "." + minutes1 + " pm";
    } else if (parseInt(startTime) === 12) {
      formattedTime = startTime + " pm";
    } else {
      formattedTime = startTime + " am ";
    }
    return formattedTime.replaceAll(".", ":");
  };

  //formatDates
  let formattedAppointmentDates = [];
  if (appointmentDates.length !== 0) {
    appointmentDates.map((item) => {
      let date = item.date.slice(0, 10);
      let normalDate = moment(item.date.slice(0, 10)).format("YYYY-MM-DD_");
      let weekDay = moment(date).day();

      switch (weekDay) {
        case 0:
          weekDay = "Sun-";
          break;
        case 1:
          weekDay = "Mon-";
          break;
        case 2:
          weekDay = "Tue-";
          break;
        case 3:
          weekDay = "Wed-";
          break;
        case 4:
          weekDay = "Thu-";
          break;
        case 5:
          weekDay = "Fri-";
          break;
        case 6:
          weekDay = "Sat-";
          break;
        default:
          break;
      }

      let monthDate = moment(date).format("MMMM-DD");
      formattedAppointmentDates.push([normalDate] + weekDay + monthDate);
    });
  }

  let appointmentDate = new Date();
  appointmentDate.setDate(appointmentDate.getDate());

  return (
    <>
      <Modal
        visible={appointmentSlotBookModal}
        className="no-slot-modal"
        onCancel={() => setAppointmentSlotBookModal(false)}
        onOk={() => setAppointmentSlotBookModal(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img className="no-slot-img" src={noSlotImg} alt="Slot Booked" />
          <span className="slot-booked-text">This slot is already booked.</span>

          <Button
            type="primary"
            className="select-different-time-btn"
            id="select-different-slot"
            onClick={() => setAppointmentSlotBookModal(false)}
          >
            <span> Please select a different time.</span>
          </Button>
        </div>
      </Modal>
      <div className="clinic-details">
        <Helmet>
          <title>CORA Health | Clinic Detail</title>
        </Helmet>
        <div className="page-header">
          <Header />
        </div>
        <div className="main-content-wrapper">
          <div className="details-page-mobile-carousel hidden-desktop">
            <Carousel>
              <div>
                <img alt="Clinic-display" src={clinicPicture1} />
              </div>
              <div>
                <img alt="Clinic-display" src={clinicPicture2} />
              </div>
              <div>
                <img alt="Clinic-display" src={clinicPicture3} />
              </div>
              <div>
                <img alt="Clinic-display" src={clinicPicture4} />
              </div>
            </Carousel>
            {/* <img
              className="clinic-picture"
              alt="Clinic-display"
              src={clinicPicture}
            /> */}
          </div>
          <div className="content-wrapper-row row no-gutters">
            <div className="content-card col-lg-7">
              <div className="date-slider hidden-mobile">
                <div
                  className={`parent ${
                    appointmentDates.length <= 3
                      ? "normalSlider"
                      : "SlickSlider"
                  }`}
                >
                  <Slider {...settings}>
                    {formattedAppointmentDates &&
                      formattedAppointmentDates.map((value, index) => {
                        return (
                          <div
                            key={index}
                            // key={value.slice(0, 10)}
                            className="child"
                            onClick={() => onChangeDate(value.slice(0, 10))}
                          >
                            {selectedDate === value.slice(0, 10) ? (
                              <span className="selected-date">
                                <span>{value.slice(11, 14)}</span>
                                <span className="d-block">
                                  {value
                                    .slice(15, value.length)
                                    .replaceAll("-", " ")}
                                </span>
                              </span>
                            ) : (
                              <span className="other-dates">
                                <span>{value.slice(11, 14)}</span>
                                <span className="month-n-date">
                                  {value
                                    .slice(15, value.length)
                                    .replaceAll("-", " ")}
                                </span>
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </div>
              <div className="card">
                {/* <div className="main-sections"> */}
                <div className="card-meta">
                  <div className="card-title" id="clinic-title">
                    <a target="_blank" rel="noreferrer" href={webUrl}>
                      {clinicName}
                    </a>
                  </div>
                  {/* <div className="card-subtitle">
                    <span>MDS - Periodontology and Oral Implantology, BDS</span>
                  </div> */}
                  <div className="contact-info">
                    {clinicAddress ? (
                      <div className="clinic-loc">
                        <div className="location-icon">
                          <img
                            className="ml-2"
                            src={clinicLocIcon}
                            alt="Location"
                          />
                        </div>
                        <span className="d-block">
                          {clinicAddress.trim() +
                            ", " +
                            lccity +
                            ", " +
                            lcstate +
                            ", " +
                            lczip}
                        </span>
                      </div>
                    ) : null}
                    <div className="clinic-nearby-plcs">
                      <div className="near-by-icon">
                        <img src={nearByIcon} alt="Near By" />
                      </div>
                      <span className="d-block">
                        {stateFromBookingPage &&
                          stateFromBookingPage.distanceInMiles}
                      </span>
                    </div>
                    <div className="phone-number">
                      <div className="phone-icon">
                        <img
                          src={phoneIcon}
                          alt="Phone Number"
                          className="ml-1"
                        />
                      </div>
                      <a
                        href={`tel:${areCode + clinicPhone}`}
                        className="d-block"
                      >
                        {formatPhoneNumber(areCode + clinicPhone)}
                      </a>
                    </div>

                    <div className="phone-number">
                      <div className="service-icon ml-1">
                        <img src={clinicServices} alt="Near By" />
                      </div>
                      <a
                        className="x-block"
                        target="_blank"
                        rel="noreferrer"
                        href={webUrl}
                      >
                        Clinic Services
                      </a>
                    </div>
                  </div>
                </div>

                {/* <div className="date-n-time-with-appointment-hours-section"> */}
                <div className="date-n-time-section">
                  <div className="heading">Available Appointments</div>
                  <div className="date-slider hidden-desktop">
                    <div
                      className={`parent ${
                        appointmentDates.length <= 3
                          ? "normalSlider"
                          : "SlickSlider"
                      }`}
                    >
                      <Slider {...settings}>
                        {formattedAppointmentDates &&
                          formattedAppointmentDates.map((value, index) => {
                            return (
                              <div
                                key={index}
                                // key={value.slice(0, 10)}
                                className="child"
                                onClick={() => onChangeDate(value.slice(0, 10))}
                              >
                                {selectedDate === value.slice(0, 10) ? (
                                  <span className="selected-date">
                                    <span>{value.slice(11, 14)}</span>
                                    <span className="d-block">
                                      {value
                                        .slice(15, value.length)
                                        .replaceAll("-", " ")}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="other-dates">
                                    <span>{value.slice(11, 14)}</span>
                                    <span className="month-n-date">
                                      {value
                                        .slice(15, value.length)
                                        .replaceAll("-", " ")}
                                    </span>
                                  </span>
                                )}
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>

                <div className="appointment-hours">
                  {stateFromBookingPage &&
                    selectedClinic.appointmentSlots.map((timeslot) =>
                      selectedDate === timeslot.date.slice(0, 10) ? (
                        appointmentTime === timeslot.startTime ? (
                          timeslot.isAvailable ? (
                            <a
                              className="appointment-hour-box timeslot-selected"
                              onClick={() => selectAppointmentTime(timeslot)}
                            >
                              {timeFormatter(timeslot.startTime)}
                            </a>
                          ) : (
                            <Tooltip title="Already Booked" color={"#007fa8"}>
                              <span>
                                <a
                                  className="appointment-hour-box disabled-slot"
                                  onClick={() =>
                                    selectAppointmentTime(timeslot)
                                  }
                                >
                                  {timeFormatter(timeslot.startTime)}
                                </a>
                              </span>
                            </Tooltip>
                          )
                        ) : timeslot.isAvailable ? (
                          <a
                            className="appointment-hour-box timeslot"
                            onClick={() => selectAppointmentTime(timeslot)}
                          >
                            {timeFormatter(timeslot.startTime)}
                          </a>
                        ) : (
                          <Tooltip title="Already Booked" color={"#007fa8"}>
                            <span>
                              <a
                                className="appointment-hour-box disabled-slot"
                                onClick={() => selectAppointmentTime(timeslot)}
                                title="Already Booked"
                              >
                                {timeFormatter(timeslot.startTime)}
                              </a>
                            </span>
                          </Tooltip>
                        )
                      ) : null
                    )}
                </div>

                <div className="clinic-hours">
                  <div className="heading">Clinic Hours {clinicHours}</div>
                  <div className="daily-clinic-hours">
                    <div className="monday-hours">
                      <span className="week-day">Mon -</span>
                      {mondayStartTime && mondayStartTime ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(mondayStartTime)}-{" "}
                            {timeFormatter(mondayCloseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>

                    <div className="tuesday-hours">
                      <span className="week-day">Tue -</span>
                      {tuesdayStartTime && tuesdayCloseTime ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(tuesdayStartTime)}-{" "}
                            {timeFormatter(tuesdayCloseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>

                    <div className="wednesday-hours">
                      <span className="week-day">Wed -</span>
                      {wednesdayStartTime && wednesdayCloseTime ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(wednesdayStartTime)}-{" "}
                            {timeFormatter(wednesdayCloseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>

                    <div className="thursday-hours">
                      <span className="week-day">Thu -</span>
                      {thrusdayStartTime && thrusdayCLoseTime ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(thrusdayStartTime)}-{" "}
                            {timeFormatter(thrusdayCLoseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>
                    <div className="friday-hours">
                      <span className="week-day">Fri -</span>
                      {fridayStartTime && fridayCloseTime ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(fridayStartTime)}-{" "}
                            {timeFormatter(fridayCloseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>

                    <div className="saturday-hours">
                      <span className="week-day">Sat -</span>
                      {saturdayStartTime &&
                      saturDayCLoseTime &&
                      parseInt(saturDayCLoseTime) > 0 ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(saturdayStartTime)}-{" "}
                            {timeFormatter(saturDayCLoseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>
                    <div className="sunday-hours">
                      <span className="week-day">Sun -</span>
                      {sundayStartTime && sundayCloseTime ? (
                        <>
                          <span className="day-time">
                            {timeFormatter(sundayStartTime)}-{" "}
                            {timeFormatter(sundayCloseTime)}
                          </span>
                        </>
                      ) : (
                        <span className="closed-tag">CLOSED</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="controls-parent">
                  <div className="controls">
                    <div className="book-appointment">
                      <Button
                        className="book-appointment-btn"
                        id="ap-form-btn"
                        onClick={() => bookAppiontmentButton()}
                        disabled={bookAppointment}
                        size="large"
                      >
                        Continue to Appointment Form
                      </Button>
                    </div>
                    <div className="go-back">
                      <Button
                        className="go-back-btn"
                        id="go-back-btn"
                        onClick={() =>
                          navigate("/clinic-list", {
                            state: {
                              selectedClinic: selectedClinic,
                              appointmentDates: appointmentDates,
                              formData: formData,
                              clinicData: clinicData,
                            },
                          })
                        }
                      >
                        <i className="fa-solid fa-arrow-left mr-2"></i>
                        Choose a different clinic
                      </Button>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="col-lg-5 carousel-column">
              <Carousel>
                <div>
                  <div className="clinic-pic-one"></div>
                </div>
                <div>
                  <div className="clinic-pic-two"></div>
                </div>
                <div>
                  <div className="clinic-pic-three"></div>
                </div>
                <div>
                  <div className="clinic-pic-four"></div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicDetail;
