import React, { useEffect, useState } from 'react';
import { Button, Carousel, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import handIcon from './../assets/img/hand-icon.svg';
import locationIcon from './../assets/img/location-pin-icon-blue.svg';
import dateIcon from './../assets/img/date-icon.svg';
import appointmentBooked from './../assets/img/appointment-booked-icon.svg';
import infoSubmitted from './../assets/img/info-submitted.svg';
import phoneIcon from './../assets/img/phone-icon-submissions-page.svg';
import homeIcon from './../assets/img/home-icon.svg';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import { useDispatch } from 'react-redux';
import errorImage from './../assets/img/error-image.svg';
import { useSelector } from 'react-redux';
import Header from './header';
import { lang } from 'moment';

const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

const AppointmentSubmitted = () => {
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [guid, setGuid] = useState('');

  const insuranceTypes = useSelector((state) => state.insuranceTypes);
  const clinicsSearchFields = useSelector((state) => state.clinics);

  //Get Insurance Name
  const insuranceName =
    Object.values(insuranceTypes).length !== 0 &&
    clinicsSearchFields['insuranceProviderId'] !== 0
      ? insuranceTypes &&
        insuranceTypes.find(
          (item, key) => clinicsSearchFields['insuranceProviderId'] === item.id
        )?.name
      : null;

  useEffect(() => {
    if (location.state !== null) {
      completeBookAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = async () => {
    navigate('/');
  };

  const completeBookAppointment = async () => {
    var bookAppointmentPayload = location.state.bookAppointmentPayload;
    bookAppointmentPayload['thno'] =
      location.state.bookAppointmentResponse.thno;
    bookAppointmentPayload['lcno'] =
      location.state.bookAppointmentResponse.lcno;
    bookAppointmentPayload['companyId'] =
      location.state.bookAppointmentResponse.companyId;
    bookAppointmentPayload['PayerNameSelected'] = insuranceName;

    try {
      const controller = new AbortController();
      const id = setTimeout(
        () => controller.abort(),
        process.env.REACT_APP_API_TIMEOUT
      );

      const duplicate = await fetch(
        `${REACT_APP_CORA_API_URL}Appointment/DuplicateAppointment`,
        {
          body: JSON.stringify(bookAppointmentPayload),
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      );

      if ((await duplicate.json()) == false) {
        console.log('entereing completeBook');
        const response = await fetch(
          `${REACT_APP_CORA_API_URL}Appointment/CompleteBookAppointment`,
          {
            body: JSON.stringify(bookAppointmentPayload),
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        );
        clearTimeout(id);
        const appointmentConfirmation = await response.json();
        if (appointmentConfirmation.responseCode === 200) {
          actions.setConfirmed(true);
          //if success continue
          if (appointmentConfirmation.bookAppointment.appointmentId !== null) {
            actions.setBookAppointmentId(
              appointmentConfirmation.bookAppointment.appointmentId
            );

            setGuid(appointmentConfirmation.bookAppointment.appointmentGUID);
          }
        }
      }
    } catch (error) {
      setApiErrorMessage(true);
    }
  };

  const addAdditionInfo = () => {
    actions.clearPhase2Form([]);
    actions.clearState([]);
    navigate(`/patient-checkin/patient-form?appointmentGuid=${guid}`, {
      state: { fromFlow: true },
    });
  };

  const addAdditionInfoSpanish = (language) => {
    actions.clearPhase2Form([]);
    actions.clearState([]);
    navigate(`/patient-checkin/patient-form?appointmentGuid=${guid}`, {
      state: {
        language: language,
        fromFlow: true,
      },
    });
  };
  return (
    <>
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            Something went wrong. Please try again later.
          </span>
        </div>
      </Modal>
      {location.state !== null ? (
        <div className="appointment-booked-page">
          <Header />
          <Helmet>
            <title>CORA Health | Appointment Submitted</title>
          </Helmet>

          <div className="row no-gutters booked-page-wrapper-row">
            <div className="col-lg-7 content-column">
              <div className="blue-blank-header"></div>
              <div className="main-content-wrapper">
                <div className="top-info-section">
                  <div className="top-image">
                    <img src={appointmentBooked} alt="Appointment Booked" />
                  </div>
                  <div>
                    <span className="ap-booked-heading">
                      Your appointment is booked.
                    </span>
                    <span className="ap-booked-subheading">
                      A CORA representative will call you if more information is
                      needed.
                    </span>
                  </div>
                </div>
                <div className="ap-details-card">
                  <div className="ap-details">
                    <div className="card-title">
                      <span>Appointment Detail</span>
                    </div>
                    <div className="appointment-info">
                      <div className="appointment-icon">
                        <img src={handIcon} alt="Appointment-display" />
                      </div>
                      <div className="appointment-text-info">
                        <span className="appointment-title">
                          Appointment For
                        </span>
                        <span className="appointment-value">
                          {location && location.state.therapyName}
                        </span>
                      </div>
                    </div>

                    {location.state.clinicAddress && (
                      <div className="clinic-address">
                        <div className="address-icon text-center">
                          <img src={locationIcon} alt="Address Icon" />
                        </div>

                        <div className="address-text">
                          <span className="address-title">Clinic Address</span>
                          <span className="address-value">
                            {location && location.state.clinicAddress.trim()}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="date-n-time">
                      <div className="date-icon">
                        <img src={dateIcon} alt="Date Icon" />
                      </div>
                      <div className="date-text">
                        <span className="date-title">Date &amp; Time</span>
                        <span className="date-value">
                          {location && location.state.date} {' at '}
                          {location && location.state.time}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="contact-section">
                    <div className="cell-number">
                      <img src={phoneIcon} alt="Phone Number" />
                      <a
                        href={`tel:${location?.state.clinicPhone}`}
                        className="ml-3 d-block"
                      >
                        {location && location.state.clinicPhone}{' '}
                      </a>
                    </div>
                    <div className="info-text">
                      <span>Please call with questions.</span>
                    </div>
                  </div>
                </div>
                <div className="additional-info-text">
                  <span className="ap-booked-subheading">
                    Depending on the services delivered, some additional forms
                    may be necessary.
                  </span>
                </div>
                <div className="additional-info-text">
                  <span>
                    Click below to begin your new patient registration forms.
                  </span>
                </div>

                <div className="btns">
                  <Button
                    className="additional-info-btn"
                    size="large"
                    onClick={() => addAdditionInfo()}
                  >
                    Begin registration
                  </Button>
                  <Button
                    className="additional-info-btn"
                    size="large"
                    onClick={() => addAdditionInfoSpanish('spa')}
                    style={{ marginTop: '15px' }}
                  >
                    Iniciar registro
                  </Button>
                  <Button
                    className="additional-info-btn"
                    size="large"
                    onClick={() => addAdditionInfoSpanish('spa')}
                    style={{ marginTop: '15px' }}
                  >
                    Komanse anrejistre
                  </Button>
                  <Button className="go-home-btn" onClick={goBack} size="large">
                    <img alt="" src={homeIcon} />
                    Go Home
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 carousel-column">
              <Carousel>
                <div>
                  <div className="clinic-pic-one"></div>
                </div>
                <div>
                  <div className="clinic-pic-two"></div>
                </div>
                <div>
                  <div className="clinic-pic-three"></div>
                </div>
                <div>
                  <div className="clinic-pic-four"></div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      ) : (
        <div className="appointment-booked-page">
          <Header />
          <Helmet>
            <title>CORA Health | Appointment Submitted</title>
          </Helmet>

          <div className="row no-gutters wrapper-row">
            <div className="col-lg-7 content-column">
              <div className="blue-blank-header"></div>
              <div className="main-content-wrapper">
                <div className="top-info-section">
                  <div className="top-image mb-2">
                    <img src={infoSubmitted} alt="Appointment Booked" />
                  </div>
                  <div>
                    <span className="ap-booked-heading">
                      Your information has been submitted.
                    </span>
                    <span className="ap-booked-subheading">
                      A CORA representative will contact you shortly.
                    </span>
                  </div>
                </div>
                <div className="ap-details-card">
                  <div className="contact-section">
                    <div className="cell-number">
                      <img src={phoneIcon} alt="Phone Number" />
                      <a href={`tel:${8664332672}`}>(866) 443-2672</a>
                    </div>
                    <div className="info-text">
                      <span>Please call with questions.</span>
                    </div>
                  </div>
                </div>

                <div className="btns">
                  <Button className="go-home-btn" onClick={goBack} size="large">
                    <img alt="" src={homeIcon} />
                    Back to Home
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 carousel-column">
              <Carousel>
                <div>
                  <div className="clinic-pic-one"></div>
                </div>
                <div>
                  <div className="clinic-pic-two"></div>
                </div>
                <div>
                  <div className="clinic-pic-three"></div>
                </div>
                <div>
                  <div className="clinic-pic-four"></div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmentSubmitted;
