const reducer = (clinicList = {}, { type, payload }) => {
  switch (type) {
    case "list":
      return { ...clinicList, ...payload };

    case "setCoordinates":
      return {
        ...clinicList,
        coordinates: payload,
      };

    default:
      return clinicList;
  }
};

export default reducer;
