const searchClinics = (clinics) => {
  return (dispatch) => {
    dispatch({
      type: "search",
      payload: clinics,
    });
  };
};

const setAuth = (param) => {
  return (dispatch) => {
    dispatch({
      type: "setAuth",
      payload: param,
    });
  };
};

const setClinicUrl = (param) => {
  return (dispatch) => {
    dispatch({
      type: "setClinicUrl",
      payload: param,
    });
  };
};

const setConfirmed = (param) => {
  return (dispatch) => {
    dispatch({
      type: "setConfirmed",
      payload: param,
    });
  };
};

const insuranceTypes = (insuranceTypesValues) => {
  return (dispatch) => {
    dispatch({
      type: "response",
      payload: insuranceTypesValues,
    });
  };
};

const clinicList = (clinicList) => {
  return (dispatch) => {
    dispatch({
      type: "list",
      payload: clinicList,
    });
  };
};

const setLongLat = (coordinates) => {
  return (dispatch) => {
    dispatch({
      type: "setCoordinates",
      payload: coordinates,
    });
  };
};

const therapyTypes = (therapyTypesValues) => {
  return (dispatch) => {
    dispatch({
      type: "fetch",
      payload: therapyTypesValues,
    });
  };
};

const uploadDocuments = (documents) => {
  return (dispatch) => {
    dispatch({
      type: "upload",
      payload: documents,
    });
  };
};

const removeImage = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: "remove-image",
      payload: imageUrl,
    });
  };
};

const setPrescriptions = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: "prescription",
      payload: imageUrl,
    });
  };
};

const removePrescriptions = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: "remove-prescription",
      payload: imageUrl,
    });
  };
};
const clearState = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: "clearAll",
      payload: imageUrl,
    });
  };
};

//multipleUpload
const uploadMultipleDocument = (documents) => {
  return (dispatch) => {
    dispatch({
      type: "uploadMultipleDocument",
      payload: documents,
    });
  };
};

//multipleUpload
const uploadDocumentsFromResponse = (documents) => {
  return (dispatch) => {
    dispatch({
      type: "uploadDocumentsFromResponse",
      payload: documents,
    });
  };
};

const removeImageMultipleDocument = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: "removeImageMultipleDocument",
      payload: imageUrl,
    });
  };
};

const clearAllMultipleDocument = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: "clearAllMultipleDocument",
      payload: imageUrl,
    });
  };
};

const formFill = (formData) => {
  return (dispatch) => {
    dispatch({
      type: "fillForm",
      payload: formData,
    });
  };
};

const clearForm = (formData) => {
  return (dispatch) => {
    dispatch({
      type: "clearForm",
      payload: formData,
    });
  };
};

const clearPhase2Form = (formData) => {
  return (dispatch) => {
    dispatch({
      type: "clearPhase2Form",
      payload: formData,
    });
  };
};

const updateFlag = (flagCheckBoolean) => {
  return (dispatch) => {
    dispatch({
      type: "updateFlag",
      payload: flagCheckBoolean,
    });
  };
};

const updateForm = (formData) => {
  return (dispatch) => {
    dispatch({
      type: "updateForm",
      payload: formData,
    });
  };
};

const setBookAppointmentId = (bookappointmentId) => {
  return (dispatch) => {
    dispatch({
      type: "setBookAppointmentId",
      payload: bookappointmentId,
    });
  };
};

const clearBookAppointmentId = (bookappointmentId) => {
  return (dispatch) => {
    dispatch({
      type: "clearBookAppointmentId",
      payload: bookappointmentId,
    });
  };
};

const patientFormNextButton = (nextButtonPatientForm) => {
  return (dispatch) => {
    dispatch({
      type: "patientFormNextButton",
      payload: nextButtonPatientForm,
    });
  };
};
const medicalFormNextButton = (nextButtonMedicalForm) => {
  return (dispatch) => {
    dispatch({
      type: "medicalFormNextButton",
      payload: nextButtonMedicalForm,
    });
  };
};
const uploadPatientForm = (uploadPatientForm) => {
  return (dispatch) => {
    dispatch({
      type: "uploadPatientForm",
      payload: uploadPatientForm,
    });
  };
};

const uploadMedicalForm = (uploadMedicalForm) => {
  return (dispatch) => {
    dispatch({
      type: "uploadMedicalForm",
      payload: uploadMedicalForm,
    });
  };
};
const uploadLetterOfExhaustionCopy = (uploadLetterOfExhaustionCopy) => {
  return (dispatch) => {
    dispatch({
      type: "uploadLetterOfExhaustionCopy",
      payload: uploadLetterOfExhaustionCopy,
    });
  };
};

const removeLetterOfExhaustionCopy = (removeLetterOfExhaustionCopy) => {
  return (dispatch) => {
    dispatch({
      type: "removeLetterOfExhaustionCopy",
      payload: removeLetterOfExhaustionCopy,
    });
  };
};

const updateEditButtonForNewPatient = (updateEditButtonForNewPatient) => {
  return (dispatch) => {
    dispatch({
      type: "updateEditButtonForNewPatient",
      payload: updateEditButtonForNewPatient,
    });
  };
};

const updateEditButtonForExistingPatient = (
  updateEditButtonForExistingPatient
) => {
  return (dispatch) => {
    dispatch({
      type: "updateEditButtonForExistingPatient",
      payload: updateEditButtonForExistingPatient,
    });
  };
};

const uploadMedicareForm = (uploadMedicareForm) => {
  return (dispatch) => {
    dispatch({
      type: "uploadMedicareForm",
      payload: uploadMedicareForm,
    });
  };
};

const saveSignature = (saveSignature) => {
  return (dispatch) => {
    dispatch({
      type: "saveSignature",
      payload: saveSignature,
    });
  };
};

const screeningQuestions = (screeningQuestions) => {
  return (dispatch) => {
    dispatch({
      type: "screeningQuestions",
      payload: screeningQuestions,
    });
  };
};

const updateClinicName = (updateClinicName) => {
  return (dispatch) => {
    dispatch({
      type: "updateClinicName",
      payload: updateClinicName,
    });
  };
};

const existingPatientWithAppointment = (existingPatientWithAppointment) => {
  return (dispatch) => {
    dispatch({
      type: "existingPatientWithAppointment",
      payload: existingPatientWithAppointment,
    });
  };
};

const validationResponse = (validationResponse) => {
  return (dispatch) => {
    dispatch({
      type: "validationResponse",
      payload: validationResponse,
    });
  };
};

const newPatientValidation = (newPatientValidation) => {
  return (dispatch) => {
    dispatch({
      type: "newPatientValidation",
      payload: newPatientValidation,
    });
  };
};

const setCheckInScreeningEnabled = (checkInScreeningEnabled) => {
  return (dispatch) => {
    dispatch({
      type: "checkInScreeningEnabled",
      payload: checkInScreeningEnabled,
    });
  };
};

const setVOBNeeded = (vobNeeded) => {
  return (dispatch) => {
    dispatch({
      type: "vobNeeded",
      payload: vobNeeded,
    });
  };
};

const setAmountDue = (amountDue) => {
  return (dispatch) => {
    dispatch({
      type: "amountDue",
      payload: amountDue,
    });
  };
};

export {
  setClinicUrl,
  searchClinics,
  insuranceTypes,
  clinicList,
  setLongLat,
  therapyTypes,
  uploadDocuments,
  removeImage,
  uploadMultipleDocument,
  uploadDocumentsFromResponse,
  removeImageMultipleDocument,
  newPatientValidation,
  clearAllMultipleDocument,
  setPrescriptions,
  removePrescriptions,
  clearState,
  formFill,
  clearForm,
  updateFlag,
  updateForm,
  setBookAppointmentId,
  setConfirmed,
  clearBookAppointmentId,
  patientFormNextButton,
  medicalFormNextButton,
  validationResponse,
  uploadPatientForm,
  uploadMedicalForm,
  uploadLetterOfExhaustionCopy,
  updateEditButtonForNewPatient,
  updateEditButtonForExistingPatient,
  uploadMedicareForm,
  clearPhase2Form,
  saveSignature,
  screeningQuestions,
  updateClinicName,
  removeLetterOfExhaustionCopy,
  existingPatientWithAppointment,
  setCheckInScreeningEnabled,
  setVOBNeeded,
  setAmountDue,
  setAuth,
};
