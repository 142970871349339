/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Select, Spin, Checkbox, Modal, Carousel } from 'antd';
import FloatLabel from './floatLabel';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state';
import DateFnsUtils from '@date-io/date-fns';
import { useForm, Controller } from 'react-hook-form';
import handIcon from './../assets/img/hand-icon.svg';
import locationIcon from './../assets/img/location-pin-icon-blue.svg';
import dateIcon from './../assets/img/date-icon.svg';
import infoIcon from './../assets/img/info-icon.svg';
import pencilIcon from './../assets/img/pencil-icon.svg';
import Header from './header';
import ReCAPTCHA from 'react-google-recaptcha';
import noSlotImg from './../assets/img/no-slot-icon.svg';
import errorImage from './../assets/img/error-image.svg';

import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import validateDate from '../utils/validateDate';
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
}));

function ConfirmAppoint() {
  const { register, handleSubmit, control, errors } = useForm();
  const initialFormData = Object.freeze({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    mobileNumber: '',
    email: '',
    memberIdNumber: '',
    gender: '',
  });

  const Alpha_Regex = /^[a-zA-Z0-9 ]+$/;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = bindActionCreators(actionCreators, dispatch);

  const location = useLocation();

  const confirmedState = useSelector(
    (state) => state.confirmedAppointment.confirmed
  );

  if (location.state === null || confirmedState == true) {
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
    actions.setConfirmed(false);
  }

  setTimeout(() => {
    var firstname = document.getElementById('first-name');
    firstname?.setAttribute(
      'onkeydown',
      "return /[a-zA-Z '-]/i.test(event.key)"
    );

    var lastname = document.getElementById('last-name');
    lastname?.setAttribute(
      'onkeydown',
      "return /[a-zA-Z '-]/i.test(event.key)"
    );

    const inputElement = document.getElementById('mobileNumber');
    if (inputElement) {
      inputElement.addEventListener('onkeydown', phoneNumberFormatter());
    }
  }, 350);

  function phoneNumberFormatter() {
    const inputField = document.getElementById('mobileNumber');
    const formattedInputValue = formatPhoneNumber(inputField?.value);
    inputField.value = formattedInputValue;
  }

  function getNumber() {
    const inputField = document.getElementById('mobileNumber');
    const formattedInputValue = formatPhoneNumber(inputField?.value);
    return formattedInputValue;
  }

  const clinicsSearchFields = useSelector((state) => state.clinics);
  const therapyTypes = useSelector((state) => state.therapyTypes);
  const insuranceTypes = useSelector((state) => state.insuranceTypes);
  const [appointmentSlotBookModal, setAppointmentSlotBookModal] =
    useState(false);
  const [formData, updateFormData] = React.useState(initialFormData);
  const [buttonTitle, setButtonTitle] = useState('Continue, Review Details');
  const [disabledField, setDisabledField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookButtonState, setBookButtonState] = useState(true);
  const [values, setValues] = useState(clinicsSearchFields);
  const [notARobotValue, setNotARobotValue] = useState(true);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);
  const [duplicateAppointment, setIsDuplicate] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [dobError, setDobError] = useState(false);

  const form = useSelector((state) => state.form);
  const classes = useStyles();

  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

  //dates formatter

  const datesFormatter = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let current_datetime = new Date(
      location.state !== null && location.state.appointmentTime.date
    );
    return (
      months[current_datetime.getMonth()] +
      ' ' +
      current_datetime.getDate() +
      ', ' +
      current_datetime.getFullYear()
    );
  };

  const timeFormatter = () => {
    let formattedTime;
    let startTime =
      location &&
      location.state !== null &&
      location.state.appointmentTime.startTime;
    var timesplit = startTime.split('.');
    var hours = timesplit[0];
    var minutes = timesplit[1];

    if (timesplit[0].length === 1) {
      hours = '0' + timesplit[0];
    }
    if (timesplit[1].length === 2 && timesplit[1].charAt(0) === '5') {
      minutes = '30';
    }

    startTime = hours + '.' + minutes;

    if (parseInt(startTime) > 12) {
      var timesplit1 = startTime.split('.');
      var hours1 = timesplit1[0];
      var minutes1 = timesplit1[1];

      if (timesplit1[1].length === 2 && timesplit1[1].charAt(0) === '5') {
        minutes1 = '30';
      }

      hours1 = hours1 - 12;
      hours1 = '' + hours1;

      if (hours1.length === 1) {
        hours1 = '0' + hours1;
      }

      formattedTime = hours1 + '.' + minutes1 + ' pm';
    } else if (parseInt(startTime) === 12) {
      formattedTime = startTime + ' pm';
    } else {
      formattedTime = startTime + ' am';
    }
    return formattedTime.replaceAll('.', ':');
  };

  //Format phone number
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  //Fetch TherapyName
  const therapyName =
    Object.values(therapyTypes).length !== 0 &&
    clinicsSearchFields['typeofTherapyId'] !== 0
      ? therapyTypes &&
        therapyTypes.find(
          (item) => clinicsSearchFields['typeofTherapyId'] === item.id
        ).name
      : null;

  const handleInputChange = (value) => {
    clinicsSearchFields['insuranceProvider'] = value;
  };

  const handleGender = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      gender: e.target.value.trim(),
    });
  };

  const handleChange = (e) => {
    if (e._d) {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        dateOfBirth: e._d.toISOString(),
      });
      if (form.shouldPreFillForm) {
        actions.updateForm({ name: 'dateOfBirth', value: e._d.toISOString() });
      }
    } else if (e.target.name === 'mobileNumber') {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: formattedPhoneNumber.trim(),
      });
      if (form.shouldPreFillForm) {
        actions.updateForm({
          name: e.target.name,
          value: formattedPhoneNumber.slice(6, 14).replaceAll('-', '').trim(),
        });
        actions.updateForm({
          name: 'areCode',
          value:
            formattedPhoneNumber.length >= 5
              ? formattedPhoneNumber.slice(1, 4)
              : e.target.value,
        });
      }
    } else {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
      });
      if (form.shouldPreFillForm) {
        actions.updateForm({ name: e.target.name, value: e.target.value });
      }
    }
  };

  let bookAppointmentPayload;
  function populateData(data, date, gender) {
    bookAppointmentPayload = {
      clinicId: location && location.state.appointmentTime.clinicId,
      typeOfTherapyId:
        clinicsSearchFields && clinicsSearchFields['typeofTherapyId'],
      city: clinicsSearchFields && clinicsSearchFields['city'],
      zip: clinicsSearchFields && clinicsSearchFields['zip'],
      insuranceProviderId:
        clinicsSearchFields && clinicsSearchFields['insuranceProviderId'],
      appointmentDate: location && location.state.appointmentTime.date,
      appointmentTime: location && location.state.appointmentTime.startTime,
      firstName: form.shouldPreFillForm
        ? form.formData[0]['firstName']
        : data.firstName,
      lastName: form.shouldPreFillForm
        ? form.formData[0]['lastName']
        : data.lastName,
      dob: form.shouldPreFillForm
        ? form.formData[0]['dob'].slice(0, 10)
        : moment(date).format('YYYY-MM-DD'),
      gender: gender,
      email: form.shouldPreFillForm ? form.formData[0]['email'] : data.email,
      memberIdNumber: form.shouldPreFillForm
        ? form.formData[0]['memberIdNumber']
        : data.memberIdNumber,
      mobileNumber: form.shouldPreFillForm
        ? form.formData[0]['mobileNumber']
        : data.mobileNumber.slice(6, 14).replaceAll('-', ''),
      isSeenDoctor: clinicsSearchFields && clinicsSearchFields['isSeenDoctor'],
      areCode: form.shouldPreFillForm
        ? form.formData[0]['areCode']
        : data.mobileNumber.slice(1, 4),
    };

    return bookAppointmentPayload;
  }

  function notARobotCheck(value) {
    setNotARobotValue(false);
  }

  const onSubmit = async (data) => {
    let bookAppointmentPayload;

    if (dobError) return;

    let title = document
      .getElementsByClassName('dual-book-button')[0]
      .innerText.trim();

    //Button text change
    if (title === 'Continue, Review Details') {
      setButtonTitle('Confirm My Appointment');
      setDisabledField(true);
    }

    //Book appointment API
    else if (title === 'Confirm My Appointment') {
      bookAppointmentPayload = populateData(
        formData,
        data.dateOfBirth,
        data.gender
      );

      try {
        setLoading(true);

        const controller = new AbortController();
        const id = setTimeout(
          () => controller.abort(),
          process.env.REACT_APP_API_TIMEOUT
        );

        const duplicate = await fetch(
          `${REACT_APP_CORA_API_URL}Appointment/DuplicateAppointment`,
          {
            body: JSON.stringify(bookAppointmentPayload),
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        );

        const isDuplicate = await duplicate.json();
        //check if admission already exists for patient, if so, prompt and do not create appointment.
        if (isDuplicate == false) {
          const response = await fetch(
            `${REACT_APP_CORA_API_URL}Appointment/BookAppointment`,
            {
              body: JSON.stringify(bookAppointmentPayload),
              headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
              },
              method: 'POST',
            }
          );

          const appointmentConfirmation = await response.json();
          if (appointmentConfirmation.responseCode === 200) {
            setLoading(false);

            //if success continue
            if (appointmentConfirmation.bookAppointment.lcno !== '') {
              navigate('/appointment-submitted', {
                state: {
                  therapyName: therapyName,
                  clinicAddress:
                    location &&
                    location.state !== null &&
                    location.state.clinicData.clinicAddress.trim() +
                      ', ' +
                      location.state.clinicData.lccity +
                      ', ' +
                      location.state.clinicData.lcstate +
                      ', ' +
                      location.state.clinicData.lczip,
                  date: datesFormatter(),
                  time: timeFormatter(),
                  clinicPhone:
                    location &&
                    location.state !== null &&
                    formatPhoneNumber(
                      location.state.clinicData.areCode +
                      location.state.clinicData.clinicPhone
                    ),
                  bookAppointmentPayload: bookAppointmentPayload,
                  bookAppointmentResponse:
                    appointmentConfirmation.bookAppointment,
                  // bookAppointmentId:
                  //   appointmentConfirmation &&
                  //   appointmentConfirmation.bookAppointment.appointmentId,
                },
                values: values,
              });
              actions.updateFlag(false);
            } else {
              actions.formFill(bookAppointmentPayload);
              actions.updateFlag(true);
              setAppointmentSlotBookModal(true);
            }
          }
        } else {
          ///todo: Show prompt
          setIsDuplicate(true);
          setLoading(false);
        }
        clearTimeout(id);
      } catch (error) {
        setApiErrorMessage(true);
        setLoading(false);
      }
    }
  };

  function editForm() {
    setButtonTitle('Continue, Review Details');
    setDisabledField(false);
    setNotARobotValue(true);
  }

  return (
    <div className="confirm-and-book-appointment">
      <Modal
        visible={apiErrorMessage}
        className="exception-modal"
        onCancel={() => setApiErrorMessage(false)}
        onOk={() => setApiErrorMessage(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Something went wrong."
          />
          <span className="exception-modal-text">
            Something went wrong. Please try again later.
          </span>
        </div>
      </Modal>
      <Modal
        visible={duplicateAppointment}
        className="exception-modal"
        onCancel={() => setIsDuplicate(false)}
        onOk={() => setIsDuplicate(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img
            className="error-img"
            src={errorImage}
            alt="Duplicate Appointment."
          />
          <span className="exception-modal-text">
            It looks like you already have an appointment scheduled. Please call
            the clinic at{' '}
            {formatPhoneNumber(
              location.state.clinicData.areCode +
                location.state.clinicData.clinicPhone
            )}{' '}
            for more information.
          </span>
        </div>
      </Modal>
      <Header />
      <Helmet>
        <title>CORA Health | Confirm Appointment</title>
      </Helmet>
      <Modal
        visible={appointmentSlotBookModal}
        className="no-slot-modal"
        onCancel={() => setAppointmentSlotBookModal(false)}
        onOk={() => setAppointmentSlotBookModal(false)}
        footer={null}
        centered
      >
        <div className="modal-content">
          <img className="no-slot-img" src={noSlotImg} alt="Slot Booked" />
          <span className="slot-booked-text">Appointment already booked.</span>

          <Button
            type="primary"
            size="large"
            className="select-different-time-btn"
            id="select-different-slot"
            onClick={() => window.history.back()}
          >
            Please select a different timeslot.
          </Button>
        </div>
      </Modal>
      <Modal
        visible={loading}
        className="no-slot-modal"
        onCancel={() => setLoading(false)}
        onOk={() => setLoading(false)}
        maskClosable={false}
        footer={null}
        centered
      >
        <div className="modal-content">
          <Spin indicator={antIcon} className="form-loading-icon" />
        </div>
        <span className="slot-booked-text">Please wait </span>
      </Modal>

      <div className="row no-gutters wrapper-row">
        <div className="col-lg-7 content-column">
          <div className="appointment-info">
            <div className="appointment">
              <div className="appointment-icon">
                <img src={handIcon} alt="Appointment-display" />
              </div>
              <div className="appointment-text-info">
                <span className="appointment-title">Appointment For</span>
                <span className="appointment-value">{therapyName}</span>
              </div>
            </div>
            <div className="clinic-address">
              <div className="address-icon text-center">
                <img src={locationIcon} alt="Address Icon" />
              </div>
              <div className="address-text">
                <span className="address-title">Clinic Address</span>
                <span className="address-value">
                  {location &&
                    location.state !== null &&
                    location.state.clinicData.clinicAddress.trim() +
                      ', ' +
                      location.state.clinicData.lccity +
                      ', ' +
                      location.state.clinicData.lcstate +
                      ', ' +
                      location.state.clinicData.lczip}
                </span>
              </div>
            </div>
            {/* <h4>{location && location.state[0].data.clinics.clinicAddress}</h4> */}
            <div className="date-n-time">
              <div className="date-icon">
                <img src={dateIcon} alt="Date Icon" />
              </div>
              <div className="date-text">
                <span className="date-title">Date &amp; Time</span>
                <span className="date-value">
                  {datesFormatter() + ' at ' + timeFormatter()}
                </span>
              </div>
            </div>
          </div>
          <div className="main-content-section">
            <div className="heading-section">
              <div className="info-heading">
                {disabledField ? (
                  <div>
                    <span>Please review your details</span>
                    <button
                      className="edit-form-btn"
                      id="edit-form-btn"
                      onClick={editForm}
                    >
                      <img alt="" src={pencilIcon} />
                      Edit
                    </button>
                  </div>
                ) : (
                  <>
                    <i
                      onClick={() => window.history.back()}
                      className="fa-solid fa-arrow-left cursor-pointer"
                    ></i>
                    <span>Please provide details</span>
                  </>
                )}
              </div>
              {!disabledField ? (
                <div className="info-text">
                  <span>All fields are required, unless marked optional.</span>
                </div>
              ) : null}
            </div>

            <div
              className={`form-card confirm-appointment-form ${
                disabledField ? 'disalbed-form' : ''
              }`}
            >
              <div className="form-title">
                <span>Patient Information</span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 first-name-col">
                    <TextField
                      label="First Name *"
                      variant="outlined"
                      disabled={disabledField}
                      fullWidth
                      size="small"
                      className={classes.inputField}
                      onChange={handleChange}
                      name="firstName"
                      id="first-name"
                      inputRef={register({
                        required: 'First Name is required.',
                        pattern: {
                          value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                          message: 'Please enter a valid first name',
                        },
                      })}
                      value={
                        form.shouldPreFillForm
                          ? form.formData[0]['firstName']
                          : null
                      }
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <div className="col-lg-6 last-name-col">
                    <TextField
                      label="Last Name *"
                      variant="outlined"
                      size="small"
                      disabled={disabledField}
                      fullWidth
                      className={classes.inputField}
                      onChange={handleChange}
                      name="lastName"
                      id="last-name"
                      inputRef={register({
                        required: 'Last Name is required.',
                        pattern: {
                          value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                          message: 'Please enter a valid last name',
                        },
                      })}
                      value={
                        form.shouldPreFillForm
                          ? form.formData[0]['lastName']
                          : null
                      }
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName?.message}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 dob-datepicker-col">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        render={(props) => (
                          <KeyboardDatePicker
                            disabled={disabledField}
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="dob-datepicker"
                            label="Date of Birth (mm/dd/yyyy) *"
                            value={props.value}
                            disableFuture={true}
                            onChange={(value) => {
                              props.onChange(value);
                              const dateValidated = validateDate(value);
                              setDobError(dateValidated);
                            }}
                            fullWidth
                            error={Boolean(errors.dateOfBirth) || dobError}
                            helperText={
                              errors.dateOfBirth?.message ||
                              (dobError && 'Invalid Date')
                            }
                            size="small"
                          />
                        )}
                        name="dateOfBirth"
                        defaultValue={
                          form.shouldPreFillForm
                            ? new Date(form.formData[0]['dob'])
                            : null
                        }
                        control={control}
                        rules={{
                          required: 'Date of Birth is required.',
                        }}
                        size="small"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-lg-6 insurance-provider-select-col">
                    <FloatLabel
                      label="Insurance Provider"
                      value={
                        clinicsSearchFields &&
                        clinicsSearchFields['insuranceProviderId']
                      }
                    >
                      <Select
                        suffixIcon={''}
                        showSearch
                        placeholder="Insurance Provider"
                        disabled={true}
                        optionFilterProp="children"
                        value={
                          clinicsSearchFields &&
                          clinicsSearchFields['insuranceProviderId']
                        }
                        size="large"
                        name="insuranceProvider"
                        onChange={(event) => handleInputChange(event)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {Object.keys(insuranceTypes).length !== 0 &&
                          insuranceTypes &&
                          insuranceTypes.map((item, index) => (
                            <Option key={index} value={item.id}>
                              {item?.name}
                            </Option>
                          ))}
                      </Select>
                    </FloatLabel>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 last-name-col">
                    <TextField
                      label="Member ID number"
                      variant="outlined"
                      size="small"
                      disabled={disabledField}
                      fullWidth
                      className={classes.inputField}
                      onChange={handleChange}
                      name="memberIdNumber"
                      id="memberIdNumber"
                      inputRef={register({
                        pattern: {
                          value: /[A-Za-z0-9_]/,
                          message: 'Please enter a valid member id',
                        },
                      })}
                      value={
                        form.shouldPreFillForm
                          ? form.formData[0]['memberIdNumber']
                          : null
                      }
                      error={Boolean(errors.memberIdNumber)}
                      helperText={errors.memberIdNumber?.message}
                      inputProps={{ maxLength: 15 }}
                      onKeyDown={(event) => {
                        if (!Alpha_Regex.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6 last-name-col">
                    <div className="d-flex align-items-center">
                      <label style={{ fontWeight: '600', marginRight: '1rem' }}>
                        Gender*
                      </label>
                      <FormControl
                        inputRef={register({
                          pattern: {
                            message: 'Please enter a valid gender',
                          },
                        })}
                        error={errors?.hasOwnProperty('gender')}
                        component="fieldset"
                        className="gender-rad"
                        style={{ backgroundColor: 'transparent !important' }}
                      >
                        <Controller
                          rules={{ required: true }}
                          control={control}
                          name="gender"
                          defaultValue=""
                          as={
                            <RadioGroup
                              name="radio-buttons-group"
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <FormControlLabel
                                value="M"
                                control={<Radio />}
                                label="Male"
                                onChange={(e) => handleGender(e)}
                              />
                              <FormControlLabel
                                value="F"
                                control={<Radio />}
                                label="Female"
                                onChange={(e) => handleGender(e)}
                              />
                            </RadioGroup>
                          }
                        />
                      </FormControl>
                    </div>
                    {errors?.gender && (
                      <p
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#fff',
                          color: 'red',
                          paddingLeft: '10px',
                        }}
                      >
                        Please select gender
                      </p>
                    )}
                  </div>
                </div>

                <div className="contact-info-section">
                  <span className="ci-heading">Contact Information</span>
                  <div className="ci-icon-n-text">
                    <img src={infoIcon} alt="Info Icon" />
                    <span className="ci-text">
                      Your mobile number is needed to book the appointment. We
                      will use it to text a confirmation message and any updates
                      on your appointment.
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 phone-number-col">
                    <TextField
                      placeholder="Enter Your Mobile Number"
                      label="Mobile Number *"
                      variant="outlined"
                      size="small"
                      id="mobileNumber"
                      disabled={disabledField}
                      fullWidth
                      onChange={handleChange}
                      className={classes.inputField}
                      name="mobileNumber"
                      inputRef={register({
                        required: 'Phone Number is required.',
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                          message: 'Please enter a valid mobile number',
                        },
                      })}
                      value={
                        form.shouldPreFillForm
                          ? formatPhoneNumber(
                              form.formData[0]['areCode'] +
                                form.formData[0]['mobileNumber']
                            )
                          : getNumber()
                      }
                      error={Boolean(errors.mobileNumber)}
                      helperText={errors.mobileNumber?.message}
                      inputProps={{ maxLength: 14 }}
                    />
                    <span className="mobile-hint">
                      Hint: Enter a 10-digit telephone: e.g. (785) 993-3322
                    </span>
                  </div>

                  <div className="col-lg-6 email-col">
                    <TextField
                      placeholder="Enter Your E-mail Address"
                      label="Email Address *"
                      variant="outlined"
                      disabled={disabledField}
                      fullWidth
                      onChange={handleChange}
                      className={classes.inputField}
                      name="email"
                      size="small"
                      inputRef={register({
                        required: 'Email is required.',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: 'Please enter a valid e-mail address.',
                        },
                      })}
                      value={
                        form.shouldPreFillForm
                          ? form.formData[0]['email']
                          : null
                      }
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                </div>

                {!disabledField ? (
                  <div className="tnc">
                    <Checkbox
                      className="tnc-checkbox"
                      id="tnc-checkbox"
                      onClick={() => setBookButtonState(!bookButtonState)}
                    ></Checkbox>
                    <span className="t-n-c-text">
                      I agree to the{' '}
                      <a
                        href="https://www.coraphysicaltherapy.com/terms-of-use/"
                        className="clickable-link"
                        target="_blank"
                        rel="noreferrer"
                        id="terms-conditions"
                      >
                        terms and conditions
                      </a>{' '}
                      and the{' '}
                      <a
                        href="https://www.coraphysicaltherapy.com/privacy-policy/"
                        className="clickable-link"
                        target="_blank"
                        rel="noreferrer"
                        id="privacy-policy"
                      >
                        privacy policy.
                      </a>
                    </span>
                  </div>
                ) : (
                  <div className="tnc">
                    <Checkbox
                      className="tnc-checkbox"
                      id="tnc-checkbox"
                      defaultChecked
                      disabled
                    ></Checkbox>
                    <span className="t-n-c-text">
                      I agree to the{' '}
                      <a
                        href="https://www.coraphysicaltherapy.com/terms-of-use/"
                        className="clickable-link"
                        target="_blank"
                        rel="noreferrer"
                        id="terms-conditions"
                      >
                        terms and conditions
                      </a>{' '}
                      and the{' '}
                      <a
                        href="https://www.coraphysicaltherapy.com/privacy-policy/"
                        className="clickable-link"
                        target="_blank"
                        rel="noreferrer"
                        id="privacy-policy"
                      >
                        privacy policy.
                      </a>
                    </span>
                    <ReCAPTCHA
                      className="mt-3"
                      sitekey="6LcI6JYpAAAAALw7lBLr6JZMj0onwiLbn342AK2K"
                      onChange={notARobotCheck}
                    />
                  </div>
                )}
                <Button
                  className="dual-book-button"
                  disabled={
                    buttonTitle === 'Continue, Review Details'
                      ? bookButtonState
                      : notARobotValue
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                >
                  {/* {loading ? (
                    <Spin
                      indicator={antIcon}
                      className="mr-3 submit-btn-loader"
                    />
                  ) : null} */}
                  {buttonTitle}
                </Button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-5 carousel-column">
          <Carousel>
            <div>
              <div className="clinic-pic-one"></div>
            </div>
            <div>
              <div className="clinic-pic-two"></div>
            </div>
            <div>
              <div className="clinic-pic-three"></div>
            </div>
            <div>
              <div className="clinic-pic-four"></div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default ConfirmAppoint;
