/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Modal, Card, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@material-ui/core/Box";
import { actionCreators } from "../state";
import Webcam from "react-webcam";
import * as imageConversion from "image-conversion";
import pdfIcon from "../assets/img/pdf-icon.png";

function IdDocumentsComponent(props) {
  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [captureSaveText, setCaptureSaveText] = useState("Take Photo");
  const [webCamModal, setWebCamModal] = useState(false);
  const [webCameraImage, setWebCameraImage] = useState();
  const [source, setSource] = useState("");
  const [displayImage, setDisplayImage] = useState("");

  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const documents = useSelector((state) => state.documents);

  async function handleCapture(input) {
    const fileSize = input.files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 25) {
      const messageText = `File should not be greater than 25MB`;
      message.error(messageText, [1.5]);
    } else {
      if (input.files) {
        if (input.files.length !== 0) {
          const file = input.files[0];
          const newReduxStoreKey =
            props.type + "-" + props.title.replaceAll(" ", "-");

          setIsModalVisible(false);
          let fileCompressed = await imageConversion.compressAccurately(
            file,
            200
          );

          if (
            fileCompressed.type.split("/").pop().includes("pdf") ||
            fileCompressed.type.split("/")[0].includes("image")
          ) {
            const imageType = {
              imageUrl: await toBase64(fileCompressed),
              imageKey: newReduxStoreKey,
              extension: fileCompressed.type.split("/").pop(),
            };

            actions.uploadDocuments(imageType);
          } else {
            const messageText = `You can only upload a PDF file or an image.`;
            message.error(messageText, [1.5]);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (Object.values(documents).length !== 0) {
      documents?.imageList.map((item) => {
        if (
          item.imageKey.includes(
            props.type + "-" + props.title.replaceAll(" ", "-")
          )
        ) {
          if (item.imageUrl.includes("/pdf")) {
            setSource(item.imageUrl);
            setDisplayImage(pdfIcon);
          } else {
            setSource(item.imageUrl);
            setDisplayImage(item.imageUrl);
          }
        }
      });
    }
  }, [documents.imageList]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const webcamRef = React.useRef(null);

  const capture = async () => {
    const newReduxStoreKey =
      props.type + "-" + props.title.replaceAll(" ", "-");
    setCaptureSaveText("Upload");

    if (captureSaveText === "Upload") {
      setWebCamModal(false);
      setSource(webCameraImage);
      const imageType = {
        imageUrl: webCameraImage,
        imageKey: newReduxStoreKey,
        extension: webCameraImage.split("/")[1].split(";", 1)[0],
      };

      //add image with key
      actions.uploadDocuments(imageType);
      setCaptureSaveText("Take Photo");
    } else {
      var images = webcamRef?.current?.getScreenshot();
      setCaptureSaveText("Upload");
      setWebCameraImage(images);
    }
  };

  const reTake = () => {
    setCaptureSaveText("Take Photo");
    setWebCameraImage("");
  };

  const handleDelete = (source) => {
    setSource(null);
    actions.removeImage(source);
    setDisplayImage(null);
    setWebCameraImage("");
    setCaptureSaveText("Take Photo");
  };

  const cardOnClick = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <Card onClick={cardOnClick}>
        {!displayImage ? (
          <p className="id-icon-wrapper">
            <i className="id-icon"></i> {props.title}
          </p>
        ) : (
          <p>Uploaded</p>
        )}

        {source && (
          <Box
            display="flex"
            justifyContent="center"
            border={1}
            className={props.classes.imgBox}
          >
            <img
              src={displayImage}
              alt={"snap"}
              className={props.classes.img}
            ></img>
            <Button
              type="danger"
              onClick={(ev) => {
                ev.stopPropagation();
                handleDelete(source);
              }}
            >
              x
            </Button>
          </Box>
        )}
      </Card>
      <Modal
        centered
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        className="upload-document-modal capture-upload-modal"
      >
        {props.screen === "desktop" ? (
          <button
            className="cam-upload-btn"
            onClick={() => {
              setIsModalVisible(false);
              setWebCamModal(true);
            }}
          >
            <i className="camera-icon"></i> Capture through webcamera
          </button>
        ) : (
          <>
            <div className={props.classes.root}>
              <Grid container>
                <Grid item xs={12}>
                  <label className="file-upload-btn">
                    <i className="camera-icon"></i> Capture by Camera
                    <input
                      accept="image/*"
                      className={props.classes.input}
                      id="icon-button-file"
                      type="file"
                      capture="environment"
                      onChange={(e) => handleCapture(e.target)}
                    />
                  </label>
                </Grid>
              </Grid>
            </div>
          </>
        )}
        <div>
          <label className="file-upload-btn">
            <i className="upload-img-icon "></i>
            Upload from your computer drive
            <input
              accept="image/*,application/pdf"
              type="file"
              name="myImage"
              id="upload-from-computer-drive"
              onChange={(e) => handleCapture(e.target)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </label>
        </div>
      </Modal>

      <Modal
        visible={webCamModal}
        onOk={() => setWebCamModal(false)}
        onCancel={() => setWebCamModal(false)}
        className="upload-document-modal capture-modal"
        width={400}
      >
        {webCameraImage ? (
          <Box
            display="flex"
            justifyContent="center"
            border={1}
            className={props.classes.imgBox}
          >
            <img
              src={webCameraImage}
              alt={"snap"}
              className={props.classes.img}
            ></img>
          </Box>
        ) : (
          <Webcam
            style={{
              width: "300px",
              height: "300px",
            }}
            ref={webcamRef}
            screenshotQuality={1}
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        )}
        <div className="cam-btn-wrapper">
          {webCameraImage && (
            <button className="retake-btn" onClick={reTake}>
              Re-take
            </button>
          )}
          <button className="take-btn" onClick={capture}>
            {captureSaveText}
          </button>
        </div>
      </Modal>
    </>
  );
}
export default IdDocumentsComponent;
