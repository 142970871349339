import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { Checkbox } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Helmet } from "react-helmet";
import { Spin, Carousel, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import pencilIcon from "./../assets/img/pencil-icon.svg";
import infoIcon from "./../assets/img/info-icon.svg";
import errorImage from "./../assets/img/error-image.svg";
import Header from "./header";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
}));

const initialFormData = Object.freeze({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  mobileNumber: "",
  email: "",
});

const BookAppointment = () => {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();
  const [buttonTitle, setButtonTitle] = useState("Continue, Review Details");
  const [disabledField, setDisabledField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookButtonState, setBookButtonState] = useState(true);
  const [formData, updateFormData] = useState(initialFormData);
  const [notARobotValue, setNotARobotValue] = useState(true);
  const [apiErrorMessage, setApiErrorMessage] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const navigate = useNavigate();

  const REACT_APP_CORA_API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;

  function editForm() {
    setButtonTitle("Continue, Review Details");
    setDisabledField(false);
    setNotARobotValue(true);
    // setBookButtonState(true);
  }

  setTimeout(() => {
    var firstname = document.getElementById("first-name");
    firstname?.setAttribute("onkeydown", "return /[a-zA-Z '-]/i.test(event.key)")

    var lastname = document.getElementById("last-name");
    lastname?.setAttribute("onkeydown", "return /[a-zA-Z '-]/i.test(event.key)")

    const inputElement = document.getElementById("mobile-number");
    inputElement.addEventListener("onkeydown", phoneNumberFormatter());
  }, 350);

  function phoneNumberFormatter() {
    const inputField = document.getElementById("mobile-number");
    const formattedInputValue = formatPhoneNumber(inputField.value);
    inputField.value = formattedInputValue;
  }
  //Format phone number
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleChange = (e) => {
    if (e.target.name === "mobileNumber") {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
      });
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const handleInputChange = (name, value) => {
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  let bookAppointmentPayload;
  function populateDate(data, date) {
    bookAppointmentPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      dob: date.toISOString(),
      insuranceProviderId: data.insuranceProvider,
      email: data.email,
      mobileNumber: data.mobileNumber,
    };

    return bookAppointmentPayload;
  }

  function notARobotCheck(value) {
    setNotARobotValue(false);
  }

  const onSubmit = async (data) => {
    let bookAppointmentPayload;

    let title = document
      .getElementsByClassName("dual-book-button")[0]
      .innerText.trim();

    //Button text change
    if (title === "Continue, Review Details") {
      setButtonTitle("Confirm & Submit");
      setDisabledField(true);
    }

    //Book appointment API
    else if (title === "Confirm & Submit") {
      bookAppointmentPayload = populateDate(formData, data.dateOfBirth);

      try {
        setLoading(true);

        const controller = new AbortController();
        const id = setTimeout(
          () => controller.abort(),
          process.env.REACT_APP_API_TIMEOUT
        );
        const response = await fetch(
          `${REACT_APP_CORA_API_URL}Appointment/ConnectWithCoraTeam`,
          {
            body: JSON.stringify(bookAppointmentPayload),
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
        clearTimeout(id);

        const appointmentConfirmation = await response.json();
        if (appointmentConfirmation.responseCode === 200) {
          setLoading(false);
          navigate("/appointment-submitted");
        }
      } catch (error) {
        setApiErrorMessage(true);
        setLoading(false);
      }
    }
  };

  const insuranceTypes = useSelector((state) => state.insuranceTypes);

  return (
    <>
      <div className="confirm-and-book-appointment connect-with-cora-page">
        <Modal
          visible={apiErrorMessage}
          className="exception-modal"
          onCancel={() => setApiErrorMessage(false)}
          onOk={() => setApiErrorMessage(false)}
          footer={null}
          centered
        >
          <div className="modal-content">
            <img
              className="error-img"
              src={errorImage}
              alt="Something went wrong."
            />
            <span className="exception-modal-text">
              Something went wrong. Please try again later.
            </span>
          </div>
        </Modal>
        <Modal
          visible={loading}
          className="no-slot-modal"
          onCancel={() => setLoading(false)}
          onOk={() => setLoading(false)}
          maskClosable={false}
          footer={null}
          centered
        >
          <div className="modal-content">
            <Spin indicator={antIcon} className="form-loading-icon" />
          </div>
          <span className="slot-booked-text">Please wait </span>
        </Modal>
        <Header />
        <Helmet>
          <title>CORA Health | Book Appointment</title>
        </Helmet>
        <div className="row no-gutters wrapper-row">
          <div className="col-lg-7 content-column">
            <div className="main-content-section">
              <div className="heading-section">
                <div className="info-heading">
                  {disabledField ? (
                    <div>
                      <span>Please review your details</span>
                      <button
                        id="edit-form-btn"
                        className="edit-form-btn"
                        onClick={editForm}
                      >
                        <img alt="" src={pencilIcon} />
                        Edit
                      </button>
                    </div>
                  ) : (
                    <>
                      <i
                        onClick={() => navigate("/")}
                        className="fa-solid fa-arrow-left cursor-pointer"
                      ></i>
                      <span>Please provide details</span>
                    </>
                  )}
                </div>
                {!disabledField ? (
                  <div className="info-text">
                    <span>
                      If your injury is due to an auto accident or work-related
                      incident, please fill out the form below. All fields are
                      required unless marked optional.
                    </span>
                  </div>
                ) : null}
              </div>

              <div className="form-card connect-with-cora-form">
                <div className="form-title">
                  <span>Patient Information</span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-6 first-name-col">
                      <TextField
                        label="First Name *"
                        variant="outlined"
                        disabled={disabledField}
                        fullWidth
                        size="small"
                        className={classes.inputField}
                        onChange={handleChange}
                        name="firstName"
                        id="first-name"
                        inputRef={register({
                          required: "First Name is required.",
                          pattern: {
                            value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                            message: "Please enter a valid first name",
                          },
                        })}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName?.message}
                        inputProps={{ maxLength: 30 }}
                      />
                    </div>

                    <div className="col-lg-6 last-name-col">
                      <TextField
                        label="Last Name *"
                        variant="outlined"
                        size="small"
                        disabled={disabledField}
                        fullWidth
                        className={classes.inputField}
                        onChange={handleChange}
                        name="lastName"
                        id="last-name"
                        inputRef={register({
                          required: "Last Name is required.",
                          pattern: {
                            value: /^[a-zA-Z0-9\s'-]*[a-zA-Z0-9\s'-]$/i,
                            message: "Please enter a valid last name",
                          },
                        })}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName?.message}
                        inputProps={{ maxLength: 30 }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 dob-datepicker">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          render={(props) => (
                            <KeyboardDatePicker
                              disabled={disabledField}
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              margin="normal"
                              label="Date of Birth (mm/dd/yyyy) *"
                              value={props.value}
                              disableFuture={true}
                              onChange={props.onChange}
                              fullWidth
                              error={Boolean(errors.dateOfBirth)}
                              helperText={errors.dateOfBirth?.message}
                              KeyboardButtonProps={{
                                disabled: disabledField ? true : false,
                                style: {
                                  display: disabledField ? "none" : "block",
                                },
                              }}
                            />
                          )}
                          name="dateOfBirth"
                          id="date-of-birth"
                          defaultValue={null}
                          control={control}
                          rules={{
                            required: "Date of Birth is required.",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-lg-6 insurance-provider-autocomplete">
                      <Autocomplete
                        id="ip-select"
                        disabled={disabledField}
                        options={insuranceTypes}
                        required={true}
                        name="insuranceProvider"
                        onChange={(event, newValue) =>
                          handleInputChange(
                            "insuranceProvider",
                            (event, newValue.id)
                          )
                        }
                        onError={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Insurance Provider *"
                            size="small"
                            variant="outlined"
                            name="insuranceProvider"
                            inputProps={{
                              ...params.inputProps,
                              maxLength: 30,
                            }}
                            inputRef={register({
                              required: "Insurance Provider is required.",
                            })}
                            error={
                              Object.keys(formData).includes(
                                "insuranceProvider"
                              )
                                ? false
                                : Boolean(errors.insuranceProvider)
                            }
                            helperText={
                              Object.keys(formData).includes(
                                "insuranceProvider"
                              )
                                ? ""
                                : errors.insuranceProvider?.message
                            }
                          />
                        )}
                        getOptionLabel={(insuranceTypes) => insuranceTypes.name}
                      />
                    </div>
                  </div>

                  <div className="contact-info-section">
                    <span className="ci-heading">Contact Information</span>
                    <div className="ci-icon-n-text">
                      <img src={infoIcon} alt="Info Icon" />
                      <span className="ci-text">
                        Your mobile number is needed to book the appointment. We
                        will use it to text a confirmation message and any
                        updates on your appointment.
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 phone-number-col">
                      <TextField
                        label="Mobile Number *"
                        variant="outlined"
                        disabled={disabledField}
                        fullWidth
                        size="small"
                        onChange={handleChange}
                        className={classes.inputField}
                        name="mobileNumber"
                        id="mobile-number"
                        inputRef={register({
                          required: "Phone Number is required.",
                          pattern: {
                            value:
                              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                            message: "Please enter a valid mobile number",
                          },
                        })}
                        error={Boolean(errors.mobileNumber)}
                        helperText={errors.mobileNumber?.message}
                        inputProps={{ maxLength: 14 }}
                      />
                      <span className="mobile-hint">
                        Hint: Enter a 10-digit telephone: e.g. (785) 993-3322
                      </span>
                    </div>
                    <div className="col-lg-6 email-col">
                      <TextField
                        label="Email Address"
                        variant="outlined"
                        size="small"
                        disabled={disabledField}
                        fullWidth
                        onChange={handleChange}
                        className={classes.inputField}
                        name="email"
                        id="email-address-field"
                        inputRef={register({
                          pattern: {
                            value:
                              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: "Please enter a valid e-mail address.",
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                  </div>

                  {!disabledField ? (
                    <div className="tnc">
                      <Checkbox
                        className="tnc-checkbox"
                        id="tnc-checkbox"
                        onClick={() => setBookButtonState(!bookButtonState)}
                      ></Checkbox>
                      <span className="t-n-c-text">
                        I agree to the{" "}
                        <a
                          href="https://www.coraphysicaltherapy.com/terms-of-use/"
                          className="clickable-link"
                          target="_blank"
                          rel="noreferrer"
                          id="terms-conditions"
                        >
                          terms and conditions
                        </a>{" "}
                        and the{" "}
                        <a
                          href="https://www.coraphysicaltherapy.com/privacy-policy/"
                          className="clickable-link"
                          target="_blank"
                          rel="noreferrer"
                          id="privacy-policy"
                        >
                          privacy policy.
                        </a>
                      </span>
                    </div>
                  ) : (
                    <div className="tnc">
                      <Checkbox
                        className="tnc-checkbox"
                        id="tnc-checkbox"
                        defaultChecked
                        disabled
                      ></Checkbox>
                      <span className="t-n-c-text">
                        I agree to the{" "}
                        <a
                          href="https://www.coraphysicaltherapy.com/terms-of-use/"
                          className="clickable-link"
                          target="_blank"
                          rel="noreferrer"
                          id="terms-conditions"
                        >
                          terms and conditions
                        </a>{" "}
                        and the{" "}
                        <a
                          href="https://www.coraphysicaltherapy.com/privacy-policy/"
                          className="clickable-link"
                          target="_blank"
                          rel="noreferrer"
                          id="privacy-policy"
                        >
                          privacy policy.
                        </a>
                      </span>
                      <ReCAPTCHA
                        className="mt-3"
                        sitekey="6LcI6JYpAAAAALw7lBLr6JZMj0onwiLbn342AK2K"
                        onChange={notARobotCheck}
                      />
                    </div>
                  )}

                  <Button
                    className="dual-book-button"
                    id="details-submit-btn"
                    disabled={
                      buttonTitle === "Continue, Review Details"
                        ? bookButtonState
                        : notARobotValue
                    }
                    variant="contained"
                    type="submit"
                    size="large"
                  >
                    {/* {loading ? (
                        <Spin
                          indicator={antIcon}
                          className="mr-3 submit-btn-loader"
                        />
                      ) : null} */}
                    {buttonTitle}
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5 carousel-column">
            <Carousel>
              <div>
                <div className="clinic-pic-one"></div>
              </div>
              <div>
                <div className="clinic-pic-two"></div>
              </div>
              <div>
                <div className="clinic-pic-three"></div>
              </div>
              <div>
                <div className="clinic-pic-four"></div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookAppointment;
